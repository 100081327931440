import { motion } from "framer-motion";
import { FullLogo, GreenLogo, NikeBg, NikeLogo, Shoes } from "../../assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SplashScreen() {
  return (
    <div className="splash-screen">
      <div className="splash-logo-motion">
        <motion.div
          initial={{ scale: 2, opacity: 0, rotate: 20 }}
          animate={{ scale: 1, opacity: 1, rotate: 0 }}
          transition={{
            ease: "easeInOut",
            type: "spring",
            duration: 2.2,
            delay: 1,
          }}
          className="splash-logo"
        >
          <LazyLoadImage src={FullLogo} />
        </motion.div>
      </div>
    </div>
  );
}

export default SplashScreen;
