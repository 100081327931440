import { Icon } from "@iconify/react";
import Button from "../element/button";
import { DefaultPromo, PromoLoading, Sample } from "../../assets/images";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "react-tooltip";
import useWindowDimensions from "../hook/use.window.dimensions";

export default function Coupon({
  tnc = true,
  promoInfo,
  className,
  handleUseNow,
  status,
  handleClaimVoucher,
  handleOpenPromoDetails,
  textClassName,
}) {
  const { width } = useWindowDimensions();
  const { promoInfoLoading } = useSelector((state) => state.common);
  const { claimLoading } = useSelector((state) => state.home);

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength) + '...';
  };

  const formatDate = (expiryDate) => {
    const dateObject = new Date(expiryDate);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      dateObject
    );
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <>
      <section
        className={`voucher-section ${
          textClassName ? "--min-height" : ""
        } ${className} ${promoInfo.type === 'receipt' ? 'w-100 p-0' : ''} ${
          status && status === "completed"
            ? "--used"
            : status && status === "pending"
            ? "--pending"
            : ""
        } ${promoInfo.is_usable === 0 ? "--unavailable" : ""} pointer`}
      >
        {status && status === "completed" && (
          <article className="used-overlay" />
        )}
        <article
          className={`voucher-left ${
            !handleOpenPromoDetails && promoInfoLoading ? "p-0" : ""
          }`}
          style={{
            backgroundImage:
              !handleOpenPromoDetails && promoInfoLoading
                ? ""
                : `url(${
                    promoInfo.media?.length > 0
                      ? promoInfo.media[0].url
                      : DefaultPromo
                  })`,
          }}
          onClick={() =>
            handleOpenPromoDetails
              ? handleOpenPromoDetails(promoInfo.id)
              : undefined
          }
        >
          {!handleOpenPromoDetails && promoInfoLoading && (
            <Skeleton width="85px" height="85px" borderRadius="5px" />
          )}
        </article>
        <article className="voucher-right">
          <article
            className={`voucher-info ${ status !== "completed" && promoInfo.is_usable === 0 ? '--disabled' : ''}`}
            onClick={() =>
              handleOpenPromoDetails
                ? handleOpenPromoDetails(promoInfo.id)
                : undefined
            }
          >
            {!promoInfoLoading ? (
              <>
                <h5 className={textClassName}>{promoInfo.title}</h5>
                <article>
                  {(className ||
                    (!className &&
                      promoInfo.balance_quantity_promotion !== null)) &&
                  status !== "completed" ? (
                    !promoInfo.unavailable_reason && <article
                      className={`flex items-start quantity ${
                        textClassName ? "mt-4" : ""
                      }`}
                    >
                      <p>
                        {className
                          ? `x${promoInfo.user_balance_quantity}`
                          : promoInfo.balance_quantity_promotion
                          ? promoInfo.balance_quantity_promotion
                          : promoInfo.quantity}
                      </p>
                      {(promoInfo.user_balance_quantity ||
                        promoInfo.quantity) && (
                        <Icon icon="mingcute:coupon-line" />
                      )}
                    </article>
                  ) : (
                    ""
                  )}
                  <article className="flex">
                    <p>
                      {status !== "completed" && promoInfo.unavailable_reason ? 
                        truncateString(promoInfo.unavailable_reason, 60)
                      :
                        status && status === "completed"
                        ? "Used at"
                        : promoInfo.expiry_day_count
                        ? "Expire in"
                        : "Valid till"
                      }{" "}
                      {status !== "completed" && promoInfo.unavailable_reason ? 
                        ""
                      :
                        promoInfo.claimed_at
                        ? formatDate(promoInfo.claimed_at)
                        : promoInfo.expiry_day_count
                        ? `${promoInfo.expiry_day_count} days`
                        : promoInfo.expiry_date
                        ? formatDate(promoInfo.expiry_date)
                        : ""
                      }
                        {tnc && (
                          <span>{promoInfo.is_usable === 1 && <>&nbsp;&nbsp;</>}&nbsp;T&C</span>
                        )}
                    </p>
                  </article>
                </article>
              </>
            ) : handleOpenPromoDetails ? (
              <>
                <h5 className={textClassName}>{promoInfo.title}</h5>
                <article>
                  {(className ||
                    (!className &&
                      promoInfo.balance_quantity_promotion !== null)) &&
                  status !== "completed" ? (
                    <article
                      className={`flex items-center ${
                        textClassName ? "mt-4" : ""
                      }`}
                    >
                      <p className="quantity">
                        {className
                          ? `x${promoInfo.user_balance_quantity}`
                          : promoInfo.balance_quantity_promotion
                          ? promoInfo.balance_quantity_promotion
                          : promoInfo.quantity}
                      </p>
                      {(promoInfo.user_balance_quantity ||
                        promoInfo.quantity) && (
                        <Icon icon="mingcute:coupon-line" />
                      )}
                    </article>
                  ) : (
                    ""
                  )}
                  <article className="flex">
                    <p data-tooltip-id={promoInfo.unavailable_reason?.length > 60 ? "unavailable-use" : ""}>
                      {status !== "completed" && promoInfo.unavailable_reason ? 
                        truncateString(promoInfo.unavailable_reason, 60)
                      :
                        status && status === "completed"
                        ? "Used at"
                        : promoInfo.expiry_day_count
                        ? "Expire in"
                        : "Valid till"
                      }{" "}
                      {status !== "completed" && promoInfo.unavailable_reason ? 
                        ""
                      :
                        promoInfo.claimed_at
                        ? formatDate(promoInfo.claimed_at)
                        : promoInfo.expiry_day_count
                        ? `${promoInfo.expiry_day_count} days`
                        : promoInfo.expiry_date
                        ? formatDate(promoInfo.expiry_date)
                        : ""
                      }
                    </p>
                    &nbsp;&nbsp;&nbsp;
                    {tnc && (
                      <p>
                        <span>T&C</span>
                      </p>
                    )}
                  </article>
                </article>
              </>
            ) : (
              <>
                <Skeleton width="70%" height="10px" borderRadius="18px" />
                <Skeleton
                  width="50%"
                  height="9px"
                  borderRadius="18px"
                  className="mt-4"
                />
              </>
            )}
          </article>
          {!promoInfo.tnc && !promoInfoLoading && status !== "completed" && (
            <article>
              {className ? (
                status &&
                status !== "completed" &&
                promoInfo.is_usable === 1 ? (
                  <button
                    disabled={promoInfo.is_usable !== 1}
                    className="claim-btn"
                    onClick={() =>
                      handleUseNow ? handleUseNow(promoInfo) : undefined
                    }
                  >
                    Use Now
                  </button>
                ) : (
                  promoInfo.user_balance_quantity && className ? <article
                    className={`flex items-center quantity pt-2`}
                  >
                    <p>x{promoInfo.user_balance_quantity}</p>
                    <Icon icon="mingcute:coupon-line" className="mt-0"/>
                  </article> : ''
                )
              ) : (
                <button
                  disabled={promoInfo.claimed_status === "claimed" || claimLoading}
                  className="claim-btn"
                  onClick={(e) =>
                    handleClaimVoucher
                      ? handleClaimVoucher(promoInfo.id)
                      : undefined
                  }
                >
                  {promoInfo.claimed_status === "claimed" ? "Claimed" : "Claim"}
                </button>
              )}
            </article>
          )}
        </article>
      </section>
      <Tooltip
        id="unavailable-use"
        place={width > 550 ? "left" : "bottom"}
        content={promoInfo.unavailable_reason}
      />
    </>
  );
}
