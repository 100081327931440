import { useDispatch, useSelector } from "react-redux"
import { getPublicReceipt, getpublicReceipt } from "../../redux/reducer/orderReducer";
import { useEffect } from "react";
import scrollToTop from "../../helpers/scroll.to.top";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { basicNumber, formatNumber } from "../../components/common/return.number";
import { Icon } from "@iconify/react";
import Button from "../../components/element/button";
import domToImage from 'dom-to-image';
import { jsPDF } from 'jspdf';
import { useToast } from "../../components/common/toast.provider";
import { useParams } from "react-router-dom";

export default function Receipt() {
  const toast = useToast()
  const dispatch = useDispatch()

  const { id } = useParams()
  const { publicReceipt, publicReceiptLoading } = useSelector((state) => state.order);

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    if(id) {
      dispatch(getPublicReceipt({
        order_id: id
      }))
    }
  }, [id])

  const detailData = [
    {
      title: "Receipt No",
      value: publicReceipt?.receipt_no,
    },
    {
      title: "Date",
      value: publicReceipt?.paid_at,
    },
    {
      title: "Payment Method",
      value: publicReceipt?.payment_method_name,
    },
  ];

  const combinedOrderItems =
    publicReceipt?.order?.length > 0 ? publicReceipt.order.reduce((acc, item) => {
        return acc.concat(item.order_items_array);
    }, [])
    : [];

  const sortedCombinedOrderItems = combinedOrderItems.sort((x, y) => {
    const xValue = x.menu_code ? x.menu_code : x.menu_name;
    const yValue = y.menu_code ? y.menu_code : y.menu_name;

    const xFirstIsNumber = /^\d/.test(xValue);
    const yFirstIsNumber = /^\d/.test(yValue);

    if (xFirstIsNumber && !yFirstIsNumber) {
      return -1;
    } else if (!xFirstIsNumber && yFirstIsNumber) {
      return 1;
    } else if (xFirstIsNumber && yFirstIsNumber) {
      const xAlpha = xValue.replace(/\d+/g, "");
      const yAlpha = yValue.replace(/\d+/g, "");
      if (xAlpha === yAlpha) {
        const xNum = parseInt(xValue.match(/\d+/)[0]);
        const yNum = parseInt(yValue.match(/\d+/)[0]);
        return xNum - yNum;
      }
      return xAlpha.localeCompare(yAlpha);
    } else {
      const xNumMatch = xValue.match(/\d+/);
      const yNumMatch = yValue.match(/\d+/);
      const xNum = xNumMatch ? parseInt(xNumMatch[0]) : 0;
      const yNum = yNumMatch ? parseInt(yNumMatch[0]) : 0;
      const xAlpha = xValue.replace(/\d+/g, "");
      const yAlpha = yValue.replace(/\d+/g, "");
      if (xAlpha === yAlpha) {
        return xNum - yNum;
      }
      return xAlpha.localeCompare(yAlpha);
    }
  });

  const handleShareReceipt = () => {
    const receiptElement = document.querySelector('.receipt-layout');

    domToImage.toPng(receiptElement)
    .then(function (dataUrl) {
      var img = new Image();
      img.src = dataUrl;
      img.onload = function () {
        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'px',
          format: [img.width, img.height]
        });
        pdf.addImage(dataUrl, 'PNG', 0, 0, img.width, img.height);
        pdf.save('receipt.pdf');
      };
    })
    .catch(function (error) {
      toast.error('Error:', error);
    });
  }

  return (
    <div className="receipt-page p-4">
      {!publicReceiptLoading && publicReceipt.store_name && 
        <Button className="share-button-layout" btnClassName="share-button" onClick={() => handleShareReceipt()}>
          <Icon icon="bxs:file-pdf" />
        </Button>
      }
      {!publicReceiptLoading ? 
        publicReceipt.store_name ?
          <div className="receipt-layout shadow">
            <h5 className="mb-3">{publicReceipt.store_name ? publicReceipt.store_name : "-"}</h5>
            <p>{publicReceipt.store_address ? publicReceipt.store_address : "-"}</p>
            {publicReceipt.sst_no && (
              <h6>
                SST No : {publicReceipt.sst_no}
              </h6>
            )}
            <h6 className="mt-3 mb-3">
              Invoice
            </h6>
            <h6 className="text-capitalize">
              **{publicReceipt.dine_in_method}**
            </h6>
            <hr />
            <div className="flex justify-between mb-2">
              <h5 className="m-0">Table</h5>
            </div>
            <div className="flex justify-between mb-2">
              <h5 className="mt-0 mb-0">{publicReceipt.table_name ?? "-"}</h5>
            </div>
            {detailData.map((data, dataIndex) => {
              return (
                <p
                  className="flex mb-1"
                  key={dataIndex}
                >
                  {data.title} :&nbsp;
                  <span
                    style={{
                      color: data.value === "pending" ? "#f97316" : "",
                    }}
                  >
                    {data.value}
                  </span>
                </p>
              );
            })}
            <hr className="mb-0 mt-2" />
            <MDBRow>
              <MDBCol className="col-7">
                <p className="mt-1 mb-1 text-start">Item</p>
              </MDBCol>
              <MDBCol>
                <p className="mt-1 mb-1 text-center">Qty</p>
              </MDBCol>
              <MDBCol>
                <p className="mt-1 mb-1 text-end">RM</p>
              </MDBCol>
            </MDBRow>
            <hr className="mt-0 mb-0" />
            <div className="mt-3">
              <div>
                {sortedCombinedOrderItems &&
                  sortedCombinedOrderItems.length > 0 &&
                  sortedCombinedOrderItems.map((order_item, orderIndex) => {
                    return (
                      <div className="mt-3" key={orderIndex}>
                        <div className="flex justify-between">
                          <MDBCol className="col-7">
                            <h6 className="menu-label text-start">
                              {order_item.menu_code} {order_item.menu_name}
                            </h6>
                          </MDBCol>
                          <MDBCol>
                            <h6 className="menu-label text-center">
                              {order_item.quantity}
                            </h6>
                          </MDBCol>
                          <MDBCol>
                            <h6 className="menu-label text-end">
                              {formatNumber(order_item.total_price)}
                            </h6>
                          </MDBCol>
                        </div>
                        <MDBCol className="col-7">
                          {order_item.menu_detail &&
                            order_item.menu_detail.length > 0 &&
                            order_item.menu_detail.map((option, optionIndex) => {
                              return (
                                <div key={optionIndex}>
                                  <p className="option-label text-start m-0">- {option.name}</p>
                                </div>
                              );
                            })}
                          {order_item.redemption_remark && (

                            <div className="option-items order mt-40 d-flex align-items-center gap-50">
                              <p className="option-label text-start m-0">Redemption Remark : {order_item.redemption_remark}</p>
                            </div>
                          )}
                          {order_item.remark && (
                            <div className="option-items order mt-40 d-flex align-items-center gap-50">
                              <p className="option-label text-start m-0">Remark : {order_item.remark}</p>
                            </div>
                          )}
                          {order_item.promotion_title && (
                            <div
                              className="flex justify-between w-100"
                            >
                              <p className="option-label">Food Discount Voucher</p>
                              <div>
                                <p className="option-label text-end">
                                  -{" "}
                                  {formatNumber(
                                    order_item.promotion_deduct_amount
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                        </MDBCol>
                      </div>
                    );
                  })}
              </div>
              <hr className="mb-0 mt-50" />
              {publicReceipt.subtotal > 0 && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between", marginTop: "0.5em" }}
                >
                  <p className="total-label">Subtotal</p>
                  <p className="total-label">
                    {" "}
                    {formatNumber(publicReceipt.subtotal)}
                  </p>
                </div>
              )}
              {publicReceipt.promotion_deduct_amount > 0 && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">Food Discount Voucher</p>
                  <p className="total-label">
                    - {formatNumber(publicReceipt.promotion_deduct_amount)}
                  </p>
                </div>
              )}
              {publicReceipt.referral_discount > 0 && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">Referral Discount</p>
                  <p className="total-label">
                    - {formatNumber(publicReceipt.referral_discount)}
                  </p>
                </div>
              )}
              {publicReceipt.merchant_deducted_amount > 0 && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">
                    Merchant Discount{" "}
                    {publicReceipt.merchant_discount_type === "percent" &&
                      `(${basicNumber(
                        publicReceipt.merchant_discount_amount
                      )}%)`}
                  </p>
                  <p className="total-label">
                    - {formatNumber(publicReceipt.merchant_deducted_amount)}
                  </p>
                </div>
              )}
              {publicReceipt.sst > 0 && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">
                    SST ({basicNumber(publicReceipt.sst_percent)}%)
                  </p>
                  <p className="total-label">
                    {" "}
                    {formatNumber(publicReceipt.sst)}
                  </p>
                </div>
              )}
              {publicReceipt.service_charge > 0 && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">
                    Service Charge (
                    {basicNumber(publicReceipt.service_charge_percent)}
                    %)
                  </p>
                  <p className="total-label">
                    {formatNumber(publicReceipt.service_charge)}
                  </p>
                </div>
              )}
              {publicReceipt.dine_in_method === "delivery" &&
                publicReceipt.delivery_fees !== undefined && (
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="total-label">Delivery Fees</p>
                    <p className="total-label">
                      {formatNumber(publicReceipt.delivery_fees)}
                    </p>
                  </div>
                )}

              {publicReceipt.rounding_adjustment !== undefined && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">Rounding</p>
                  <p className="total-label">
                    {formatNumber(publicReceipt.rounding_adjustment)}
                  </p>
                </div>
              )}
              {publicReceipt.adjustment_name && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">
                    {publicReceipt.adjustment_name}
                  </p>
                  <p className="total-label">
                    {formatNumber(publicReceipt.adjustment_value)}
                  </p>
                </div>
              )}
              <hr className="mb-50" style={{ marginTop: "0.5em" }} />

              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <h5
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Total Paid :
                </h5>
                <h5
                  style={{
                    fontSize: "14px",
                    color: "#7367f0",
                  }}
                >
                  {formatNumber(publicReceipt.total)}
                </h5>
              </div>
              {publicReceipt.payment_method_name === "Cash" && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">Cash Received</p>
                  <p className="total-label">
                    {" "}
                    {formatNumber(publicReceipt.received_amount)}
                  </p>
                </div>
              )}
              {publicReceipt.payment_method_name === "Cash" && (
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p className="total-label">Change</p>
                  <p className="total-label">
                    {formatNumber(publicReceipt.change)}
                  </p>
                </div>
              )}
            </div>
          </div>
        : 
          <div className="text-center p-5 mt-2">
            <h5 className="loading mb-0">No receipt found</h5>    
          </div>  
      : (
        <div className="text-center p-5 mt-2">
          <h5 className="loading mb-0">Loading receipt, please wait ..</h5>
          <article className="general-loading pt-5">
            <Icon icon="svg-spinners:12-dots-scale-rotate" />
          </article>
        </div>
      )}
    </div>
  );
}
