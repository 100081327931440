//lib
import { MDBBtn, MDBRow } from "mdb-react-ui-kit";

export default function Button(props) {
  const {
    label,
    name,
    type,
    variant,
    disabled,
    className,
    labelClassName,
    btnClassName,
    onClick,
    badge,
  } = props;

  return (
    <div className={`element _button ${className ? className : ""}`}>
      {label && (
        <MDBRow className="mb-2">
          <label
            htmlFor={name}
            className={`element _label ${labelClassName ? labelClassName : ""}`}
          >
            {label}
          </label>
        </MDBRow>
      )}
      {/* {badge && <p className="badge">{badge}</p>} */}
      <MDBBtn
        type={type ? type : "button"}
        className={`
          ${variant === "outline" ? "outline-button" : "solid-button"} 
          ${btnClassName ? btnClassName : ""} 
          pointer 
        `}
        disabled={disabled}
        onClick={onClick || undefined}
      >
        {props.children}
      </MDBBtn>
    </div>
  );
}
