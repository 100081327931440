import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  closePromotionDetailsModal,
  openConfirmModal,
  openPopUp,
  openPromotionDetailsModal,
  openSetAddressModal,
} from "../../redux/reducer/modalReducer";
import Footer from "../footer/footer";
import MainHeader from "../header/main.header";
import useWindowDimensions from "../hook/use.window.dimensions";
import BottomNavBar from "../navbar/bottom.navbar";
import SideNavbar from "../navbar/side.navbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PopUp from "../../pages/PopUp";
import CouponPopUp from "../../pages/CheckIn/popups/coupon.popup";
import {
  getPopupDisplayed,
  getToken,
  getGuestToken,
  getLogin,
  getMethod,
  getDeviceId,
  getAddressId,
  getCartId,
  getMerchantId,
  getTableId,
  getQueueId,
  getSessionNo,
  getUserId,
  getUsername,
  setConfirmItem,
  getPromotionInfo,
  getCartOwner,
  getDineIn,
  getReserveData,
  setReserveId,
} from "../../redux/reducer/commonReducer";
import { getWalletBalance } from "../../redux/reducer/homeReducer";
import {
  getMerchantInfo,
  setMerchantInfoLoading,
} from "../../redux/reducer/merchantReducer";
import Confirmation from "../common/confirmation";
import SplashScreen from "../common/splash.screen";
import scrollToTop from "../../helpers/scroll.to.top";
import SetAddress from "../../pages/Address/modals/set.address";
import SaveAddress from "../../pages/Address/modals/save.address";
import FullLoading from "../common/full.loading";
import { useToast } from "../common/toast.provider";
import httpService from "../../services/http.service";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

export default function MainLayout(props) {
  const { store, merchant, id } = useParams();
  const location = useLocation();
  const { pathname, search } = useLocation();
  const { realWidth } = useWindowDimensions();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const { merchantInfo, merchantInfoLoading, themeColor } = useSelector(
    (state) => state.merchant
  );
  const {
    isOpenPromotionDetailsModal,
    isOpenSetAddressModal,
    isOpenSaveAddressModal,
    isOpenPopUp,
    isOpenCouponPopUp,
    isOpenConfirmModal,
    isOpenLoginModal,
    isOpenCongratsModal,
    isOpenScanQRModal,
    tableId_checked,
  } = useSelector((state) => state.modal);
  const {
    dineIn,
    sessionNo,
    confirmItem,
    token,
    token_checked,
    isPopupDisplayed,
    deviceId,
    login,
    reserveData,
    merchantId,
    method,
    tableId,
    addressId,
    fullLoading,
  } = useSelector((state) => state.common);

  const oldDeviceId = navigator.userAgent.replace(/[^\w]/gi, "");

  function getUuid() {
    let newDeviceId = localStorage.getItem('device_id');

    if (!newDeviceId || (newDeviceId && oldDeviceId === newDeviceId)) {
        const uuid = uuidv4();
        const timestamp = new Date().getTime();
        const rawId = `${uuid}-${timestamp}`;
        newDeviceId = CryptoJS.SHA256(rawId).toString();
    }

    return newDeviceId;
  }

  const device_id = getUuid();

  const [searchParams, setSearchParams] = useSearchParams();

  const table_id = searchParams.get("table");
  const open_modal = searchParams.get("open");
  const session_no = searchParams.get("session_no");
  const promotion = searchParams.get("promotion");

  const hexToRGBA = (hex, alpha = 1) => {
    const r = parseInt(hex?.slice(1, 3), 16);
    const g = parseInt(hex?.slice(3, 5), 16);
    const b = parseInt(hex?.slice(5, 7), 16);

    return `${r}, ${g}, ${b}`;
  };

  useEffect(() => {
    if (
      token_checked &&
      token &&
      pathname === `/${encodeURIComponent(store?.trim())}` &&
      promotion &&
      realWidth < 450
    ) {
      dispatch(openPromotionDetailsModal('claim-btn'));
      dispatch(
        getPromotionInfo({
          id: promotion,
        })
      )
        .unwrap()
        .catch((ex) => {
          setSearchParams("");
          dispatch(closePromotionDetailsModal());
          if (ex && ex.response?.status === 404) {
            toast.error("Promotion is expired or not exist");
          }
        });
    }
  }, [token_checked, token, promotion]);

  useEffect(() => {
    if (
      merchantId &&
      !method &&
      (pathname === `/${encodeURIComponent(store?.trim())}/menu` ||
        pathname === `/${encodeURIComponent(store?.trim())}/cart` ||
        pathname === `/${encodeURIComponent(store?.trim())}/order`)
    ) {
      navigate(`/${encodeURIComponent(store?.trim())}`);
      toast.success("Pick your path to deliciousness.");
    }
  }, [merchantId, method, pathname]);

  useEffect(() => {
    if (merchantId) {
      if (
        pathname === `/${encodeURIComponent(store?.trim())}/check-in` &&
        merchantInfo.has_check_in === 0
      ) {
        navigate(`/${encodeURIComponent(store?.trim())}`);
        toast.error("The check in event is expired or closed");
      }

      if (
        pathname === `/${encodeURIComponent(store?.trim())}/spin-wheel` &&
        merchantInfo.has_spin_wheel === 0
      ) {
        navigate(`/${encodeURIComponent(store?.trim())}`);
        toast.error("The spin to win event is expired or closed");
      }

      if (
        pathname === `/${encodeURIComponent(store?.trim())}/queue` &&
        merchantInfo.has_queue === 0
      ) {
        navigate(`/${encodeURIComponent(store?.trim())}`);
        toast.error("This merchant is not open for queuing");
      }

      if (
        pathname === `/${encodeURIComponent(store?.trim())}/reservation` &&
        merchantInfo.has_reservation === 0
      ) {
        navigate(`/${encodeURIComponent(store?.trim())}`);
        toast.error("This merchant is not open for reservations");
      }
    }
  }, [merchantId, pathname]);

  useEffect(() => {
    if (open_modal) {
      setSearchParams("");
      dispatch(setMerchantInfoLoading('finished'))
      dispatch(
        setConfirmItem({
          type: open_modal,
        })
      );
      dispatch(openConfirmModal());
    }
  }, []);

  useEffect(() => {
    if (table_id && session_no) {
      localStorage.setItem("table_id", table_id);
      localStorage.setItem("session_no", session_no);
      localStorage.setItem("method", "dine in");
      dispatch(getTableId());
      dispatch(getSessionNo());
      dispatch(getMethod());

      setSearchParams("");

      // navigate(`/${store}`)
    }
  }, [table_id, session_no]);

  useEffect(() => {
    if (
      pathname === `/${encodeURIComponent(store?.trim())}/menu` &&
      method === "dine in"
    ) {
      if (!table_id && tableId_checked) {
        toast.error("Invalid table id");
        navigate(`/${encodeURIComponent(store?.trim())}`);
      }
    }
  }, [pathname, method, table_id, tableId_checked]);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getLogin());
    dispatch(getUserId());
    dispatch(getMethod());
    dispatch(getCartId());
    dispatch(getQueueId());
    dispatch(getReserveData())
    dispatch(getTableId());
    dispatch(getDeviceId());
    dispatch(getAddressId());
    dispatch(getSessionNo());
    dispatch(getPopupDisplayed());
    localStorage.setItem("device_id", device_id);
  }, []);

  useEffect(() => {
    if(merchantId && reserveData.length > 0) {
      const reserveRow = reserveData.find((reserve) => reserve.merchant_id === merchantId)
      if(reserveRow?.reserve_id) {
        dispatch(setReserveId(reserveRow.reserve_id))
      }
    }
  }, [merchantId, reserveData])

  useEffect(() => {
    if (
      method === "delivery" &&
      !addressId &&
      merchantInfoLoading === "finished" &&
      (pathname === `/${encodeURIComponent(store?.trim())}/menu` ||
        pathname === `/${encodeURIComponent(store?.trim())}/cart` ||
        pathname === `/${encodeURIComponent(store?.trim())}/order`)
    ) {
      dispatch(openSetAddressModal());
      toast.error("Set a new address or select an existing one to proceed.");
    }
  }, [method, addressId, merchantInfoLoading, pathname]);

  useEffect(() => {
    if (
      store !== undefined &&
      !open_modal &&
      token_checked &&
      token &&
      realWidth < 450
    ) {
      dispatch(
        getMerchantInfo({
          display_name: store,
          table_id: tableId ? (tableId === "null" ? null : tableId) : null,
          session_no: sessionNo
            ? sessionNo === "null"
              ? null
              : sessionNo
            : null,
        })
      )
        .unwrap()
        .then((res) => {
          localStorage.setItem("merchant_name", res.data.store_name);
          localStorage.setItem("merchant_id", res.data.id);
          dispatch(getMerchantId());
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              toast.error("Invalid merchant.");
              navigate("/");
              localStorage.removeItem("table_id");
              localStorage.removeItem("session_no");

              dispatch(getTableId());
              dispatch(getSessionNo());
            }
          }
        });
    }
  }, [token_checked, store]);

  useEffect(() => {
    if (
      token_checked &&
      !token &&
      !open_modal &&
      store !== undefined &&
      realWidth < 450
    ) {
      dispatch(
        getGuestToken({
          device_id: device_id,
        })
      )
        .unwrap()
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user_id", res.data.user_id);
          localStorage.setItem("username", res.data.username);
          dispatch(getToken());
          dispatch(getUserId());
          dispatch(getUsername());

          dispatch(
            getMerchantInfo({
              display_name: store,
              table_id: tableId ? (tableId === "null" ? null : tableId) : null,
              session_no: sessionNo
                ? sessionNo === "null"
                  ? null
                  : sessionNo
                : null,
            })
          )
            .unwrap()
            .then((res) => {
              localStorage.setItem("merchant_name", res.data.store_name);
              localStorage.setItem("merchant_id", res.data.id);
              dispatch(getMerchantId());
            })
            .catch((ex) => {
              if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                  toast.error("Invalid merchant.");
                  navigate("/");
                  localStorage.removeItem("table_id");
                  localStorage.removeItem("session_no");

                  dispatch(getTableId());
                  dispatch(getSessionNo());
                }
              }
            });
        })
        .catch((ex) => {})
    }
    // else if (token_checked && token && login === 'full' && merchantId) {
    //   dispatch(getWalletBalance({
    //     merchant_id: merchantId
    //   }))
    // }
  }, [token_checked, token, merchantId, store]);

  useEffect(() => {
    if (
      store &&
      !isPopupDisplayed &&
      merchantInfo.pop_up?.length > 0
    ) {
      dispatch(openPopUp());
      document.body.style.overflow = "hidden";
      localStorage.setItem("is_popup_displayed", true);
    }
  }, [isPopupDisplayed, merchantInfo]);

  useEffect(() => {
    const contentContainer = document.querySelector(".content-container");

    if (realWidth < 450) {
      if (
        isOpenCouponPopUp ||
        isOpenPopUp ||
        fullLoading ||
        pathname === `/${encodeURIComponent(store?.trim())}/menu` ||
        pathname === `/`
      ) {
        scrollToTop();
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    } else {
      if (
        merchantInfoLoading === "splashed" ||
        isOpenCouponPopUp ||
        isOpenPopUp ||
        fullLoading ||
        isOpenLoginModal ||
        isOpenConfirmModal ||
        isOpenCongratsModal ||
        isOpenScanQRModal ||
        pathname === `/${encodeURIComponent(store?.trim())}/menu`
      ) {
        scrollToTop();
        // document.body.style.overflow = 'hidden'
        if (contentContainer) {
          contentContainer.style.overflow = "hidden";
        }
      } else {
        // document.body.style.overflow = 'auto'
        if (contentContainer) {
          contentContainer.style.overflow = "auto";
        }
      }
    }
  }, [
    isOpenCouponPopUp,
    isOpenPopUp,
    isOpenLoginModal,
    isOpenConfirmModal,
    isOpenCongratsModal,
    isOpenScanQRModal,
    fullLoading,
    merchantInfoLoading,
    pathname,
  ]);

  useEffect(() => {
    if (merchantInfoLoading === "splashed") {
      if (themeColor.bg_color) {
        document.body.style.setProperty(
          "--primary_background_color",
          themeColor.bg_color
        );
      }

      if (themeColor.font_color) {
        document.body.style.setProperty(
          "--primary_text_color",
          themeColor.font_color
        );
      }

      if (themeColor.main_color) {
        document.body.style.setProperty(
          "--primary_modal_color",
          themeColor.main_color
        );
        document.body.style.setProperty(
          "--primary_modal_color_opacity",
          hexToRGBA(themeColor.main_color.trim())
        );
      } else {
        document.body.style.setProperty(
          "--primary_modal_color",
          "#3C387F"
        );
        document.body.style.setProperty(
          "--primary_modal_color_opacity",
          hexToRGBA(("#3C387F").trim())
        );
      }

      if (themeColor.second_color) {
        document.body.style.setProperty(
          "--secondary_modal_color",
          themeColor.second_color
        );
        document.body.style.setProperty(
          "--secondary_modal_color_opacity",
          hexToRGBA(themeColor.second_color.trim())
        );
      }

      if (themeColor.third_color) {
        document.body.style.setProperty(
          "--tertiary_background_color",
          themeColor.third_color
        );
        document.body.style.setProperty(
          "--tertiary_background_color_opacity",
          hexToRGBA(themeColor.third_color.trim())
        );
      }

      if (themeColor.btn_color) {
        document.body.style.setProperty(
          "--secondary_background_color",
          themeColor.btn_color
        );
        document.body.style.setProperty(
          "--secondary_background_color_opacity",
          hexToRGBA(themeColor.btn_color.trim())
        );
      }

      const timerId = setTimeout(() => {
        dispatch(setMerchantInfoLoading("finished"));
      }, 2000);

      return () => clearTimeout(timerId);
    }
  }, [merchantInfoLoading, dispatch, themeColor]);

  useEffect(() => {
    const onesignalBellContainer = document.getElementById(
      "onesignal-bell-container"
    );

    if (onesignalBellContainer) {
      if (pathname === `/${encodeURIComponent(store?.trim())}`) {
        onesignalBellContainer.style.display = "block";
      } else {
        onesignalBellContainer.style.display = "none";
      }
    }
  }, [pathname]);

  const navigateParent = (url) => {
    if (url !== window.parent.location.href) {
      const message = {
        type: "navigate",
        url: url,
      };
      window.parent.postMessage(message, process.env.REACT_APP_URL);
      window.parent.history.replaceState(null, "", url);
    }
  };

  useEffect(() => {
    const handleNavigation = () => {
      navigateParent(window.location.href);
    };

    handleNavigation();
    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [window.location.href]);

  return (realWidth < 450 && merchantInfoLoading === "finished") ||
    pathname === "/" || id || merchant ||
    realWidth >= 450 ? (
    <>
      <main className={realWidth >= 450 ? "layout-container" : ""}>
        {realWidth >= 450 && (
          <article className="watermark">
            {Array.from({ length: 100 }, (_, watermarkIndex) => (
              <p key={watermarkIndex} >foodlink</p>
            ))}
          </article>
        )}
        {realWidth >= 450 ? (
          <iframe
            className={`content-container relative ${
              realWidth < 450 ? "overflow-x-hidden" : ""
            }`}
            src={`${pathname}${search}`}
          />
        ) : (
          <>
            <article
              style={{
                minHeight:
                  pathname === `/${encodeURIComponent(store?.trim())}`
                    ? merchantInfo.company_logo?.length > 0
                      ? "85vh"
                      : "96vh"
                    : "100vh",
              }}
              className={`content-container relative ${
                realWidth < 450 ? "overflow-x-hidden" : ""
              }`}
            >
              {isOpenCouponPopUp && <CouponPopUp />}
              {isOpenConfirmModal && <Confirmation />}
              {isOpenSetAddressModal && <SetAddress />}
              {isOpenSaveAddressModal && <SaveAddress />}
              {pathname !== "/" && isOpenPopUp && <PopUp />}
              {pathname !== "/" && fullLoading && <FullLoading />}
              {props.children}
            </article>
            {pathname === `/${encodeURIComponent(store?.trim())}` && <Footer />}
          </>
        )}
      </main>
    </>
  ) : (
    <>
      {realWidth < 450 && (
        <main className={realWidth >= 450 ? "layout-container" : ""}>
          {realWidth >= 450 && (
            <article className="watermark">
              {Array.from({ length: 100 }, (_, promoSkeletonIndex) => (
                <p>foodlink</p>
              ))}
            </article>
          )}
          <article
            className={`content-container relative ${
              realWidth < 450 ? "overflow-x-hidden" : ""
            }`}
          >
            <SplashScreen />
            {isOpenConfirmModal && 
            confirmItem?.type === "update_version" && 
            confirmItem?.type === "under_maintenance" &&(
              <Confirmation />
            )}
          </article>
        </main>
      )}
    </>
  );
}
