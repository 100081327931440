import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";

import { useDispatch } from "react-redux";
import { setSearchedAddress } from "../../redux/reducer/addressReducer";

const libraries = ["places"];

const MapComponent = ({ onMarkerPlaced, markerPlaced, address }) => {
  const dispatch = useDispatch();

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
    borderRadius: "1em",
  };

  const center = {
    lat: address.latitude
      ? parseFloat(address.latitude)
      : typeof address?.geometry?.location?.lat === "function"
      ? address.geometry.location.lat()
      : typeof address?.geometry?.location?.lat === "number"
      ? address.geometry.location.lat
      : 3.139,
    lng: address.longitude
      ? parseFloat(address.longitude)
      : typeof address?.geometry?.location?.lng === "function"
      ? address.geometry.location.lng()
      : typeof address?.geometry?.location?.lng === "number"
      ? address.geometry.location.lng
      : 101.6869,
  };

  const onMapClick = async (event) => {
    onMarkerPlaced({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    dispatch(
      setSearchedAddress(
        await reverseGeocode(event.latLng.lat(), event.latLng.lng())
      )
    );
  };

  const reverseGeocode = async (lat, lng) => {
    let result = {};

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCoiYt8mivkh-uHFmwZAz0TV6FCNI8fsh4&language=en`
      );

      if (response.ok) {
        const data = await response.json();
        result = data.results[0] || "Address not found";
      } else {
        console.error("Reverse geocoding failed.");
      }
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
    }

    return result;
  };

  return (
    <LoadScriptNext
      googleMapsApiKey="AIzaSyCoiYt8mivkh-uHFmwZAz0TV6FCNI8fsh4"
      language="en"
      libraries={libraries}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={address ? 18 : 10}
        onClick={onMapClick}
      >
        <Marker
          position={{
            lat: markerPlaced.lat
              ? markerPlaced.lat
              : address.latitude
              ? parseFloat(address.latitude)
              : typeof address?.geometry?.location?.lat === "function"
              ? address.geometry.location.lat()
              : typeof address?.geometry?.location?.lat === "number"
              ? address.geometry.location.lat
              : 3.139,
            lng: markerPlaced.lng
              ? markerPlaced.lng
              : address.longitude
              ? parseFloat(address.longitude)
              : typeof address?.geometry?.location?.lng === "function"
              ? address.geometry.location.lng()
              : typeof address?.geometry?.location?.lng === "number"
              ? address.geometry.location.lng
              : 101.6869,
          }}
        />
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default MapComponent;
