import { useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { setFullLoading } from "../../../redux/reducer/commonReducer";
import { closeInstantTopUpModal, closeTimeModal } from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";
import HalfModal from "../../../components/modal/half.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";
import { setPickupDate, setPickupTime } from "../../../redux/reducer/cartReducer";
import moment from 'moment';

export default function SelectTime() {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { realWidth, width } = useWindowDimensions();
  
//   const { cartInfo } = useSelector((state) => state.cart);
  const { pickupDate, pickupTime } = useSelector((state) => state.cart);
  const { isOpenTimeModal } = useSelector((state) => state.modal);
  const { merchantInfo } = useSelector((state) => state.merchant);

//   const { topUpPaymentList, merchantId, cartId } = useSelector((state) => state.common);

  const handleCloseTimeModal = () => {
    dispatch(closeTimeModal());
  };

  const handleChooseDate = (date) => {
    dispatch(setPickupDate(date))
    dispatch(setPickupTime({}))
  }

  const handleChooseTime = (time) => {
    dispatch(setPickupTime(time))
    handleCloseTimeModal()
  }

  function getThreeDays() {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date();
    
    const unavailableDays = {
      Sun: merchantInfo.is_unavailable_on_sunday,
      Mon: merchantInfo.is_unavailable_on_monday,
      Tue: merchantInfo.is_unavailable_on_tuesday,
      Wed: merchantInfo.is_unavailable_on_wednesday,
      Thu: merchantInfo.is_unavailable_on_thursday,
      Fri: merchantInfo.is_unavailable_on_friday,
      Sat: merchantInfo.is_unavailable_on_saturday,
    };
  
    const threeDays = [0, 1, 2].map(offset => {
      const date = new Date(today);
      date.setDate(today.getDate() + offset);
  
      const dayOfWeek = daysOfWeek[date.getDay()];
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const dayOfMonth = String(date.getDate()).padStart(2, '0');
      const fullDate = `${year}-${month}-${dayOfMonth}`;
  
      if (unavailableDays[dayOfWeek] === 1) {
        return null;
      }
  
      return {
        date: date.getDate(),
        day: offset === 0 ? 'Today' : dayOfWeek,
        full_date: fullDate
      };
    }).filter(day => day !== null);
    return threeDays;
  }
  
  const threeDays = getThreeDays()

  // justin code
  function generatePickUpTimeList(operationHours, afterDurationMinutes, days) {
    const interval = 30;
    let firstTimeSlotFound = false;

    return days.map((day, dayIndex) => {
      let availableTimes = [];
      const currentTime = new Date();
      // currentTime.setHours(3);
      // currentTime.setMinutes(1);
      // currentTime.setSeconds(0);
      // currentTime.setMilliseconds(0);

      const earliestTime = new Date(
        currentTime.getTime() + afterDurationMinutes * 60000
      );

      operationHours.forEach((period) => {
        let [start, end] = period.split("-");
        let startDateTime = new Date(`${day.full_date}T${start}:00`);
        let endDateTime = new Date(`${day.full_date}T${end}:00`);

        if (endDateTime <= startDateTime) {
          endDateTime.setDate(endDateTime.getDate() + 1);
        }

        let currentSlot;

        if (earliestTime >= startDateTime && earliestTime <= endDateTime) {
          currentSlot = new Date(earliestTime);
          currentSlot.setMinutes(
            Math.ceil(currentSlot.getMinutes() / interval) * interval,
            0,
            0
          );
        } else if (earliestTime < startDateTime) {
          currentSlot = new Date(
            startDateTime.getTime() + afterDurationMinutes * 60000
          );
          currentSlot.setMinutes(
            Math.ceil(currentSlot.getMinutes() / interval) * interval,
            0,
            0
          );
        } else {
          return;
        }

        while (currentSlot <= endDateTime) {
          const timeString = currentSlot.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });
          const time24HrFormat = currentSlot.toTimeString().slice(0, 5);
          const fromNowMinutes = Math.round(
            (currentSlot - currentTime) / 60000
          );
          const fromNowText =
            fromNowMinutes >= 60
              ? `${Math.floor(fromNowMinutes / 60)} hrs ${
                  fromNowMinutes % 60
                } mins`
              : `${fromNowMinutes} mins`;

          if (
            dayIndex === 0 &&
            time24HrFormat === "00:00" &&
            currentTime.getHours() >= 3
          ) {
            currentSlot = new Date(currentSlot.getTime() + interval * 60000);
            continue;
          }

          availableTimes.push({
            time: timeString,
            from_now: fromNowText,
            "24hr_format": time24HrFormat,
            asap: !firstTimeSlotFound && dayIndex === 0,
          });

          if (!firstTimeSlotFound && dayIndex === 0) {
            firstTimeSlotFound = true;
          }

          currentSlot = new Date(currentSlot.getTime() + interval * 60000);
        }
      });

      availableTimes = availableTimes.sort((a, b) => {
        let timeA = new Date(`${day.full_date} ${a["24hr_format"]}:00`);
        let timeB = new Date(`${day.full_date} ${b["24hr_format"]}:00`);
        return timeA - timeB;
      });

      if (
        dayIndex > 0 &&
        availableTimes.length > 0 &&
        availableTimes[0]["24hr_format"] === "00:00"
      ) {
        availableTimes.shift();
        availableTimes.push({
          time: "12:00 AM",
          from_now: "1 day 0 hrs",
          "24hr_format": "00:00",
          asap: false,
        });
      }

      return {
        day: day.day,
        date: day.date,
        full_date: day.full_date,
        times: availableTimes,
      };
    });
  }
  
  const pickUpTimeList = generatePickUpTimeList(merchantInfo.operating_hour, merchantInfo.user_pickup_time, threeDays);

  useEffect(() => {
    if(threeDays?.length > 0 && !pickupDate?.date) {
        dispatch(setPickupDate(threeDays[0]))
    }
  }, [threeDays?.length, pickupDate])

  return (
    <>
      <HalfModal
        className='select-time'
        show={isOpenTimeModal}
        backButton={handleCloseTimeModal}
        type={realWidth >= 450 ? "mobile" : ""}
        desktopModal={width >= 991 ? true : false}
        screenSize={width >= 991 ? "xl" : ""}
        content={
          <>
            <ModalHeader
              title="Pick Up Details"
              backTo={handleCloseTimeModal}
              backToNoAnimation={handleCloseTimeModal}
              type={`model2 ${realWidth < 450 ? "half" : ""}`}
            />
            <MDBModalBody ref={modalRef} className="fixed-body p-0">
                <article className="select-time-modal">
                    <article className="pickup-info">
                        <article className="flex items-end gap-3">
                            <article>
                                <Icon icon="clarity:store-solid"/>
                            </article>
                            <h6>{merchantInfo.store_name}</h6>
                        </article>
                        <article className="flex items-start gap-3">
                            <article>
                                <Icon icon="hugeicons:location-04" color="transparent"/>
                            </article>
                            <p>{merchantInfo.display_address}</p>
                        </article>

                    </article>
                    <article className="select-date-time">
                        <section className="select-date">
                            {threeDays?.length > 0 && threeDays.map((tDay, tDayIndex) => (
                                <article 
                                    onClick={() => handleChooseDate(tDay)}
                                    className={`date ${pickupDate?.full_date === tDay.full_date ? '--active' : ''} pointer`}
                                    index={tDayIndex}
                                >
                                    <p>{tDay.day}</p>
                                    <article className="date-bg">
                                        <h5>{tDay.date}</h5>
                                    </article>
                                </article>
                            ))}
                        </section>
                        <section className="select-time">
                            <h6>Choose Pick Up Time : </h6>
                            <article className="time-list">
                                {pickUpTimeList?.length > 0 && pickUpTimeList.filter((pTime) => pickupDate?.full_date === pTime.full_date).map((pTime) => (
                                    pTime.times?.length > 0 && pTime.times.map((times, timesIndex) => (
                                        <article className="time pointer" key={timesIndex} onClick={() => handleChooseTime(times)}>
                                            <h5>{times.time}</h5>
                                            <article className="flex items-center gap-2">
                                                <span>{timesIndex === 0 && pTime.day === 'Today' ? 'ASAP' : ''}</span>
                                                {((pickupDate?.full_date === pTime.full_date && pickupTime.time === times.time) 
                                                  || ((!pickupDate.full_date || !pickupTime.time) && times.asap)) &&
                                                    <article>
                                                        <Icon icon="charm:tick" className="mb-1" />
                                                    </article>
                                                }
                                            </article>
                                        </article>
                                    ))
                                ))}
                            </article>
                        </section>
                    </article>
                </article>
            </MDBModalBody>
          </>
        }
      />
    </>
  );
}
