import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getExperience = createAsyncThunk('getExperience', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getExperience(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getTableSection = createAsyncThunk('getTableSection', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getTableSection(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getTimeSession = createAsyncThunk('getTimeSession', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getReservationTime(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getReservationTime = createAsyncThunk('getReservationTime', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getReservationTime(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const createReservation = createAsyncThunk('createReservation', async (payload, thunkAPI) => {
  try {
    const response = await apiService.createReservation(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getReservationInfo = createAsyncThunk('getReservationInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getReservationInfo(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  experienceList: [],
  tableSectionList: [],
  timeSessionList: [],
  reserveTimeList: [],
  reserveInfo: {},

  expLoading: false,
  tableLoading: false,
  timeLoading: false,
  sessionLoading: false,
  infoLoading: false,
};

const dataSlice = createSlice({
  name: "reserve",
  initialState: initialData,
  reducers: {
    setReservationTime: (state, action) => {
      state.reserveTimeList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getExperience.pending, (state) => {
      state.expLoading = true;
    })
    .addCase(getExperience.fulfilled, (state, action) => {
      state.expLoading = false;
      state.experienceList = action.payload.data;
    })
    .addCase(getExperience.rejected, (state) => {
      state.expLoading = false;
    })

    .addCase(getTableSection.pending, (state) => {
      state.tableLoading = true;
    })
    .addCase(getTableSection.fulfilled, (state, action) => {
      state.tableLoading = false;
      state.tableSectionList = action.payload.data;
    })
    .addCase(getTableSection.rejected, (state) => {
      state.tableLoading = false;
    })

    .addCase(getReservationTime.pending, (state) => {
      state.timeLoading = true;
    })
    .addCase(getReservationTime.fulfilled, (state, action) => {
      state.timeLoading = false;
      state.reserveTimeList = action.payload.data;
    })
    .addCase(getReservationTime.rejected, (state) => {
      state.timeLoading = false;
    })
    
    .addCase(getTimeSession.pending, (state) => {
      state.sessionLoading = true;
    })
    .addCase(getTimeSession.fulfilled, (state, action) => {
      state.sessionLoading = false;
      state.timeSessionList = action.payload.data;
    })
    .addCase(getTimeSession.rejected, (state) => {
      state.sessionLoading = false;
    })

    .addCase(createReservation.pending, (state) => {
      state.reserveLoading = true;
    })
    .addCase(createReservation.fulfilled, (state, action) => {
      state.reserveLoading = false;
    })
    .addCase(createReservation.rejected, (state) => {
      state.reserveLoading = false;
    })

    .addCase(getReservationInfo.pending, (state) => {
      state.infoLoading = true;
    })
    .addCase(getReservationInfo.fulfilled, (state, action) => {
      state.infoLoading = false;
      state.reserveInfo = action.payload.data;
    })
    .addCase(getReservationInfo.rejected, (state) => {
      state.infoLoading = false;
    });
  }
});

export const { 
  setReservationTime
} = dataSlice.actions;

export default dataSlice.reducer;
