import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Pusher from "pusher-js";

import scrollToTop from "../../helpers/scroll.to.top";

import { useDispatch, useSelector } from "react-redux";
import { getQueue } from "../../redux/reducer/queueReducer";
import { getToken, getUserId, getUsername } from "../../redux/reducer/commonReducer";

import QueueBody from "./components/queue.body";
import QueueHeader from "./components/queue.header";

export default function Queue() {
  const dispatch = useDispatch()

  const { merchantId, queueId, token, token_checked, userId, username } = useSelector((state) => state.common);

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    dispatch(getToken())
    dispatch(getUserId())
    dispatch(getUsername())
  }, [])

  useEffect(() => {
    if(merchantId) {
      dispatch(getQueue({
        merchant_id: merchantId,
        queue_entry_id: queueId ?? ''
      }))
    }
  }, [merchantId])

  useEffect(() => {
    if (token && token_checked && userId && queueId) {
      const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
        cluster: "ap1",
        authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            username: username ? username === 'null' ? null : username : null,
            "user-version": process.env.REACT_APP_VERSION,
          },
        },
      });

      const channel = pusher.subscribe("presence-queue-entry-" + queueId);
      var socketId = null;
      pusher.connection.bind("connected", () => {
        socketId = pusher.connection.socket_id;
      });

      channel.bind("queue-notification-event", (data) => {
        if (data) {
          dispatch(getQueue({
            merchant_id: merchantId,
            queue_entry_id: queueId ?? ''
          }))
        }
      });

      return () => {
        channel.unbind("queue-notification-event");
        pusher.disconnect();
      };
    }
  }, [token, token_checked, userId, queueId])

  return (
    <>
      <article className="queue-container">
        <QueueHeader />
        <QueueBody />
      </article>
    </>
  );
}
