import React, { useState, useContext, createContext, useEffect } from "react";
import toast, { ToastBar, Toaster, useToasterStore } from "react-hot-toast";

const ToastContext = createContext();

const ToasterComponent = () => {
    const { toasts } = useToasterStore();
    const TOAST_LIMIT = 1;

    useEffect(() => {
        toasts
            .filter((t) => t.visible) 
            .filter((_, i) => i >= TOAST_LIMIT) 
            .forEach((t) => toast.dismiss(t.id))
    }, [toasts]);

    return (
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 4000,
          }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ message }) => {
                      const { type } = t;

                      return (
                        <div
                          onClick={() => toast.dismiss(t.id)}
                          style={{
                              display: 'flex',
                              alignItems: 'center',
                              background: type === 'success' ? '#7fd290cc' : '#fa4e4ecc',
                              borderRadius: '30px',
                              color: '#fff',
                              backdropFilter: 'blur(5px)',
                              fontFamily: 'poppins',
                              padding: '7px 10px',
                              cursor: 'pointer',
                              fontSize: '18px'
                          }}
                        >
                          {type === 'success' ? '💁‍♀️' : '🔌'}
                          <div className="mt-50">{message}</div>
                        </div>
                      )
                    }}
                </ToastBar>
            )}
        </Toaster>
    );
};

export const ToastProvider = ({ children }) => {
    return (
        <ToastContext.Provider value={toast}>
            {children}
            <ToasterComponent />
        </ToastContext.Provider>
    );
};

export const useToast = () => useContext(ToastContext);
