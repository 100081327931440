import React, { useState, useEffect } from "react";

import { LoadScriptNext, Autocomplete } from "@react-google-maps/api";

import { useDispatch, useSelector } from "react-redux";
import {
  setIsNoAddressFound,
  setSearchingAddress,
} from "../../redux/reducer/addressReducer";
import { useToast } from "./toast.provider";

const libraries = ["places"];

const AddressInput = ({ onPlaceSelected, inputRef }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [autocomplete, setAutocomplete] = useState(null);
  const { searchingAddress } = useSelector((state) => state.address);

  const onLoad = (autoC) => setAutocomplete(autoC);

  const checkIsAddressFound = () => {
    const dropdownElements = document.getElementsByClassName("pac-item");

    if (dropdownElements.length === 0) {
      dispatch(setIsNoAddressFound(true));
    } else {
      dispatch(setIsNoAddressFound(false));
    }
  };

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace();

    const malaysiaBounds = {
      east: 119.27,  // Eastern longitude
      west: 99.64,   // Western longitude
      north: 6.80,   // Northern latitude
      south: 1.20,   // Southern latitude
    };

    if (
      place.geometry &&
      place.geometry.location &&
      place.geometry.location.lat() >= malaysiaBounds.south &&
      place.geometry.location.lat() <= malaysiaBounds.north &&
      place.geometry.location.lng() >= malaysiaBounds.west &&
      place.geometry.location.lng() <= malaysiaBounds.east
    ) {
      if (autocomplete !== null) {
        onPlaceSelected(autocomplete.getPlace());
      }
    } else {
      toast.error("Delivery service currently only available in Malaysia.");
      dispatch(setSearchingAddress(""));
    }
  };

  useEffect(() => {
    if (autocomplete) {
    }
  }, [autocomplete]);

  return (
    <LoadScriptNext
      googleMapsApiKey="AIzaSyBdum7iP8PiZQxxFb1OCE7HHx-uZodPI7E"
      language="en"
      libraries={["places"]}
    >
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        options={{
          componentRestrictions: { country: "MY" },
        }}
      >
        <input
          ref={inputRef}
          type="text"
          value={searchingAddress}
          placeholder="Search New Delivery Address"
          onChange={(e) => {
            checkIsAddressFound();
            dispatch(setSearchingAddress(e.target.value));
          }}
        />
      </Autocomplete>
    </LoadScriptNext>
  );
};

export default AddressInput;

//AIzaSyBdum7iP8PiZQxxFb1OCE7HHx-uZodPI7E
