import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { 
  DefaultVoucher, 
  DefaultHistory, 
  DefaultProfile, 
  DefaultLogout, 
  DefaultSpinWin, 
  DefaultFeedback, 
  DefaultDineIn, 
  DefaultQueue, 
  DefaultTakeAway, 
  DefaultDelivery, 
  DefaultAvatar, 
  StoreIcon, 
  DefaultTopup, 
  DefaultMarket, 
  DefaultReserve
} from "../../../assets/images";

import { useDispatch, useSelector } from "react-redux";
import { 
  openFeedbackModal, 
  openHistoryModal, 
  openLoginModal, 
  openMarketModal, 
  openProfileModal, 
  openPromotionDetailsModal, 
  openPromotionModal, 
  openScanQRModal, 
  openSetAddressModal, 
  openStoreInfoModal, 
  openTopUpModal, 
} from "../../../redux/reducer/modalReducer";
import { getDineIn, getMethod, getPromotionInfo, getTopUpPackageList } from "../../../redux/reducer/commonReducer";
import { getMarketplaceList, getPointBalance, getProfile, getUserRank, getWalletBalance } from "../../../redux/reducer/homeReducer";

import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import Button from "../../../components/element/button";
import { useToast } from "../../../components/common/toast.provider";

import AvailableVoucher from "./available.voucher";
import StampCollection from "./stamp.collection";
import Announcement from "./announcement";
import formatNumber from "../../../helpers/format.number";
import OtherOutlet from "./other.outlet";

export default function HomeBody({ address, setLoginType }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams()
  const { width } = useWindowDimensions()
  
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status")

  const { walletBalance, pointBalance, walletLoading, pointLoading, promoCount, promoCountLoading } = useSelector((state) => state.home);
  const { checkInLoading, stampCollection } = useSelector((state) => state.check_in);
  const { token, login, username, method: selectedMethod, merchantId, tableId } = useSelector((state) => state.common);
  const { themeColor, merchantInfo, marketplaceIcon, avatarIcon, storeIcon, historyIcon, voucherIcon, topUpIcon, profileIcon, reservationIcon, queueIcon, deliveryIcon, dineInIcon, selfPickIcon, logoutIcon, feedbackIcon, spinWinIcon, checkInIcon } = useSelector((state) => state.merchant);
  
  const { store_status, has_dine_in, has_take_away, has_delivery, has_queue, has_check_in, has_spin_wheel, has_topup, has_marketplace, has_membership, has_reservation } = merchantInfo;

  const zeroMethodCount = [has_dine_in, has_take_away, has_delivery].filter(value => value === 0).length;
  let zeroFeatureCount = [has_queue, has_spin_wheel, has_topup, has_reservation].filter(value => value === 0).length;

  if (has_marketplace === 0 && has_membership === 0) {
    zeroFeatureCount += 1;
  }

  const features = [
    {value: 'queue', has: 'queue', title: 'Queue', subtitle: 'Simplify your wait with our virtual queue system, ensuring a hassle-free experience for all.', image: queueIcon ?? DefaultQueue, mobile_image: queueIcon ?? DefaultQueue},
    {value: 'reservation', has: 'reservation', title: width > 600 ? 'Feedback Me What You Feel' : 'Feedback', subtitle: `Your opinion matters! Share your thoughts and feedback with us and receive rewards in return.`, image: reservationIcon ?? DefaultReserve, mobile_image: reservationIcon ?? DefaultReserve},
    {value: 'spin-wheel', has: 'spin_wheel', title: 'Spin & Win', subtitle: `Take a spin on our wheel to win fantastic prizes! Minimum spend required to qualify for a spin.`, image: spinWinIcon ?? DefaultSpinWin, mobile_image: spinWinIcon ?? DefaultSpinWin},
    {value: 'marketplace', has: 'marketplace', title: width > 600 ? '' : '', subtitle: ``, image: marketplaceIcon ?? DefaultMarket, mobile_image: marketplaceIcon ?? DefaultMarket},
    {value: 'top-up', has: 'topup', title: 'Top Up', subtitle: `Recharge your wallet with attractive top-up packages, ensuring a seamless transaction experience.`, image: topUpIcon ?? DefaultTopup, mobile_image: topUpIcon ?? DefaultTopup},
    {value: 'feedback', has: 'feedback', title: width > 600 ? 'Feedback Me What You Feel' : 'Feedback', subtitle: `Your opinion matters! Share your thoughts and feedback with us and receive rewards in return.`, image: feedbackIcon ?? DefaultFeedback, mobile_image: feedbackIcon ?? DefaultFeedback},
  ]

  const loginFeatures = [
    // {value: 'marketplace', title: 'Member', image: marketplaceIcon ?? DefaultMarket},
    {value: 'voucher', title: 'My Voucher', image: voucherIcon ?? DefaultVoucher, badge: promoCount},
    {value: 'history', title: 'My Order', image: historyIcon ?? DefaultHistory},
    {value: 'profile', title: 'Profile', image: profileIcon ?? DefaultProfile},
    {value: 'log out', title: 'Logout', image: logoutIcon ?? DefaultLogout}
  ]

  const methods = [
    {value: 'dine in', title: 'Dine In', subtitle: 'Enjoy here', image: dineInIcon ?? DefaultDineIn},
    {value: 'take away', title: 'Self Pick', subtitle: 'Pick it up', image: selfPickIcon ?? DefaultTakeAway},
    {value: 'delivery', title: 'Delivery', subtitle: 'Delivered to you', image: deliveryIcon ?? DefaultDelivery}
  ]

  const handleOpenStoreInfo = () => {
    dispatch(openStoreInfoModal())
  }

  const handleLogout = () => {
    const deviceId = localStorage.getItem('device_id');
    localStorage.clear();
    if (deviceId) {
      localStorage.setItem('device_id', deviceId);
    }

    toast.success('Logout successful.')
    window.location.reload()
  }

  const handleChooseMethod = (method) => {
    if(store_status === 'available') {
      if (method === 'delivery' && address === null) {
          dispatch(openSetAddressModal())
          return
      }

      if (method !== 'dine in' || (method === 'dine in' && tableId)) {
        if (merchantId) {
          localStorage.setItem('method', method)
          dispatch(getMethod())
          navigate(`/${store}/menu`)
        }
      } else {
        dispatch(openScanQRModal())
      }
    } else {
       toast.error('This store is temporarily closed.') 
    }
  }

  const handleChooseLoginFeature = (value) => {
    if((value !== 'log out' && value !== 'history' 
    && (value !== 'marketplace' || (value === 'marketplace' && merchantInfo.has_marketplace === 0)))
    && login !== 'full') {
      setLoginType(`full-${value}`)
      dispatch(openLoginModal())

      return
    }

    if (value === 'log out') {
      handleLogout()
    } else if (value === 'voucher') {
      dispatch(openPromotionModal())
    } else if (value === 'marketplace') {
      dispatch(openMarketModal())
      dispatch(getMarketplaceList({
        columns: 'costing',
        order: 'asc',
        merchant_id: merchantId,
        applicable_type: 'marketplace'
      }))

      if (login === 'full') {
        dispatch(getPointBalance({
          merchant_id: merchantId
        }))
      }
    } else if (value === 'history') {
      navigate(`/${store}/order/all`)
    } else if (value === 'profile') {
      dispatch(getProfile())
      dispatch(openProfileModal())
    }
  }

  const handleNavTo = (value) => {
    if(value === 'marketplace') {
      if(login !== 'full' && merchantInfo.has_marketplace === 0) {
        setLoginType(`full-${value}`)
        dispatch(openLoginModal())
        return
      }

      if (login === 'full') {
        dispatch(getPointBalance({
          merchant_id: merchantId
        }))
      }

      if(merchantInfo.has_membership === 1) {
        dispatch(getUserRank({
          merchant_id: merchantId,
        }))
      }

      if(merchantInfo.has_marketplace === 1) {
        dispatch(getMarketplaceList({
          columns: 'costing',
          order: 'asc',
          merchant_id: merchantId,
          applicable_type: 'marketplace'
        }))
      }

      dispatch(openMarketModal())

      return
    }


    if(value === 'feedback') {
      dispatch(openFeedbackModal())
      return
    }

    if (value === 'queue') {
      navigate(`/${store}/queue`)
      return
    }

    if(value === 'top-up') {
      dispatch(getTopUpPackageList({
        merchant_id: merchantId
      }))
      dispatch(openTopUpModal())

      return
    }

    navigate(`/${store}/${value}`)
    return
  }

  const handleLogin = () => {
    if(!token || login !== 'full') {
      setLoginType('full')
      dispatch(openLoginModal())
    }
  }

  const handleViewWallet = () => {
    if(!token || login !== 'full') {
      setLoginType('full')
      dispatch(openLoginModal())
    } else {
      dispatch(openHistoryModal())
    }
  }

  const handleViewPoint = () => {
    if(!token || login !== 'full') {
      setLoginType('full')
      dispatch(openLoginModal())
    } else {
      dispatch(openMarketModal('point'))
    }
  }

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
  };

  const applyColorToImage = (color) => {
    const rgbColor = hexToRgb(color ?? '#3C387F');

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
        <filter id="colorize">
          <feColorMatrix type="matrix" values={`0 0 0 0 ${rgbColor.r / 255}
                                                0 0 0 0 ${rgbColor.g / 255}
                                                0 0 0 0 ${rgbColor.b / 255}
                                                0 0 0 1 0`} 
          />
        </filter>
        <image xlinkHref={StoreIcon} width="40" height="40" filter="url(#colorize)" />
      </svg>
    );
  }

  useEffect(() => {
    if(status === 'success') {
      dispatch(getTopUpPackageList({
        merchant_id: merchantId
      }))
      .unwrap()
      .then((res) => {
        dispatch(openTopUpModal())
      })
      .catch((ex) => {})
    }
  }, [status])

  return (
    <article className="home-body"> 
        <section className="profile-section" style={{ paddingBottom: token && login === 'partial' ? '0px' : '8px' }}>
            <section className={`top-part full`}>
              <article className="flex items-center gap-4 pointer" onClick={() => handleLogin()}>
                <article>
                    <article className="profile-frame">
                        <LazyLoadImage src={avatarIcon || DefaultAvatar} />
                    </article>
                </article>
                {token && login === 'full' ? 
                  <article className="mt-2">
                    <h2>Hi, {username}</h2>
                    {(token && login === 'full' && (!walletLoading && !pointLoading) && 
                    (walletBalance > 0 || pointBalance > 0)) && 
                      <article className="flex flex-wrap items-center">
                        <p>Balance :&nbsp;</p>
                        <Button btnClassName='wallet-btn'>
                          {/* {token && login !== 'full' && 'Wallet'} */}
                          {walletBalance > 0 && 
                            <article onClick={() => handleViewWallet()}>
                              <Icon icon="solar:wallet-bold-duotone" />
                              <span>&nbsp;{parseFloat(walletBalance).toFixed(2)}</span>
                            </article>
                          }
                          {pointBalance > 0 && 
                            <>
                              {walletBalance > 0 && <span>/</span>}
                              <span className="text-lowercase" onClick={() => handleViewPoint()}> 
                              {parseFloat(pointBalance).toFixed(2)} pts
                              </span>
                            </>
                          }
                        </Button>
                      </article>
                    }
                  </article>
                :
                  <article className="mt-2">
                      <article className="flex justify-between items-end">
                        <h2>{login === 'partial' ? `Hi, ${username}` : 'Login / Register'}</h2>
                      </article>
                      <p>Register as Member and your next meal could be free.</p>
                  </article>
                }
              </article>
              <article className="store-info pointer" onClick={() => handleOpenStoreInfo()}>
                <article className="store-frame">
                  {storeIcon ?
                    <LazyLoadImage src={storeIcon} />
                  :
                    <>
                      <article className="store-bg" />
                      {applyColorToImage(themeColor.main_color)}
                    </>
                  }
                </article>
              </article>
            </section>
            {token && (login === 'full' || login === 'partial') && 
              <section className="bottom-part">
                {loginFeatures.map((loginFeature, loginFeatureIndex) => (
                  (loginFeature.value !== 'marketplace' || 
                  (loginFeature.value === 'marketplace' && (merchantInfo.has_marketplace === 1 || merchantInfo.has_membership === 1))) &&
                    <article className="grid center" onClick={() => handleChooseLoginFeature(loginFeature.value)} key={loginFeatureIndex}>
                      <article className="bottom-frame pointer">
                        <LazyLoadImage src={loginFeature.image} />
                        {(!promoCountLoading && loginFeature.badge && loginFeature.badge > 0) ? <article className={`number-badge ${loginFeature.badge > 99 ? '--plus' : ''}`}>
                          <p>{loginFeature.badge < 99 ? loginFeature.badge : '99+'}</p>
                        </article> : ''}
                      </article>
                      <h5 className="pointer">{loginFeature.title}</h5>
                    </article>
                ))}
              </section>
            }
        </section>
        
        {merchantInfo?.announcement?.length > 0 && <Announcement />}
        <AvailableVoucher setLoginType={setLoginType} />

        <section className={`method-section ${zeroMethodCount === 1 ? '--col-2' : zeroMethodCount === 2 ? '--col-1' : '--col-3'}`}>
          {merchantInfo && methods.map((method, methodIndex) => (
            merchantInfo[`has_${method.value.replace(/\s+/g, '_')}`] === 1 && (
            <article 
              className="method-col" 
              onClick={() => handleChooseMethod(method.value)}
              key={methodIndex}
            >
              <article className="method-frame">
                  <LazyLoadImage src={method.image} />
              </article>
              <article className='method-detail'>
                <h1>{method.title}</h1>
                <p className="text-capitalize">{method.subtitle}</p>
              </article>
            </article>  
          )))}
        </section>

        {has_check_in === 1 && !checkInLoading && stampCollection.length > 0 ? 
            <StampCollection />
          : has_check_in === 1 && checkInLoading ? 
            <Skeleton
              width={width > 991 ? "98%" : "97%"}
              height="84px"
              borderRadius="10px"
              className="skeleton --stamp"
            />
          : ''
        }

        <section className={`feature-section pointer ${zeroFeatureCount === 3 || zeroFeatureCount === 1 ? '--col-3' : zeroFeatureCount === 5 ? '--col-1' : '--col-2'}`}>
          {features.map((feature, featureIndex) => (
            ((feature.has !== 'marketplace' && merchantInfo[`has_${feature.has.replace(/\s+/g, '_')}`] === 1) 
            || (feature.has == 'marketplace' && (merchantInfo[`has_marketplace`] === 1 || merchantInfo[`has_membership`] === 1))
            || feature.has === 'feedback') && (
            <article key={featureIndex} style={{ background: 'var(--tertiary_modal_color)', borderRadius: '12px' }} className="feature-grid">
              <article className="feature-row" style={{ backgroundImage: `url(${feature.image})` }} onClick={() => handleNavTo(feature.value)}>
                <article className="feature-frame">
                  {width > 600 && <LazyLoadImage src={feature.image} />}
                </article>
                <article className={`w-100 ${zeroFeatureCount === 1 || (zeroFeatureCount === 0 && !checkInLoading && stampCollection.length === 0) ? '' : 'h-100'}`}>
                  {width > 600 && <h4>{feature.title}</h4>}
                  {width > 600 && <p>{feature.subtitle}</p>}
                </article>
              </article>  
            </article>
          )))}
        </section>
        <OtherOutlet />
    </article>
  );
}
