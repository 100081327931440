// Import necessary React and Material-UI components
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Create the AccountMenu component
export default function ActionMenu(props) {
  const { className, menus, left, top, handleSortPrice, handleEdit, handleDelete, handlePickupTime, item } =
    props;

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (menus.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      toast.error("Add something into cart first.", {
        theme: "light",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip>
          <div onClick={(e) => handleClick(e)}>{props.children}</div>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: top ? top : 1.5,
            ml: left,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 5,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {menus?.length > 0 &&
          menus.map((menu, menuIndex) => (
            <MenuItem
              onClick={() => {
                if (menu.action === "edit") {
                  handleEdit(item);
                } else if (menu.action === "delete") {
                  handleDelete(item);
                } else if (menu.action === 'asc' || menu.action === 'desc') {
                  handleSortPrice(menu.action)
                } else {
                  handlePickupTime(menu.value ? menu.value : menu);
                }
              }}
              key={menuIndex}
              className={className}
            >
              <div className="flex gap-3 items-center pointer">
                {menu.icon && (
                  <Icon
                    icon={menu.icon}
                    color={"#113B63"}
                    width={18}
                    height={18}
                  />
                )}
                <label className="pointer">
                  {menu.label ? menu.label : menu}
                </label>
              </div>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
