import OneSignal from 'react-onesignal';
import { subscribeNotification } from '../../redux/reducer/commonReducer';
import { useDispatch } from 'react-redux';

//returnext-user
export const initializeOneSignal = (
  setInitialized, 
  setNotificationPermission, 
  notificationPermission, 
  dispatch, 
  themeColor
) => {
    OneSignal.init({ 
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID, 
      safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID,
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: true,
        size: 'medium',
        position: 'bottom-right',
        theme: 'default',
        prenotify: true,
        showCredit: false,
        text: {
          'tip.state.unsubscribed': 'Stay up-to-date with important updates.',
          'tip.state.subscribed': `You're going to receive timely updates.`,
          'tip.state.blocked': 'Access notification settings to enable updates.',
          'message.prenotify': 'Click to allow notification.',
          'message.action.subscribed': 'Notifications setting updated!',
          'message.action.resubscribed': 'Notifications re-enabled successfully!',
          'message.action.unsubscribed': `You won't receive notifications anymore.`,
          'dialog.main.title': 'Manage Notifications',
          'dialog.main.button.subscribe': 'Notify Me',
          'dialog.main.button.unsubscribe': `Don't Notify Me`,
          'dialog.blocked.title': `Unblock Notifications`,
          'dialog.blocked.message': 'Follow these instructions to allow notifications:'
        },
        colors: {
          'circle.background': themeColor.btn_color,
          'circle.foreground': '#ffffff',
          'badge.background': '#ff0000',
          'badge.foreground': '#ffffff',
          'badge.bordercolor': '#ff0000',
          'pulse.color': themeColor.third_color,
        }
      },
      welcomeNotification: {
          title: 'Welcome to FoodLink!',
          message: 'Stay tuned for the latest updates.',
      }
    })
    .then(() => {
      setInitialized(true)
      const isUserPushSubscription = OneSignal.User && OneSignal.User.PushSubscription;

      const handleSubscriptionChange = async () => {
        if (isUserPushSubscription) {
          const { optedIn, id: subcriptionId} = OneSignal.User.PushSubscription;
          const newPermission = optedIn ? "granted" : "denied";

          dispatch(subscribeNotification({
              subscription_id: subcriptionId,
              subscription_status: optedIn ? 1 : 0,
              notification_status: notificationPermission,
              type: "app",
            })
          );

          setNotificationPermission(newPermission);
        }
      };
  
      if (isUserPushSubscription) {
        // dispatch(setNotificationStatus(notificationStatus));
        OneSignal.User.PushSubscription.addEventListener('change', handleSubscriptionChange);
      }

      return () => {
        if (isUserPushSubscription) {
          OneSignal.User.PushSubscription.removeEventListener('change', handleSubscriptionChange);
        }
      };
    })
    .catch((ex) => {
    });
  };
  
export const observeNotificationChanges = (notificationPermission) => {

  if (notificationPermission === 'denied') {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
      if (mutation.addedNodes && mutation.addedNodes.length > 0) {
        const instructionElements = document.querySelectorAll('.instructions');

        instructionElements.forEach(element => {
          const anchorTag = element.querySelector('a');
          
          if (anchorTag) {
            anchorTag.href = 'https://i.ibb.co/09dvXz6/foodlink-unblock-notification.png';
          }
          
          const imgTag = element.querySelector('img');
          if (imgTag) {
            imgTag.src = 'https://i.ibb.co/09dvXz6/foodlink-unblock-notification.png';
          }
        });
      }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }
};
