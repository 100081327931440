import { useDispatch, useSelector } from 'react-redux';

import { Sample } from '../../../assets/images';

import { Icon } from '@iconify/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { getCheckInList } from '../../../redux/reducer/checkInReducer';
import { setConfirmItem, setUseNowItem } from '../../../redux/reducer/commonReducer';
import { closeCouponPopUp, openConfirmModal, openLoginModal } from '../../../redux/reducer/modalReducer';

import Button from '../../../components/element/button';
import SymbolLine from '../../../components/common/symbol.line';

export default function CouponPopUp() {
  const dispatch = useDispatch();

  const { useNowItem, login, merchantId } = useSelector((state) => state.common);

  const handleCloseCouponPopUp = () => {
    if(useNowItem.type === 'just claimed') {
      dispatch(getCheckInList({
        merchant_id: merchantId
      }))
    }
    
    dispatch(setUseNowItem({}))
    dispatch(closeCouponPopUp())
  }

  const handleUseVoucher = async () => {
    if(login === 'full') {
      handleCloseCouponPopUp()
      dispatch(openConfirmModal())
      dispatch(setConfirmItem({
        type: 'use now',
        item_type: useNowItem.item_type,
        item: useNowItem.promotion_id,
      }))
    } else {
        dispatch(openLoginModal())
    }
  }

  return (
    <article className={`popup-container`}>
      <section className="popup-content">
        <article className="popup-coupon">
            <section className="coupon-info">
                <article className="coupon">
                    <SymbolLine>Coupon</SymbolLine>
                    <article className="coupon-frame">
                        <LazyLoadImage src={useNowItem.media?.length > 0 && useNowItem.media[0].url || Sample} />
                    </article>
                    <article className='flex justify-between mt-4 mb-4'>
                        <h2 className=' text-220'>{useNowItem.display_name}</h2>
                        <h2>x1</h2>
                    </article>
                    {useNowItem.expiry_date && <p>{ useNowItem.type === 'days' ||  useNowItem.type === 'just claimed' ? `Expire in ${useNowItem.expiry_date} days` : `Valid till ${useNowItem.expiry_date}`}</p>}
                </article>
                <article className="coupon --use">
                    <Button className="w-100" btnClassName="use-now-btn" onClick={() => handleUseVoucher()}>
                        Use Now
                    </Button>
                </article>
            </section>
        </article>
        <Button btnClassName="close-btn" onClick={() => handleCloseCouponPopUp()}>
          <Icon icon="maki:cross" />
        </Button>
      </section>
    </article>
  );
};
