import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getWheelList = createAsyncThunk('getWheelList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getWheelList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getSpinHistory = createAsyncThunk('getSpinHistory', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getSpinHistory(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  tokenLeft: 0,
  wheelList: [],
  spinHistory: [],
  spinWheelTerms: [
    {
      content: `Welcome to FoodLink's Spin Wheel Program! By participating in our Spin Wheel Program, you agree to comply with the following terms and conditions:`
    },
    {
      title: "Eligibility",
      content: "The FoodLink Spin Wheel Program is open to all individuals who visit our website or mobile app.",
    },
    {
      title: "Spin Wheel Prizes",
      content: "Each spin of the wheel entitles you to a chance to win exciting prizes, including discounts, coupons, freebies, and more.",
    },
    {
      title: "Spin Limits",
      content: "You may spin the wheel a limited number of times per day, as specified on the spin wheel interface.",
    },
    {
      title: "Prize Redemption",
      content: "Prizes won through the Spin Wheel Program are subject to availability and may be redeemed based on the terms and conditions associated with each prize.",
    },
    {
      title: "Freebies and Rewards",
      content: "Freebies and rewards won through the Spin Wheel Program will be credited to your account or provided as digital vouchers for redemption.",
    },
    {
      title: "Non-Transferable Prizes",
      content: "Prizes won through the Spin Wheel Program are non-transferable and cannot be exchanged for cash or any other form of currency.",
    },
    {
      title: "Verification of Winners",
      content: "FoodLink reserves the right to verify the eligibility of winners and may require proof of identity or other documentation before awarding prizes.",
    },
    {
      title: "Participant Responsibility",
      content: "Participants are responsible for ensuring the accuracy of their personal information provided for prize redemption. FoodLink will not be liable for any incorrect or incomplete information provided by participants.",
    },
    {
      title: "Prohibited Activities",
      content: "The Spin Wheel Program is intended for genuine participation only. Any attempt to manipulate or abuse the spin wheel system, including but not limited to using automated scripts or multiple accounts, will result in disqualification from the program and forfeiture of prizes.",
    },
    {
      title: "Changes to Terms",
      content: "FoodLink reserves the right to modify, suspend, or terminate the Spin Wheel Program or its terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website or app.",
    },
    {
      title: "Acceptance of Terms",
      content: "By participating in the Spin Wheel Program, you acknowledge that you have read, understood, and agreed to these terms and conditions.",
    },
    {
      content: `For any questions or concerns regarding the Spin Wheel Program, please contact our customer support team at`,
      email: process.env.REACT_APP_SUPPORT_EMAIL
    },
    {
      content: `Thank you for joining FoodLink's Spin Wheel Program and best of luck with your spins!`
    },
  ],
  noLoading: false,
  maxSpin: '',
  spinPeriod: {},
  spinWheelLoading: false,
  spinHistoryLoading: false,

  hasMore: false,
  length: 10,
  start: 0,
  data: []
};

const dataSlice = createSlice({
  name: "spin_to_win",
  initialState: initialData,
  reducers: {
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setStart: (state, action) => {
      state.start = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLength: (state, action) => {
      state.length = action.payload;
    },
    setNoLoading: (state, action) => {
      state.noLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getWheelList.pending, (state) => {
      const noLoading = state.noLoading;
      state.spinWheelLoading = noLoading ? false : true;
    })
    .addCase(getWheelList.fulfilled, (state, action) => {
      state.spinWheelLoading = false;
      state.spinPeriod = {
        start_at: action.payload.data.activate_start_at,
        end_at: action.payload.data.activate_end_at
      }
      state.wheelList = action.payload.data.spin_wheel_prizes;
      state.spinToken = action.payload.data.user_token;
      state.maxSpin = action.payload.data.max_spin;
      state.amountGetToken = action.payload.data.require_amount_per_token;
    })
    .addCase(getWheelList.rejected, (state) => {
      state.spinWheelLoading = false;
    })

    .addCase(getSpinHistory.pending, (state) => {
      state.spinHistoryLoading = true;
    })
    .addCase(getSpinHistory.fulfilled, (state, action) => {
      state.spinHistoryLoading = false;
      state.spinHistory = action.payload.data.aaData;
    })
    .addCase(getSpinHistory.rejected, (state) => {
      state.spinHistoryLoading = false;
    });
  }
});

export const { 
  setNoLoading,
  setHasMore,
  setStart,
  setData,
  setLength
} = dataSlice.actions;

export default dataSlice.reducer;
