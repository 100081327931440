import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useWindowDimensions from "../hook/use.window.dimensions";
import {
    closePrizeDetailsModal,
  closePromotionDetailsModal,
  openConfirmModal,
  openLoginModal,
  openMarketModal,
  openPrizeModal,
  openPromotionModal,
  openTopUpModal,
  openVoucherModal,
  setAnimationModal,
} from "../../redux/reducer/modalReducer";
import FullModal from "../modal/full.modal.box";
import ModalHeader from "../header/modal.header";
import { MDBModalBody } from "mdb-react-ui-kit";
import Coupon from "./coupon";
import Button from "../element/button";
import {
  setIsBuyingPromo,
  setIsViewingPromo,
} from "../../redux/reducer/homeReducer";
import { setConfirmItem } from "../../redux/reducer/commonReducer";
import { Icon } from "@iconify/react";
import { useToast } from "./toast.provider";
import { RWebShare } from "react-web-share";
import { formatNumber } from "./return.number";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function PrizeDetails() {
//   const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
//   const navigate = useNavigate();
  const { width } = useWindowDimensions();
//   const { pathname } = useLocation();
//   const { store } = useParams();

//   const [searchParams, setSearchParams] = useSearchParams();

  const { prizeItem } = useSelector((state) => state.common);
  const { isOpenPrizeDetailsModal } = useSelector((state) => state.modal);

  const handleClosePrizeDetails = () => {
    dispatch(closePrizeDetailsModal());
    dispatch(openPrizeModal());
  };

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenPrizeDetailsModal}
      backButton={handleClosePrizeDetails}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName="--white"
      content={
        <>
          <ModalHeader
            title="Prize Details"
            backTo={handleClosePrizeDetails}
            backToNoAnimation={handleClosePrizeDetails}
            type="model2"
          />
          <MDBModalBody className="fixed-body" ref={modalRef}>
            <article className="details-modal">
              <section className="prize-frame">
                <LazyLoadImage src={prizeItem.media?.length > 0 && prizeItem.media[0].url} />
              </section>
              <section className="details-info --prize">
                <article>
                  <h3>Description</h3>
                  <p>{prizeItem.description}</p>
                </article>
                <article>
                  <h3>Reward</h3>
                  <p>
                    x1 {prizeItem.reward_name}
                  </p>
                </article>
                <article>
                  <h3>Terms & Conditions</h3>
                  <div dangerouslySetInnerHTML={{ __html: prizeItem.tnc }} />
                </article>
              </section>
            </article>
          </MDBModalBody>
          <section className="model2-summary --fixed">
              <Button
                onClick={() => handleClosePrizeDetails()}
                className="w-100"
                // disabled={promoInfoLoading}
              >
                Close
              </Button>
          </section>
        </>
      }
    />
  );
}
