import { useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { motion, AnimatePresence } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";
import { getPromotionInfo, setUseNowItem } from "../../../redux/reducer/commonReducer";
import { openCouponPopUp, openHistoryModal, openPromotionDetailsModal, openTncModal } from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { Tooltip } from "react-tooltip";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSpinHistory, setData, setHasMore, setStart } from "../../../redux/reducer/spinToWinReducer";
import { useParams } from "react-router-dom";

export default function SpinInfo() {
  const dispatch = useDispatch()
  const { store } = useParams()
  const { width, height } = useWindowDimensions()

  const { spinHistoryLoading, start, length, data, hasMore, spinToken, amountGetToken, maxSpin, spinWheelLoading } = useSelector((state) => state.spin_to_win);
  const { merchantId} = useSelector((state) => state.common);

  const [showScrollTop, setShowScrollTop] = useState(false);

//   let scrollHeight = height - (width > 550 ? height * 0.35 : height * 0.2);

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength) + '...';
  };

  const getDateTime = (dateTime) => {
    const dateObject = new Date(dateTime);
  
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  
    return formattedDateTime;
  }

  const handleOpenTnc = () => {
    dispatch(openTncModal())
  }

  const handleOpenPromoDetails = (id) => {
    dispatch(openPromotionDetailsModal())
    dispatch(getPromotionInfo({
      id: id
    }))
  }

  const handleUseNow = (history) => {
    if(history.type === 'credit' || history.promotion_type === 'free credit') {
        dispatch(openHistoryModal())
    } else {
        dispatch(setUseNowItem({
            type: 'days',
            item_type: 'spin wheel',
            promotion_id: history.user_promotion_bag_id,
            media: history.media,
            display_name: history.display_name,
            expiry_date: history.expiry_day_count
        }))
        dispatch(openCouponPopUp())
    }
  }

  const getMoreData = () => {
    dispatch(
      getSpinHistory({
        merchant_id: merchantId,
        has_reward: '',
        startDate: '',
        endDate: '',
        start: start + 10,
        search: store,
        length: length
      })
    )
    .unwrap()
    .then((res) => {
    dispatch(setStart(start + 10));

    let newData = [];
    for (var key in res.data.aaData) {
        newData.push({
        ...res.data.aaData[key],
        });
    }
    if (data.length + newData.length >= res.data.iTotalRecords) {
        dispatch(setHasMore(false));
    }
    dispatch(setData([...data, ...newData]));
    })
    .catch((ex) => {});
  };
    
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;

      setShowScrollTop(scrollTop > 700);
    };
  
    window.addEventListener('scroll', handleScroll);

    return () => {
    window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
        top: 800,
        behavior: "smooth",
    });
  };

  return (
    <section className='coupon-info'>
        <article className="container">
            <section className="spending">
                <article className="flex justify-between items-center">
                    <h4>Token {spinToken?.remaining_token} / <span>{spinToken?.total_token}</span></h4>
                    <article className="flex items-end gap-3 pointer" onClick={() => handleOpenTnc()}>
                        <p>Read T&C</p>
                        <Icon icon="ion:chevron-right" />
                    </article>
                </article>
                <article className="spend-progress-bar"> 
                    <article className="progress" style={{ width: `${((spinToken?.remaining_token / spinToken?.total_token) * 100)}%` }}/>
                </article>
                {!spinWheelLoading ?
                     maxSpin === 1 ? 
                        <h3>Every Single Bill Above RM {spinWheelLoading ? '0.00' : parseFloat(amountGetToken).toFixed(2)} Get 1 Token</h3>
                    : 
                        <article className="flex justify-between items-end flex-wrap">
                            <h3>Spend Every RM {spinWheelLoading ? '0.00' : parseFloat(amountGetToken).toFixed(2)} Get 1 Token</h3>
                            <p className="max-spin">Maximum Get {maxSpin} Token Per Bill</p>
                        </article>
                : ''
                }
            </section>
            <section className="spending --voucher">
                <article className="w-100 grid center">
                    <article className="title">
                        <h4>Spin History</h4>
                    </article>
                </article>
                <InfiniteScroll
                    className='ps-0 pe-0'
                    dataLength={data.length}
                    // height={`${scrollHeight}px`}
                    hasMore={hasMore}
                    next={() => {
                        getMoreData();
                    }}
                    loader={
                        hasMore && (
                        <div className="text-center mt-5 infinite-loading-label">
                            Loading..
                        </div>
                        )
                    }
                >
                    {(hasMore || !spinHistoryLoading) && data?.length > 0 ?
                    data.map((history, historyIndex) => (
                        <article className={`coupon-grid ${history.type === null ? '--nothing' : ''} pe-4 mb-4`} key={historyIndex}>
                            <section className={`flex items-center gap-4 ${history.type !== null && history.type !== 'credit' ? 'pointer' : ''}`} onClick={() => history.type !== null && history.type !== 'credit' ? handleOpenPromoDetails(history.promotion_id) : undefined}>
                                <article className="w-100">
                                    <article className="w-100 with-info pt-2">
                                        <h5 className={history.unavailable_reason ? '--disabled' : ''}>{history.reward_name}</h5>
                                        {/* {history.unavailable_reason &&
                                            <article className="ms-2" onClick={(e) => e.stopPropagation()}>
                                                <Icon icon="solar:info-circle-line-duotone" data-tooltip-id="claim-before" />
                                            </article>
                                        } */}
                                    </article>
                                    {/* <br /> */}
                                    <p className={`condition ${history.unavailable_reason ? '--disabled mt-1' : 'mt-3'}`} data-tooltip-id={history.unavailable_reason?.length > 60 ? "claim-before" : ""}>
                                        {history.unavailable_reason ? truncateString(history.unavailable_reason, 60) : 'spinned at '}
                                        {!history.unavailable_reason && <span>{getDateTime(history.created_at)}</span>}&nbsp;&nbsp;
                                        {history.type !== null && history.type !== 'credit' && <span className="tnc">T&C</span>}
                                    </p>
                                </article>
                                {history.unavailable_reason && <Tooltip
                                    id="claim-before"
                                    place="top"
                                    content={history.unavailable_reason}
                                />}
                            </section>
                            {history.type !== null ? 
                                <section className="use-section">
                                    <article className="use-info">
                                        {(history.promotion_type === 'free credit' || history.type === 'credit' || history.status !== 'unavailable') &&
                                            <Button 
                                                className='w-100 grid center' 
                                                disabled={history.type !== 'credit' && history.promotion_type !== 'free credit' && history.status !== 'available'} 
                                                btnClassName="use-now-btn" 
                                                onClick={() => handleUseNow(history)}
                                            >
                                                {history.type === 'credit' || history.promotion_type === 'free credit' ? 'View' : history.status !== 'available' ? 'Used' : 'Use Now'}
                                            </Button>
                                        }
                                    </article>
                                </section>
                            :
                                ''
                            }
                        </article>
                    ))
                    : 
                        !hasMore && spinHistoryLoading ? 
                            Array.from({ length: 2 }, (_, spinSkeletonIndex) => (
                                <Skeleton
                                    width="100%"
                                    height="70px"
                                    borderRadius="8px"
                                    key={spinSkeletonIndex}
                                    className="mb-4"
                                />
                            ))
                        : 
                            ''
                    }
                    {!hasMore && !spinHistoryLoading ? 
                        data.length > 0 ? 
                            <div className="text-center mt-5 infinite-loading-label --nothing">
                                Nothing more..
                            </div>
                        :
                            <article className="empty-container">
                                <p>No spin history record found</p>
                            </article>
                    : 
                        ""
                    }
                </InfiniteScroll>
            </section>
        </article>
        <AnimatePresence>
            {showScrollTop && 
                <motion.article
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        ease: 'easeInOut',
                        type: 'spring',
                        duration: 1.5,
                    }}
                >
                    <motion.button
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.2 }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scroll-to-top"
                        onClick={handleScrollToTop}
                        transition={{
                            ease: 'easeInOut',
                            type: 'spring',
                            duration: 1,
                        }}
                    >
                    <Icon icon="icon-park-outline:to-top" />
                    </motion.button>
                </motion.article>
            }
        </AnimatePresence>
    </section>
  );
}
