import { Icon } from "@iconify/react";
import Marquee from "react-fast-marquee";

import { useSelector } from "react-redux";

export default function Announcement() {
  const { merchantInfo } = useSelector((state) => state.merchant);

  return (
    <section className="announcement-ticker"> 
        <Icon
          icon="mdi:announcement"
        />
        <Marquee
          gradient={true}
          gradientWidth={15}
        >
          {merchantInfo?.announcement?.length > 0 && (
            merchantInfo.announcement.map((notice, noticeIndex) => (
              <p key={noticeIndex}>&nbsp;&nbsp;{notice.content}</p>
            ))
          )}
        </Marquee>
    </section>
  );
}
