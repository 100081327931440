import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Rider } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import dayjs from 'dayjs';
import { openStoreInfoModal } from "../../../redux/reducer/modalReducer";

export default function MethodInfo({ order }) {
  const dispatch = useDispatch()
  const { merchantInfo } = useSelector((state) => state.merchant);

  const handleNavToDelyva = () => {
    window.open(order.delivery_link, '_blank');
  }

  const handleOpenStore = () => {
    dispatch(openStoreInfoModal())
  }

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24?.split(':').map(Number);
    const period = hours >= 12 ? 'pm' : 'am';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
  };

  const calculateEndTime = (startTime, duration) => {
    const [hours, minutes] = startTime?.split(':').map(Number);
    const [durationMin, durationMax] = duration
      .split('~')
      .map((str) => parseInt(str.replace(' mins', '').trim(), 10));
  
    const startMinutes = hours * 60 + minutes;
    const endMinTime = startMinutes + durationMin;
    const endMaxTime = startMinutes + durationMax;
  
    const endMinHours = Math.floor(endMinTime / 60) % 24;
    const endMinMinutes = endMinTime % 60;
    const endMaxHours = Math.floor(endMaxTime / 60) % 24;
    const endMaxMinutes = endMaxTime % 60;
  
    const endMinFormatted = convertTo12HourFormat(
      `${endMinHours < 10 ? '0' : ''}${endMinHours}:${endMinMinutes < 10 ? '0' : ''}${endMinMinutes}`
    );
    const endMaxFormatted = convertTo12HourFormat(
      `${endMaxHours < 10 ? '0' : ''}${endMaxHours}:${endMaxMinutes < 10 ? '0' : ''}${endMaxMinutes}`
    );
  
    return `${endMinFormatted} ~ ${endMaxFormatted}`;
  };

  return (
    <>
      {order.method === 'delivery' ?
        <section className="method-info pointer">
          <article className="grid">
            <article className="location-time">
              <article><Icon icon="line-md:map-marker-multiple-alt-filled" /></article>
              <article>
                <h3>Delivery Address</h3>
                {/* {(order.username || (username && username !== "null")) && <p>Phone no. | {order.username ? order.username : username}</p>} */}
                <p>{order.delivery_address}</p>
              </article>
            </article>
            <article className="location-time">
              <Icon icon="line-md:speed-loop" />
              <article>
                <h3>ETA : {calculateEndTime(order.order_time, order.duration)} ({order.distance})</h3>
              </article>
            </article>
            <article className="location-time mb-0">
              <Icon icon="line-md:alert-circle-twotone" />
              <article>
                <p className="mb-0 extra">Orders will be delivered by the merchant 
                <br /> Review address correctly, rider will send exact address</p>
              </article>
            </article>
          </article>
          {order.delivery_link && <article className="grid center" onClick={() => handleNavToDelyva()}>
            <article className="rider-frame">
              <LazyLoadImage src={Rider} />
            </article>
            <p className="view-text">View</p>
          </article>}
        </section>
      : order.method === 'take away' ? 
        <section className="method-info pointer" onClick={() => handleOpenStore()}>
          <article className="grid w-100">
            <article className="location-time --order-pick w-100 mb-0">
              <article className="flex items-center">
                <article className="flex gap-3"> 
                  <article><Icon icon="line-md:map-marker-filled" className="mt-1"/></article>
                  <article>
                    <h3>{merchantInfo.store_name}</h3>
                    <p>{merchantInfo.pic_name} | {merchantInfo.whatsapp_number}</p>
                    <p className="address">{merchantInfo.display_address}</p>
                  </article>
                </article>
                <article><Icon icon="ion:chevron-right" className="view-icon" /></article>
              </article>
              {order.scheduled_at && 
              <article className="location-time row-gap-0">
                <Icon icon="line-md:my-location-loop" />
                <article className="flex flex-wrap row-gap-0">
                  <h3 className="mb-0">Pick up on {dayjs(order.scheduled_at).isSame(dayjs(), 'day') ? 'Today' : dayjs(order.scheduled_at).format('ddd')}, {dayjs(order.scheduled_at).format('h:mm A')}</h3>
                </article>
              </article>}
              {(merchantInfo.google_map_link || merchantInfo.waze_link) && <article className="store-location">
                {merchantInfo.waze_link !== null && 
                  <Button 
                    onClick={(e) => {
                      e.stopPropagation()

                      if(merchantInfo.waze_link !== null) {
                        window.open(merchantInfo.waze_link, '_blank')
                      }
                    }}
                  >
                    <Icon icon='simple-icons:waze' className="--map" />
                  </Button>
                }
                {merchantInfo.google_map_link !== null && 
                  <Button 
                    onClick={(e) => {
                      e.stopPropagation()
                      
                      if(merchantInfo.google_map_link !== null) {
                        window.open(merchantInfo.google_map_link, '_blank')
                      }
                    }}
                  >
                    <Icon icon='simple-icons:googlemaps' className="--map" />
                  </Button>
                }
              </article>}
            </article>
            {/* <article className="location-time mb-0">
              <Icon icon="line-md:my-location-loop" />
              <article>
                <h3>Estimate Pickup Time : 10Min</h3>
              </article>
            </article> */}
          </article>
        </section>
      : ''
      }
    </>
  );
}
