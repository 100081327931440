import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";
import authService from "../../services/auth.service";
import { P } from "../../assets/images";

export const getGuestToken = createAsyncThunk('getGuestToken', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getGuestToken(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getTopUpPaymentList = createAsyncThunk('getTopUpPaymentList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getTopUpPaymentList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getTopUpPackageList = createAsyncThunk('getTopUpPackageList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getTopUpPackageList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getPromotionInfo = createAsyncThunk('getPromotionInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getPromotionInfo(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getMerchantList = createAsyncThunk('getMerchantList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getMerchantList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getSupermerchantList = createAsyncThunk('getSupermerchantList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getSupermerchantList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const subscribeNotification = createAsyncThunk('subscribeNotification', async (payload, thunkAPI) => {
  try {
    const response = await apiService.subscribeNotification(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const handleLogout = createAsyncThunk('logout', async (payload, thunkAPI) => {
  try {
    const response = await authService.logout(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  footerListing: [
    {
      id: 1,
      title: "Service",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [
      ],
    },
    {
      id: 2,
      title: "About",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [
      ],
    },
    {
      id: 3,
      title: "Support",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [
        // {
        //   label: "Support",
        //   to: "",
        // },
      ],
    },
  ],
  navigation_item: [
    {
      label: "Home",
      navigate: "/",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
    {
      label: "Store",
      navigate: "/store",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
    {
      label: "MyCar",
      navigate: "/car",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
    {
      label: "Profile",
      navigate: "/profile",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
  ],
  fullLoading: false,
  token: '',
  userId: '',
  cartOwner: '',
  token_checked: false,
  tableId_checked: false,
  voucherPopup_checked: false, 
  login: '',
  guest: null,
  username: '',
  deviceId: '',
  deviceId_checked: false,
  queueId: '',
  reserveId: '',
  reserveData: [],
  addressId: '',
  merchantId: '',
  voucherPopup: '',
  isPopupDisplayed: true,
  cartId: '',
  tableId: '',
  sessionNo: '',
  topUpPaymentList: [],
  topUpPaymentLoading: false,
  topUpPackageList: [],
  topUpPackageLoading: false,
  promoInfoLoading: false,
  confirmItem: {},
  useNowItem: {},
  prizeItem: {},
  promoInfo: {},
  merchantListLoading: false,
  supermerchantListLoading: false,
  merchantList: [],
  supermerchantList: [],
  type: '',
  dineIn: '',
  isShowModalLoading: false,
};

const dataSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    setShowModalLoading: (state, action) => {
      state.isShowModalLoading = action.payload;
    },
    setPrizeItem: (state, action) => {
      state.prizeItem = action.payload;
    },
    setConfirmItem: (state, action) => {
      state.confirmItem = action.payload;
    },
    setUseNowItem: (state, action) => {
      state.useNowItem = action.payload;
    },
    setFullLoading: (state, action) => {
      state.fullLoading = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setReserveId: (state, action) => {
      state.reserveId = action.payload;
    },
    getToken: (state, action) => {
      state.token = localStorage.getItem("token");
      state.token_checked = true
    },
    getLogin: (state, action) => {
      state.login = localStorage.getItem("login");
    },
    getGuest: (state, action) => {
      state.guest = localStorage.getItem("guest");
    },
    getUserId: (state, action) => {
      state.userId = localStorage.getItem("user_id");
    },
    getCartOwner: (state, action) => {
      state.cartOwner = localStorage.getItem("cart_owner");
    },
    getVoucherPopup: (state, action) => {
      state.voucherPopup = localStorage.getItem("voucher_popup");
      state.voucherPopup_checked = true
    },
    getMethod: (state, action) => {
      state.method = localStorage.getItem("method");
    },
    getDineIn: (state, action) => {
      state.dineIn = localStorage.getItem("dine_in");
    },
    getUsername: (state, action) => {
      state.username = localStorage.getItem("username");
    },
    getDeviceId: (state, action) => {
      state.deviceId = localStorage.getItem("device_id");
      state.deviceId_checked = true
    },
    getTableId: (state, action) => {
      state.tableId = localStorage.getItem("table_id");
      state.tableId_checked = true
    },
    getSessionNo: (state, action) => {
      state.sessionNo = localStorage.getItem("session_no");
    },
    getCartId: (state, action) => {
      state.cartId = localStorage.getItem("cart_id");
    },
    getAddressId: (state, action) => {
      state.addressId = localStorage.getItem("address_id");
    },
    getMerchantId: (state, action) => {
      state.merchantId = localStorage.getItem("merchant_id");
    },
    getMerchantName: (state, action) => {
      state.merchantName = localStorage.getItem("merchant_name");
    },
    getQueueId: (state, action) => {
      state.queueId = localStorage.getItem("queue_id");
    },
    getReserveData: (state, action) => {
      state.reserveData = JSON.parse(localStorage.getItem("reserve_data")) || [];
    },
    getPopupDisplayed: (state, action) => {
      state.isPopupDisplayed = localStorage.getItem("is_popup_displayed");
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getTopUpPaymentList.pending, (state) => {
      state.topUpPaymentLoading = true;
    })
    .addCase(getTopUpPaymentList.fulfilled, (state, action) => {
      state.topUpPaymentLoading = false;
      state.topUpPaymentList = action.payload.data;
    })
    .addCase(getTopUpPaymentList.rejected, (state) => {
      state.topUpPaymentLoading = false;
    })
    
    .addCase(getTopUpPackageList.pending, (state) => {
      state.topUpPackageLoading = true;
    })
    .addCase(getTopUpPackageList.fulfilled, (state, action) => {
      state.topUpPackageLoading = false;
      state.topUpPackageList = action.payload.data;
    })
    .addCase(getTopUpPackageList.rejected, (state) => {
      state.topUpPackageLoading = false;
    })

    .addCase(getPromotionInfo.pending, (state) => {
      state.promoInfoLoading = true;
    })
    .addCase(getPromotionInfo.fulfilled, (state, action) => {
      state.promoInfoLoading = false;
      state.promoInfo = action.payload.data;
    })
    .addCase(getPromotionInfo.rejected, (state) => {
      state.promoInfoLoading = false;
    })

    .addCase(getMerchantList.pending, (state) => {
      state.merchantListLoading = true;
    })
    .addCase(getMerchantList.fulfilled, (state, action) => {
      state.merchantListLoading = false;
      state.merchantList = action.payload.data;
    })
    .addCase(getMerchantList.rejected, (state) => {
      state.merchantListLoading = false;
    })
    
    .addCase(getSupermerchantList.pending, (state) => {
      state.supermerchantListLoading = true;
    })
    .addCase(getSupermerchantList.fulfilled, (state, action) => {
      state.supermerchantListLoading = false;
      state.supermerchantList = action.payload.data.aaData;
    })
    .addCase(getSupermerchantList.rejected, (state) => {
      state.supermerchantListLoading = false;
    });
  }
});

export const { 
  getToken,
  getUsername,
  getDeviceId,
  getPopupDisplayed,
  getLogin,
  getGuest,
  getUserId,
  getCartOwner,
  getVoucherPopup,
  getMethod,
  getAddressId,
  getMerchantId,
  getMerchantName,
  getTableId,
  getCartId,
  getQueueId,
  getReserveData,
  getSessionNo,
  setReserveId,
  setUseNowItem,
  setConfirmItem,
  setPrizeItem,
  setFullLoading,
  setShowModalLoading,
  setType,
  getDineIn
} = dataSlice.actions;

export default dataSlice.reducer;

