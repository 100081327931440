import { useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { Tooltip } from "react-tooltip";
import { Skeleton } from "primereact/skeleton";
import { motion, AnimatePresence } from "framer-motion";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { getCheckInList } from "../../../redux/reducer/checkInReducer";
import { getPromotionInfo, setUseNowItem } from "../../../redux/reducer/commonReducer";
import { openCouponPopUp, openLoginModal, openPromotionDetailsModal, openTncModal } from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";

import apiService from "../../../services/api.service";

export default function CheckInInfo() {
  const toast = useToast();
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const { login, merchantId } = useSelector((state) => state.common);
  const { amountGetStamp, checkInProgress, checkInLoading, checkInHistory } = useSelector((state) => state.check_in);

  const [showScrollTop, setShowScrollTop] = useState(false);

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength) + '...';
  };

  const getDateTime = (dateTime) => {
    const dateObject = new Date(dateTime);
  
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  
    return formattedDateTime;
  }

  const handleOpenTnc = () => {
    dispatch(openTncModal())
  }

  const handleOpenPromoDetails = (id) => {
    dispatch(openPromotionDetailsModal())
    dispatch(getPromotionInfo({
      id: id
    }))
  }

  const handleUseNow = (history) => {
    dispatch(setUseNowItem({
        type: 'days',
        item_type: 'check in',
        promotion_id: history.user_promotion_bag_id,
        media: history.promotion_pic,
        display_name: history.promotion_title,
        expiry_date: history.promotion_expiry_day_count
    }))
    dispatch(openCouponPopUp())
  }

  const handleClaimReward = async (history) => {    
    if(login === 'full') {
        try {
            const response = await apiService.claimCheckInReward({
                user_check_in_id: history.id,
            })

            if(response) {
                toast.success('Claim reward successful!')
                if(history.promotion_type === 'free menu') {
                    dispatch(setUseNowItem({
                        item_type: 'check in',
                        promotion_id: response.data.user_promotion_bag_id,
                        media: response.data.image,
                        display_name: response.data.name,
                        expiry_date: response.data.expiry_day_count
                    }))
                    dispatch(openCouponPopUp())
                }
                dispatch(getCheckInList({
                    merchant_id: merchantId
                }))
            }
        } catch (ex) {
            if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        toast.error(errors[item][0]);
                    });
                }
            }
        }
    } else {
        dispatch(openLoginModal())
    }
  }

  const handleScrollToTop = () => {
    window.scrollTo({
        top: 500,
        behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;

      setShowScrollTop(scrollTop > 700);
    };
  
    window.addEventListener('scroll', handleScroll);

    return () => {
    window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className='coupon-info'>
        <article className="container">
            <section className="spending">
                <article className="flex justify-between items-center">
                    <h4>PROGRESS : {checkInLoading ? '' : checkInProgress?.checked_in > 0 ? checkInProgress?.checked_in : 0} / <span>{checkInLoading ? '' : checkInProgress?.total_check_in}</span></h4>
                    <article className="flex items-end gap-3 pointer" onClick={() => handleOpenTnc()}>
                        <p>Read T&C</p>
                        <Icon icon="ion:chevron-right" />
                    </article>
                </article>
                <article className="spend-progress-bar"> 
                    <article className="progress" style={{ width: `${(((checkInProgress?.checked_in > 0 ? checkInProgress?.checked_in : 0) / (checkInLoading ? 0 : checkInProgress?.total_check_in) * 100))}%` }}/>
                </article>
                <h3>Every Single Bill Above RM {checkInLoading ? '0.00' : parseFloat(amountGetStamp).toFixed(2)} Get 1 Stamp</h3>
            </section>
            <section className="spending --voucher">
                <article className="w-100 grid center">
                    <article className="title">
                        <h4>Check In History</h4>
                    </article>
                </article>
                {!checkInLoading && checkInHistory.length > 0 ? checkInHistory.map((history, historyIndex) => (
                    <article className={`coupon-grid ${history.has_reward === 0 ? '--nothing' : history.available_status === 'available to claim' ? '--ready' : ''} pe-4`} key={historyIndex}>
                        <section className={`flex items-center gap-4 ${history.has_reward === 1 && history.available_status === 'available' ? 'pointer' : ''}`} onClick={() => history.available_status !== null && history.promotion_type !== 'credit' && (history.available_status !== 'used' || history.available_status !== 'expired') ? handleOpenPromoDetails(history.promotion_id) : undefined}>
                            <article className="pt-2 w-100">
                                <article className="with-info">
                                    <h5 className={history.unavailable_reason ? '--disabled' : ''}>{history.has_reward === 1 ? history.promotion_title : `You've earned one check-in stamp`}</h5>
                                    {/* {((history.available_status === 'unavailable' && history.unavailable_reason) || (history.available_status === 'available to claim' && history.claim_expiry_date)) &&
                                        <article className="ms-2" onClick={(e) => e.stopPropagation()}>
                                            <Icon icon="solar:info-circle-line-duotone" data-tooltip-id={history.available_status === 'unavailable' && history.unavailable_reason ? "unavailable-use" : "claim-before"} />
                                        </article>
                                    } */}
                                </article>
                                <article className="flex justify-between flex-wrap">
                                {/* <p className={`condition ${history.available_status === 'available to claim' ? 'mt-2' : 'mt-3'}`}>checked at <span>{getDateTime(history.checked_in_at)}</span>&nbsp;&nbsp;
                                    {(history.available_status !== 'used' || history.available_status !== 'expired') && history.has_reward === 1 && <span className="tnc">T&C</span>}
                                </p> */}

                                <p className={`condition ${history.unavailable_reason ? '--disabled' : ''} ${history.unavailable_reason ? 'mt-1' : history.available_status === 'available to claim' ? 'mt-2' : 'mt-3'}`} data-tooltip-id={history.unavailable_reason?.length > 60 ? "claim-before" : ""}>
                                    {history.unavailable_reason ? truncateString(history.unavailable_reason, 60) : 'checked at '}
                                    {!history.unavailable_reason && <span>{getDateTime(history.checked_in_at)}</span>}&nbsp;&nbsp;
                                    {(history.available_status !== 'used' || history.available_status !== 'expired') && history.has_reward === 1 && <span className="tnc">T&C</span>}
                                </p>
                                </article>
                            </article>
                            {history.available_status === 'unavailable' && history.unavailable_reason ?
                                <Tooltip 
                                    id="unavailable-use"
                                    place={width > 550 ? "left" : "bottom"}
                                    content={history.unavailable_reason}
                                />
                            : history.available_status === 'available to claim' && history.claim_expiry_date ?
                                <Tooltip 
                                    id="claim-before"
                                    place={width > 550 ? "left" : "bottom"}
                                    content={`Please claim before ${getDateTime(history.claim_expiry_date)}`}
                                />
                            : ''
                            }
                        </section>
                        {history.has_reward === 1 ?
                            <section className="use-section">
                                <article className="use-info">
                                    {(history.available_status === 'available to claim' || history.available_status === 'available' || history.available_status === 'expired' || history.available_status === 'used') && 
                                        <Button 
                                            className='w-100 grid center' 
                                            disabled={history.available_status === 'used' || history.available_status === 'expired'} 
                                            btnClassName="use-now-btn" 
                                            onClick={() => 
                                                history.available_status === 'available' ? handleUseNow(history)
                                                : history.available_status === 'available to claim' ? handleClaimReward(history)
                                                : undefined
                                            }
                                        >
                                            {history.available_status === 'available to claim' ? 'Claim' 
                                            : history.available_status === 'available' ? 'Use Now' 
                                            : history.available_status === 'used' ? 'Used'
                                            : history.available_status === 'expired' ? 'Expired'
                                            : ''}
                                        </Button>
                                    }
                                </article>
                            </section>
                        :
                            ''
                        }
                    </article>
                ))
                : 
                    checkInLoading ? 
                        Array.from({ length: 2 }, (_, spinSkeletonIndex) => (
                            <Skeleton
                                width="100%"
                                height="70px"
                                borderRadius="8px"
                                key={spinSkeletonIndex}
                            />
                        ))
                    : 
                        <article className="empty-container">
                            <p>No check in record found</p>
                        </article>
                }
            </section>
        </article>
        <AnimatePresence>
            {showScrollTop && 
                <motion.article
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        ease: 'easeInOut',
                        type: 'spring',
                        duration: 1.5,
                    }}
                >
                    <motion.button
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.2 }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="scroll-to-top"
                        onClick={handleScrollToTop}
                        transition={{
                            ease: 'easeInOut',
                            type: 'spring',
                            duration: 1,
                        }}
                    >
                    <Icon icon="icon-park-outline:to-top" />
                    </motion.button>
                </motion.article>
            }
        </AnimatePresence>
    </section>
  );
}
