import { useEffect, useRef } from "react";

import { DefaultCategory } from "../../../assets/images";

import { Skeleton } from "primereact/skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useSelector } from "react-redux";

export default function CategoryBar({
  onCategoryClick,
  scrollActiveCategory,
  clickActiveCategory,
}) {
  const categoryBarRef = useRef(null);

  const { method } = useSelector((state) => state.common);
  const {
    isAddingCartId,
    deliveryMenuList,
    dineInMenuList,
    takeAwayMenuList,
    menuListLoading,
    showRecommend,
    recommendMenuLoading,
    recommendMenu,
  } = useSelector((state) => state.menu);

  const menuList =
    method === "delivery"
      ? deliveryMenuList
      : method === "dine in"
      ? dineInMenuList
      : takeAwayMenuList;

  useEffect(() => {
    if (categoryBarRef.current) {
      const activeCategory = scrollActiveCategory;
      const activeElement = document.getElementById(
        `category-item-${activeCategory}`
      );

      if (activeElement) {
        const scrollContainer = categoryBarRef.current;
        scrollContainer.scrollTo({
          top: activeElement.offsetTop - 400,
          behavior: "smooth",
        });
      }
    }
  }, [scrollActiveCategory]);

  return (
    <aside
      className="category-bar"
      ref={categoryBarRef}
      style={{
        paddingTop:
          isAddingCartId && recommendMenu?.length > 0 && showRecommend
            ? ""
            : (!recommendMenuLoading && recommendMenu?.length === 0) ||
              !showRecommend ||
              recommendMenuLoading
            ? "0em"
            : "",
      }}
    >
      {!menuListLoading || isAddingCartId
        ? menuList?.length > 0 &&
          menuList.map(
            (menuCategory, menuCategoryIndex) =>
              menuCategory.menu.length > 0 && (
                <section
                  className={`category-grid 
            ${
              clickActiveCategory
                ? clickActiveCategory === menuCategory.category_id
                  ? "--active"
                  : ""
                : scrollActiveCategory
                ? scrollActiveCategory === menuCategory.category_id
                  ? "--active"
                  : ""
                : ""
            } pointer`}
                  onClick={() => onCategoryClick(menuCategory.category_id)}
                  key={menuCategoryIndex}
                  id={`category-item-${menuCategory.category_id}`}
                >
                  <article className="category-frame">
                    <LazyLoadImage
                      src={
                        menuCategory.media.length > 0
                          ? menuCategory.media[0].url
                          : DefaultCategory
                      }
                    />
                  </article>
                  <p>{menuCategory.name}</p>
                </section>
              )
          )
        : Array.from({ length: 3 }, (_, categorySkeletonIndex) => (
            <section
              className="category-grid pointer"
              key={categorySkeletonIndex}
            >
              <Skeleton
                width="100%"
                height="42px"
                borderRadius="8px"
                className="mt-3"
              />
            </section>
          ))}
    </aside>
  );
}
