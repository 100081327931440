
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import { Skeleton } from "primereact/skeleton";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useDispatch, useSelector } from "react-redux";
import { closePlaceOrderModal } from "../../../redux/reducer/modalReducer";

import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import FullModal from "../../../components/modal/full.modal.box";
import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import InputCount from "../../../components/element/input.count";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalHeader from "../../../components/header/modal.header";
import {
  DefaultMenu,
  DefaultOption,
  DefaultTakeAway,
  DefaultTakeAwayOption
} from "../../../assets/images";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  getMenuInfo,
  getMenuList,
  getRecommendMenu,
  setQuantity,
} from "../../../redux/reducer/menuReducer";
import { useEffect, useRef, useState } from "react";
import apiService from "../../../services/api.service";
import {
  getAddOnMenu,
  getCartCount,
  getCartInfo,
  setEditCartData,
} from "../../../redux/reducer/cartReducer";
import {
  getCartId,
  getCartOwner,
  setConfirmItem,
} from "../../../redux/reducer/commonReducer";
import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import { Tooltip } from "react-tooltip";

export default function PlaceOrder() {
  const toast = useToast();
  const modalRef = useRef(null);
  const formikRef = useRef(null)
  const dispatch = useDispatch();
  const { width, realWidth } = useWindowDimensions();
  const { store } = useParams();
  const { pathname } = useLocation();

  const {
    method,
    addressId,
    cartId,
    tableId,
    merchantId,
    sessionNo,
    userId
  } = useSelector((state) => state.common);
  const { editCartData } = useSelector((state) => state.cart);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { isOpenPlaceOrderModal } = useSelector((state) => state.modal);
  const { menuId, menuInfo, quantity, menuInfoLoading } = useSelector((state) => state.menu);

  const [id, setId] = useState("");
  const [top, setTop] = useState(false);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [quantityChange, setQuantityChange] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [initialPrice, setIntialPrice] = useState(false);

  const option_list = menuInfo.option_listing ? menuInfo.option_listing : [];

  const cartForm = {
    menu_id: menuId,
    merchant_id: merchantId,
    quantity: editCartData.quantity ? editCartData.quantity : 1,
    remark: editCartData.remark ? editCartData.remark : "",
    method: "",
    option: [],
  };

  const addCartForm =
    option_list.length > 0
    ? option_list.reduce((acc, group) => {
        const defaultSelection =
          group.option_item_min === 1 || (method === 'take away' && group.option_name === 'Take Away') || (method === "delivery" && group.option_name === 'Delivery') ? [group.option_item[0].option_item_id] : [];

        return {
          ...acc,
          ...cartForm,
          [group.option_id]: defaultSelection,
        };
      }, {})
    : cartForm;

  const editCartForm = {
    ...cartForm,
    ...(editCartData?.details?.length > 0 &&
      editCartData.details.reduce((acc, item) => {
        if (!acc[item.option_id]) {
          acc[item.option_id] = [];
        }
        acc[item.option_id].push(item.option_item_id);
        return acc;
      }, {})),
  };

  const cartSchema = Yup.object().shape(
    option_list.reduce(
      (acc, group) => ({
        ...acc,
        [group.option_id]: Yup.array()
          .min(group.option_item_min, `Minimum select ${group.option_item_min}`)
          .max(
            group.option_item_max,
            `Maximum select  ${group.option_item_max}`
          ),
      }),
      {}
    )
  );
  
  const handleSubmit = async ({ values, setFieldError }) => {
    let newOption = [];

    for (var key in option_list) {
      values[option_list[key].option_id]?.map((item) =>
        newOption.push({ option_item_id: Number(item) })
      );
    }

    try {
      setPlacingOrder(true)
      if (editCartData.cart_item_id) {
        const response = await apiService.updateCart({
          cart_id: cartId,
          cart_item_id: editCartData.cart_item_id,
          quantity: quantity ? quantity : "",
          remark: values.remark,
          option: newOption,
          promotion_id: editCartData.promotion_id,
          user_promotion_bag_id: editCartData.user_promotion_bag_id,
          do_not_use_any: null,

          ...(method === "delivery" && {
            "address_id": addressId
          }),
        });

        if (response) {
          dispatch(
            getCartInfo({
              order_method: method,
              merchant_id: merchantId,
            })
          )
          .unwrap()
          .then((res) => {
            setQuantityChange(false)
            dispatch(setEditCartData({}));
            dispatch(closePlaceOrderModal());
            setPlacingOrder(false)
          })
          .catch((ex) => {})
          dispatch(
            getCartCount({
              order_method: method,
              merchant_id: merchantId,
            })
          );
        }
      } else {
        const response = await apiService.addCart({
          cart_id: cartId,
          menu_id: menuId,
          quantity: quantity ? quantity : "",
          remark: values.remark,
          method: method,
          option: newOption,
          table_id: method === "dine in" ? tableId ? tableId === 'null' ? null : tableId : null : null,
          session_no: method === "dine in" ? sessionNo ? sessionNo === 'null' ? null : sessionNo : null : null,

          ...(method === "delivery" && {
            "address_id": addressId
          }),
        });

        if (response) {
          setQuantityChange(false)

          if (cartId && pathname === `/${encodeURIComponent(store.trim())}/cart`) {
            dispatch(
              getAddOnMenu({
                type: "add on items",
                merchant_id: merchantId,
                cart_id: cartId,
                method: method,
              })
            );
          }
          if (!cartId) {
            // dispatch(
            //   getMenuList({
            //     merchant_id: merchantId,
            //     remark: "food",
            //     method: method,
            //     table_id: method === "dine in" ? tableId : null,
            //     session_no: sessionNo ?? null,
            //   })
            // );
          }
          dispatch(
            getCartInfo({
              order_method: method,
              merchant_id: merchantId,
            })
          )
            .unwrap()
            .then((res) => {
              setPlacingOrder(false)
              dispatch(closePlaceOrderModal());
              if (res.data.length > 0) {
                localStorage.setItem("cart_id", res.data[0].cart_id);
                if(res.data[0].user_id === parseInt(userId)) {
                  localStorage.setItem("cart_owner", true);
                }
                dispatch(getCartId());
                dispatch(getCartOwner());
              }
            })
            .catch((ex) => {})
          dispatch(
            getCartCount({
              order_method: method,
              merchant_id: merchantId,
            })
          );
        }
      }
    } catch (ex) {
      setPlacingOrder(false)
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item][0];
              toast.error(errorMsg);
            });
          }
        }

        if (ex.response?.status === 404) {
          localStorage.removeItem("cart_id");
          dispatch(getCartId())
          handleClosePlaceOrder()
          toast.error('Your cart has been outdated. Please refresh.');
         
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      }
    }
  };

  const calculateOptionPrices = (values, menuInfo) => {
    let optionPriceTotal = 0;
  
    if (menuInfo && menuInfo.option_listing?.length > 0) {
      menuInfo.option_listing.forEach((option) => {
        if (values && Array.isArray(values[option.option_id])) {
          values[option.option_id].forEach((itemId) => {
            const item = option.option_item.find(
              (item) => item.option_item_id === itemId
            );
            if (item) {
              optionPriceTotal += parseFloat(item.option_item_price);
            }
          });
        }
      });
    }
    return parseFloat(optionPriceTotal);
  };

  const handleClosePlaceOrder = () => {
    setQuantityChange(false)
    dispatch(closePlaceOrderModal());
    dispatch(setEditCartData({}));
  };

  useEffect(() => {
    const updatedSelectedOptions = {};

    if (editCartData?.menu_id) {
      dispatch(
        getMenuInfo({
          method: method,
          id: editCartData?.menu_id,
        })
      )
      .unwrap()
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }
      });

      dispatch(setQuantity(editCartData.quantity));

      if (editCartData?.details?.length > 0) {
        editCartData.details.forEach((item) => {
          updatedSelectedOptions[item.option_id] = item.option_item_id;
        });
        setSelectedOptions(updatedSelectedOptions);
      }
    } else {
      dispatch(setQuantity(1));
    }
  }, [editCartData]);

  useEffect(() => {
    setTop(true);
  }, [isOpenPlaceOrderModal]);

  useEffect(() => {
    if (top && modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [top]);

  useEffect(() => {
    if (!menuInfoLoading) {
      setTimeout(() => {
        const quantity = quantityChange 
          ? quantityChange 
          : editCartData.quantity 
            ? editCartData.quantity 
            : 1;

        const unitPrice = menuInfo.promotion_price 
          ? menuInfo.promotion_price 
          : menuInfo.unit_price;

        const optionsPrice = calculateOptionPrices(formikRef.current?.values, menuInfo);

        const total = (parseFloat(unitPrice) * quantity) + (optionsPrice * quantity);
        setTotalPrice(total.toFixed(2));
        setIntialPrice(true)
      }, 500)
    }
  }, [menuInfoLoading]);

  return (
    <FullModal
      show={isOpenPlaceOrderModal}
      backButton={handleClosePlaceOrder}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName="--visible"
      content={
        <>
          <ModalHeader
            title={editCartData?.menu_id ? "Update Order" : "Place Order"}
            backTo={handleClosePlaceOrder}
            backToNoAnimation={handleClosePlaceOrder}
            type="model2"
          />
          <MDBModalBody ref={modalRef}>
            <Formik
              innerRef={formikRef}
              initialValues={
                editCartData.cart_item_id ? editCartForm : addCartForm
              }
              validationSchema={cartSchema}
              enableReinitialize={true}
              onSubmit={(values, { setFieldError }) => {
                handleSubmit({ values, setFieldError });
              }}
            >
              {({ setFieldValue, values, errors, isValid }) => (
                <Form>
                  <article className="place-order-modal">
                    <section className={`food-frame ${!menuInfo.picture && merchantInfo.company_logo.length > 0 ? '--store-logo' : ''}`}>
                      {!menuInfoLoading ? (
                        <LazyLoadImage
                          src={menuInfo.picture || (merchantInfo.company_logo.length > 0 ? merchantInfo.company_logo[0].url : DefaultMenu)}
                          alt={menuInfo.name}
                        />
                      ) : (
                        <Skeleton width="100%" height="260px" />
                      )}
                    </section>
                    <section className="food-section">
                      {!menuInfoLoading ? (
                        <article className="food-info">
                          <section>
                            <h2>{menuInfo.name}</h2>
                            <p>{menuInfo.description ?? ""}</p>
                          </section>
                          <section className="grid end">
                            {menuInfo.promotion_price ? (
                              <h2>
                                RM{" "}
                                {menuInfo.promotion_price
                                  ? parseFloat(
                                      menuInfo.promotion_price
                                    ).toFixed(2)
                                  : "0.00"}
                                <br />
                                <span>
                                  RM{" "}
                                  {parseFloat(menuInfo.unit_price).toFixed(2)}
                                </span>
                                <br />
                                <p className="base-price">Base price</p>
                              </h2>
                            ) : (
                              <h2>
                                RM {parseFloat(menuInfo.unit_price).toFixed(2)}
                                <br />
                                <p className="base-price">Base price</p>
                              </h2>
                            )}
                          </section>
                        </article>
                      ) : (
                        <article className="food-info">
                          <section>
                            <Skeleton
                              width="80%"
                              height="18px"
                              borderRadius="18px"
                            />
                            <Skeleton
                              width="70%"
                              height="12px"
                              borderRadius="18px"
                              className="mt-3"
                            />
                          </section>
                          <section>
                            <Skeleton
                              width="80%"
                              height="16px"
                              borderRadius="18px"
                            />
                            <Skeleton
                              width="100%"
                              height="12px"
                              borderRadius="18px"
                              className="mt-3"
                            />
                          </section>
                        </article>
                      )}
                      {!menuInfoLoading ? (
                        <FieldArray
                          name="option"
                          render={() =>
                            menuInfo.option_listing?.length > 0 &&
                            menuInfo.option_listing.map((option, index) => {
                              const max = option.option_item_max;

                              return (
                                <article
                                  className="food-option-info"
                                  key={option.option_id}
                                >
                                  <article className="info-flex">
                                    <h3>
                                      {option.option_name}&nbsp;&nbsp;
                                      <span>
                                        Option, max {option.option_item_max}
                                      </span>
                                    </h3>
                                    {errors && errors[option.option_id] ? (
                                      <div className="element _errors text-right no-padding error-message text-end">
                                        <label
                                          style={{
                                            fontSize:
                                              width > 550 ? "12px" : "11px",
                                            fontFamily: "cabin-semibold",
                                          }}
                                        >
                                          {errors[option.option_id]}
                                        </label>
                                      </div>
                                    ) : null}
                                  </article>
                                  <Swiper
                                    slidesPerView={
                                      realWidth < 350 
                                        ? 2.5
                                        : realWidth < 400 
                                        ? 3
                                        : width < 600
                                        ? 3.5
                                        : width < 991
                                        ? 6.5
                                        : 4.5
                                    }
                                    grabCursor={true}
                                  >
                                    {option.option_item.map((item, index) => {
                                      const isSelected =
                                        values[option.option_id]?.length > 0 ?  values[option.option_id].includes(item.option_item_id) : false;

                                      const isTakeAway =
                                        item.option_item_name === "Take Away" &&
                                        method === "take away";
                                      const isDelivery =
                                        item.option_item_name === "Delivery" &&
                                        method === "delivery";

                                      return (
                                        <SwiperSlide
                                          key={item.option_item_id}
                                          className="pointer"
                                          onClick={() => {
                                            setTotalPrice(null)

                                            if(max === 1) {
                                              if(!isTakeAway && !isDelivery) {
                                              setSelectedOptions({
                                                ...selectedOptions,
                                                [option.option_id]: isSelected
                                                  ? []
                                                  : item.option_item_id,
                                              });
                                              setFieldValue(
                                                option.option_id,
                                                isSelected
                                                  ? []
                                                  : [item.option_item_id]
                                              )}
                                            } else {
                                              const selectedOptionIds = values[option.option_id] || [];
                                              const index = selectedOptionIds.indexOf(item.option_item_id);
  
                                              if (index === -1) {
                                                if (selectedOptionIds.length < max) {
                                                  const updatedSelectedOptionIds = [...selectedOptionIds, item.option_item_id];
                                                  setFieldValue(option.option_id, updatedSelectedOptionIds);
                                                  setSelectedOptions((prevSelectedOptions) => ({
                                                    ...prevSelectedOptions,
                                                    [option.option_id]: updatedSelectedOptionIds,
                                                  }));
                                                } else {
                                                  toast.error(`Maximum limit (${max}) reached for ${option.option_name} option`);
                                                }
                                              } else {
                                                const updatedSelectedOptionIds = [...selectedOptionIds.slice(0, index), ...selectedOptionIds.slice(index + 1)];
                                                setFieldValue(option.option_id, updatedSelectedOptionIds);
                                                setSelectedOptions((prevSelectedOptions) => ({
                                                  ...prevSelectedOptions,
                                                  [option.option_id]: updatedSelectedOptionIds,
                                                }));
                                              }
                                            }
                                          }}
                                        >
                                          <article
                                            className={`food-option-frame ${
                                              isSelected ||
                                              isTakeAway ||
                                              isDelivery
                                                ? "--selected"
                                                : ""
                                            }`}
                                          >
                                            <LazyLoadImage
                                              src={
                                                item.media.length > 0
                                                  ? item.media[0].url
                                                  : item.option_item_name === "Take Away"
                                                  ? DefaultTakeAwayOption
                                                  : DefaultOption
                                              }
                                              alt=""
                                            />
                                          </article>
                                          <article className="food-option" data-tooltip-id={item.option_item_name}>
                                            <h4 
                                            style={{ display: 
                                              isSelected ||
                                              isTakeAway ||
                                              isDelivery ?
                                              'block' : '',
                                            }}
                                            >
                                              {item.option_item_name}
                                            </h4>
                                            <p>
                                              +{" "}
                                              {parseFloat(
                                                item.option_item_price
                                              ).toFixed(2)}
                                            </p>
                                            {/* {item.option_item_name.length > 15 && 
                                              <Tooltip
                                                // className="food-option-tooltip"
                                                id={item.option_item_name}
                                                place="top"
                                                // delayHide={10000000}
                                                content={item.option_item_name}
                                              />
                                            } */}
                                          </article>
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                </article>
                              );
                            })
                          }
                        />
                      ) : (
                        Array.from({ length: 2 }, (_, optionIndex) => (
                          <article className="food-option-info" key={optionIndex}>
                            <article className="flex gap-3 items-center">
                              <Skeleton
                                width="40%"
                                height="15px"
                                borderRadius="18px"
                              />
                              <Skeleton
                                width="20%"
                                height="10px"
                                borderRadius="18px"
                              />
                            </article>
                            <Swiper
                              slidesPerView={
                                width < 600 ? 3.5 : width < 991 ? 6.5 : 4.5
                              }
                              grabCursor={true}
                            >
                              {Array.from(
                                { length: 3 },
                                (_, optionItemIndex) => (
                                  <SwiperSlide key={optionItemIndex}>
                                    <article
                                      className="flex center"
                                      style={{ flexDirection: "column" }}
                                    >
                                      <Skeleton
                                        width="90px"
                                        height="60px"
                                        borderRadius="15px"
                                      />
                                      <Skeleton
                                        width="80px"
                                        height="11px"
                                        borderRadius="18px"
                                        className="mt-3"
                                      />
                                      <Skeleton
                                        width="50px"
                                        height="10px"
                                        borderRadius="18px"
                                        className="mt-2"
                                      />
                                    </article>
                                  </SwiperSlide>
                                )
                              )}
                            </Swiper>
                          </article>
                        ))
                      )}
                      <article className="note-info">
                        <h3>
                          Note to restaurant&nbsp;&nbsp;<span>Optional</span>{" "}
                        </h3>
                        <Input
                          name="remark"
                          placeholder="e.g no pickles"
                          as="line-field --black"
                        />
                      </article>
                    </section>
                    <div></div>
                    <section className="cart-summary">
                      <article className="cart-price">
                        <InputCount
                          type="place order"
                          initialPrice={initialPrice}
                          setTotalPrice={setTotalPrice}
                          setId={
                            editCartData.cart_item_id &&
                            editCartData.quantity === 1
                              ? setId
                              : undefined
                          }
                          className="cart-input-count"
                          setQuantity={setQuantity}
                          food_id={
                            editCartData.cart_item_id &&
                            editCartData.quantity === 1
                              ? editCartData.cart_item_id
                              : null
                          }
                          setQuantityChange={setQuantityChange}
                          quantity={
                            editCartData.quantity
                              ? editCartData.quantity
                              : quantity
                          }
                          max={
                            menuInfo.quantity_balance !== null
                              ? menuInfo.quantity_balance
                              : null
                          }
                        />
                        <article className="w-50 grid center">
                          <p>Total price</p>
                          {!menuInfoLoading ?
                            <h2>
                              RM{" "}
                              {totalPrice !== null ? parseFloat(totalPrice).toFixed(2) : (parseFloat(
                                  (menuInfo.promotion_price
                                    ? menuInfo.promotion_price
                                  : menuInfo.unit_price) *
                                    (quantityChange
                                      ? quantity
                                      : editCartData.quantity
                                      ? editCartData.quantity
                                      : quantity)
                                ) +
                                calculateOptionPrices(values, menuInfo) *
                                  (quantityChange
                                    ? quantity
                                    : editCartData.quantity
                                    ? editCartData.quantity
                                    : quantity)
                              ).toFixed(2)}
                            </h2>
                          :
                            <h2>RM 0.00</h2>
                          }
                        </article>
                      </article>
                      <Button
                        btnClassName="w-100"
                        type="submit"
                        disabled={!isValid || placingOrder || (menuInfo.quantity_balance !== null && quantity > menuInfo.quantity_balance) || menuInfoLoading}
                      >
                        {editCartData.cart_item_id ? "Update" : "Add to"} Cart
                      </Button>
                    </section>
                  </article>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </>
      }
    />
  );
}
