// 10
export function basicNumber(number) {
  const checkNumber = number ? number : 0;
  const newNumber = Number(checkNumber);

  const formattedNumber = parseFloat(newNumber).toFixed(0);

  return formattedNumber;
}

// 10.00
export function basicNumberTwo(number) {
  const checkNumber = number || 0;

  const numberWithoutCommas = checkNumber.toString().replace(/,/g, "");

  const formattedNumber = Number(numberWithoutCommas).toFixed(2);

  return formattedNumber;
}

// 1,000.00
export function formatNumber(number) {
  const checkNumber = number ? String(number) : "0";

  if (checkNumber.includes(",")) {
    let numberString = checkNumber.replace(/,/g, "");
    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  } else {
    let numberString = checkNumber.replace(/,/g, "");

    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  }
}

// Check if the number has a decimal part of zero
export function checkFormatNumber(number) {
  const checkNumber = Number(number);
  if (checkNumber % 1 === 0) {
    return checkNumber.toString();
  } else {
    return checkNumber.toFixed(2);
  }
}

// 0.23 => 1 , 0 => 0 , 0.9 => 1
export function customRound(number) {
  const checkNumber = Number(number);
  if (checkNumber % 1 !== 0) {
    return Math.ceil(checkNumber);
  } else {
    return checkNumber;
  }
}
