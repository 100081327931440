//lib
import Lottie from "lottie-react";
import { Payment } from "../../assets/lotties";

export default function FullLoading() {
  return (
    <article className="full-page-loading">
      <section className="lottie-frame d-grid justify-content-center">
        <Lottie animationData={Payment} />
      </section>
      <section className="loading-label text-center">
        <h4>We are currently processing your payment.</h4>
        <br />
        <p className="mt-3">
          Please <span>DO NOT</span> close this page or press refresh or back
          button.
        </p>
      </section>
    </article>
  );
}
