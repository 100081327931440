

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

export default function HomeHeader() {
  const navigate = useNavigate()
  const { merchantInfo } = useSelector((state) => state.merchant);

  // const handleBackMerchant = () => {
  //   navigate('/')
  // }

  return (
    <header> 
      {/* <article className="back-list pointer" onClick={() => handleBackMerchant()}>
        <Icon
          icon="ic:round-arrow-back"
          color=""
          className="loading-icon --smaller ms-3 mb-1"
        />
      </article> */}
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
            delay: 4500,
            disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {merchantInfo.banner?.length > 0 ? 
          merchantInfo.banner.map((banner, bannerIndex) => (
            <SwiperSlide className="home-header" key={bannerIndex}>
              <img src={banner.url} alt="" />
            </SwiperSlide>
          ))
        : 
          <SwiperSlide className="home-header">
          </SwiperSlide>
        }
      </Swiper>
    </header>
  );
}
