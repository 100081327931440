import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getQueue = createAsyncThunk('getQueue', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getQueue(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  queueInfo: []
};

const dataSlice = createSlice({
  name: "queue",
  initialState: initialData,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(getQueue.pending, (state) => {
      state.loading = true;
    })
    .addCase(getQueue.fulfilled, (state, action) => {
      state.loading = false;
      state.queueInfo = action.payload.data;
    })
    .addCase(getQueue.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { 
} = dataSlice.actions;

export default dataSlice.reducer;
