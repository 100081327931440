import { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Icon } from "@iconify/react";

import { useToast } from "../../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import { getQueue } from "../../../redux/reducer/queueReducer";
import { getQueueId } from "../../../redux/reducer/commonReducer";

import apiService from "../../../services/api.service";

import Input from "../../../components/element/input";
import Button from "../../../components/element/button";

export default function QueueBody({ method }) {
  const dispatch = useDispatch()
  const dropdownRef = useRef(null);
  const toast = useToast();

  const { queueInfo } = useSelector((state) => state.queue);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { merchantId, queueId, username } = useSelector((state) => state.common);

  const [ dropPax, setDropPax ] = useState(false);
  const [ partyOptions, setPartyOptions ] = useState([]);
  const [ hasSubmitted, setHasSubmitted ] = useState(false);

  const queueForm = {
    merchant_id: merchantId,
    name: queueInfo.name ?? '',
    code: '+60',
    phone_number: queueInfo.phone_number ? queueInfo.phone_number?.substring(3) : username && username !== "null" ? username?.substring(3) : '',
    no_of_pax: queueInfo.party_size ?? '',
  }

  const queueSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    no_of_pax: Yup.string().required("No of pax is required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^(1)[0-9]{8,9}$/,
      "Enter a valid international phone number (e.g., 123456789)"
    ),
  });

  const handleChoosePax = (value, { setFieldValue }) => {
    setFieldValue('no_of_pax', value)
    setDropPax(false)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropPax(false);
    }
  };

  const handleSubmit = async ({ values, errors, setFieldError }) => {
    try {
        const response = await apiService.createQueue({
            ...values,
            phone_number: `${values.code}${values.phone_number}`
        })
        if (response) {
            localStorage.setItem('queue_id', response.data.queue_entry_id)
            dispatch(getQueueId())
            toast.success('Queue up successful.')

            dispatch(getQueue({
                merchant_id: merchantId,
                queue_entry_id: response.data.queue_entry_id,
            }))
        }
    } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
            let errorMsg = [];
            if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        errorMsg = errors[item][0];
                    });
                    toast.error(errorMsg);
                }
            }
        }
    }
  }

  const handleRefreshQueue = () => {
    if(queueId) {
        dispatch(getQueue({
            merchant_id: merchantId,
            queue_entry_id: queueId,
        }))
    } else {
        toast.error(`You're no longer in the queue.`);
    }
  }

  const handleLeaveQueue = async () => {
    try {
        const response = await apiService.leaveQueue({
            queue_entry_id: queueId
        })
        if (response) {
            localStorage.removeItem('queue_id')
            dispatch(getQueueId())
            toast.success('Leave queue successful.')

            dispatch(getQueue({
                merchant_id: merchantId,
                queue_entry_id: '',
            }))
        }
    } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
            let errorMsg = [];
            if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        errorMsg = errors[item][0];
                    });
                    toast.error(errorMsg);
                }
            }
        }
    }
  }

  useEffect(() => {
    if(queueInfo.position && queueId) {
        setHasSubmitted(true)
    } else {
        setHasSubmitted(false)
    }
  }, [queueInfo])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropPax]);

  useEffect(() => {
    if(queueInfo.queue_group?.length > 0) {
        const minPax = Math.min(...queueInfo.queue_group.map((queue) => queue.from_pax));
        const maxPax = Math.max(...queueInfo.queue_group.map((queue) => queue.to_pax));
        
        const options = [];
        for (let i = minPax; i <= maxPax; i++) {
        options.push({ label: `Party of ${i}`, value: String(i) });
        }
        setPartyOptions(options);
    }
  }, [queueInfo]);

  return (
    <section className="queue-body">
        {merchantInfo.store_status === 'unavailable' && <article className="notification">
            <Icon icon="carbon:error" />
            <p>This store is <span>temporarily closed</span>, please try again later.</p>
        </article>}
        <article className='current-line-section'>
            <article className="current-line-text">
                <p>Current In Line</p>
            </article>
            <article className="current-line-info">
                {queueInfo.queue_group?.length > 0 && queueInfo.queue_group.map((queue, queueIndex) => (
                    <article key={queueIndex}>
                        <p>{queue.queue_name} ({queue.from_pax} ~ {queue.to_pax})</p>
                        <h3>{queue.no_of_queue}</h3>
                    </article>
                ))}
            </article>
        </article>
        <Formik
            initialValues={queueForm}
            validationSchema={queueSchema}
            enableReinitialize={true}
            onSubmit={(values, { errors, setFieldError }) => {
                handleSubmit({ values, errors, setFieldError });
            }}
        >
            {({
                errors,
                setFieldValue
            }) => (
            <Form>
                {!hasSubmitted ?
                    <article className="input-section">
                    <Input
                        name="name"
                        placeholder="Name"
                        isRequired={true}
                        disabled={merchantInfo.store_status === 'unavailable'}
                    />          
                    <section className={`input-flex ${errors && errors['phone_number'] ? '--error' : '--no-error'}`}>   
                        <Input
                            readOnly
                            name="code"
                            placeholder="Code"
                        />
                        <Input
                            type="code"
                            isRequired={true}
                            name="phone_number"
                            placeholder="Mobile Number"
                            disabled={merchantInfo.store_status === 'unavailable'}
                        />
                        <></>
                    </section>    
                    {errors && errors['phone_number'] && 
                        <div className='element _errors text-right no-padding'>
                            {errors['phone_number']}
                        </div>
                    }
                    <section className="relative">
                        <Input
                            name="no_of_pax"
                            placeholder="Party Size"
                            isRequired={true}
                            onClick={() => setDropPax(!dropPax)}
                            readOnly
                            disabled={merchantInfo.store_status === 'unavailable'}
                        />
                        <article 
                            ref={dropdownRef}
                            className={`dropdown-container ${!dropPax ? 'hide' : ''}`}
                        >
                            {partyOptions?.map((option, optionIndex) => (
                                <section 
                                    className="row pointer"
                                    onClick={() => handleChoosePax(option.value, {setFieldValue})}
                                    key={optionIndex}
                                >
                                    <label className="pointer">{option.label}</label>
                                </section>
                            ))}
                        </article>
                    </section>
                    <section> 
                        <p className="note-text ms-2 mt-4">Note: Please enter valid mobile number to ensure you receive line up message.</p>
                        <Button type={!hasSubmitted ? "submit" : "button"} className="w-100 pt-2" btnClassName="w-100 text-uppercase">Join The Line Now</Button>
                    </section>
                    </article>
                :
                    <article className="your-line-section">
                        <article className="title">
                            <Icon icon="mdi:tick-circle" />
                            <h1>Line Up Successful</h1>
                        </article>
                        <hr />
                        <section className="your-line-info">
                            <article className="line-col">
                                <p>Time</p>
                                <h6>{queueInfo.time}</h6>
                            </article>
                            <article className="line-col">
                                <p>Party Size</p>
                                <h6>Party of {queueInfo.party_size}</h6>
                            </article>
                            <article className="line-col">
                                <p>Position</p>
                                <h6>{queueInfo.position} <span>(position of the Q)</span></h6>
                            </article>
                            <article className="line-col">
                                <p>Code</p>
                                <article className="flex gap-4">
                                    <h6>{queueInfo.queue_number}</h6>
                                    <h6>{queueInfo.group_name}</h6>
                                </article>
                            </article>
                            <Button variant="outline" className="grid center" btnClassName="refresh-btn" onClick={() => handleRefreshQueue()}>Refresh</Button>      
                        </section>
                        <hr />
                        <Button className="ps-5 pe-5 pt-3" btnClassName="text-uppercase w-100" type={hasSubmitted ? "submit" : "button"}>Join The Line Again</Button>
                        <h2 className="pointer" onClick={() => handleLeaveQueue()}>Leave The Line Now</h2>
                    </article>
                }
            </Form>
            )}
        </Formik>
    </section>
  );
}
