import { useNavigate, useParams } from "react-router-dom";

import { AfterStamp, BeforeStamp, Claimed, DefaultPromo } from "../../../assets/images";

import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { setUseNowItem } from "../../../redux/reducer/commonReducer";
import { openCouponPopUp, openLoginModal, openPrizeModal } from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";
import PageHeader from "../../../components/header/page.header";

import apiService from "../../../services/api.service";
import { useEffect, useState } from "react";
import { getCheckInList } from "../../../redux/reducer/checkInReducer";

export default function CheckInStamp() {
  const toast = useToast();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { store } = useParams()
  const { width } = useWindowDimensions()

  const { login, merchantId } = useSelector((state) => state.common);
  const { afterStamp, beforeStamp, themeColor } = useSelector((state) => state.merchant);
  const { checkInPeriod, checkInList, checkInReward, checkInProgress, checkInLoading, checkInPrizeList } = useSelector((state) => state.check_in);

  const [deviceType, setDeviceType] = useState('');

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
  };

  const applyColorToImage = (color) => {
    const rgbColor = hexToRgb(color ?? '#6C5FE6');

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
        <filter id="colorize">
          <feColorMatrix type="matrix" values={`0 0 0 0 ${rgbColor.r / 255}
                                                0 0 0 0 ${rgbColor.g / 255}
                                                0 0 0 0 ${rgbColor.b / 255}
                                                0 0 0 1 0`} 
          />
        </filter>
        <image xlinkHref={Claimed} width="40" height="40" filter="url(#colorize)" />
      </svg>
    );
  }

  const handleBackToHome = () => {
    navigate(`/${store}`)
  }

  const handleOpenPrize = () => {
    if(checkInPrizeList.length > 0) {
        dispatch(openPrizeModal())
    } else {
        toast.error(`Merchant didn't setup any check-in prizes`)
    }
  }

  const handleClaimReward = async (checkIn) => {
    if(login === 'full') {
        try {
            const response = await apiService.claimCheckInReward({
                user_check_in_id: checkIn.user_check_in_id,
            })

            if(response) {
                toast.success('Claim reward successful!')
                if(checkInList[checkIn.item_index - 1].promotion_type === 'free menu') {
                    dispatch(setUseNowItem({
                        type: 'just claimed',
                        item_type: 'check in',
                        promotion_id: response.data.user_promotion_bag_id,
                        media: response.data.image,
                        display_name: response.data.name,
                        expiry_date: response.data.expiry_day_count
                    }))
                    dispatch(openCouponPopUp())
                }
                dispatch(getCheckInList({
                    merchant_id: merchantId
                }))
            }
        } catch (ex) {
            if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        toast(errors[item][0], 'error');
                    });
                }
            }
        }
    } else {
        dispatch(openLoginModal())
    }
  }

  const rewardIndex = checkInProgress?.next_reward - 1;
  //   const claimIndex =  checkInList.findIndex(checkIn => checkIn.user_check_in_id !== null && checkIn.has_reward === 1 && checkIn.has_claim === 0);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    }
  }, []);

  return (
    <>
        <header className='check-in-header'> 
            <PageHeader 
                title='Check In'
                endsIn={checkInPeriod.end_at ? checkInPeriod.end_at.split(' ')[0] : null}
                backIcon="fluent:person-arrow-back-48-regular" 
                backTo={handleBackToHome} 
                navTo={undefined} 
                className="--blue pb-4" 
            />
        </header>
        <section className={`check-in ${!checkInLoading && checkInPrizeList.length === 0 ? 'pt-0' : ''}`}>
            <article className="w-100 flex justify-end">
                {!checkInLoading && checkInPrizeList.length > 0 ? 
                    <Button variant="outline" btnClassName="prize-detail" onClick={() => handleOpenPrize()}>
                        <Icon icon="emojione:wrapped-gift" />
                        Prize Details
                    </Button>
                : 
                    checkInLoading ?
                        <Skeleton
                            width="128px"
                            height="36px"
                            borderRadius="8px"
                        />
                    : 
                        ''
                }
            </article>
            {/* {checkInPeriod.end_at && <article className="stamp-period">
                <p>Event ends in {checkInPeriod.end_at.split(' ')[0]}</p>
            </article>} */}
            {!checkInLoading && checkInList.length > 0 ? 
                <article className="stamp-list">
                    {checkInList.map((checkIn, checkInIndex) => (
                        <section 
                            className={`stamp-frame ${checkIn.available_status === 'available to claim' ? 'pointer' : ''}`} 
                            onClick={() => checkIn.available_status === 'available to claim' ? handleClaimReward(checkIn) : undefined}
                            key={checkInIndex}
                        >
                            {checkIn.has_claim === 1 && themeColor?.main_color && 
                                <article className="stamp-claimed">{applyColorToImage(themeColor.btn_color)}</article>
                            }
                            <LazyLoadImage src={checkIn.has_reward === 1 ? checkIn.promotion_pic?.length > 0 ? checkIn.promotion_pic[0].url : DefaultPromo : checkIn.has_check_in === 1 ? afterStamp || AfterStamp : beforeStamp || BeforeStamp} />
                            {(checkIn.available_status === 'available to claim' || checkInIndex === rewardIndex) && 
                            <p className={`badge ${deviceType === 'iOS' ? '--ios' : ''} ${checkIn.available_status === 'available to claim' ? '' : '--next' }`}>{checkIn.available_status === 'available to claim' ? 'Claim' : 'Next Reward'}</p>}
                        </section>
                    ))}
                </article>
            : 
                checkInLoading ?
                    <Skeleton
                        width={width > 650 ? "600px" : "100%"}
                        height="70px"
                        borderRadius="8px"
                    />   
                :        
                    <article className={`stamp-list ${width < 650 ? 'w-100' : ''}`}>
                        <article className="empty-container --stamp">
                            <p>Merchant didn't setup any check in stamp</p>
                        </article>
                    </article>    
            }
            {checkInProgress?.next_reward !== null && !checkInLoading && (checkInProgress?.next_reward - checkInProgress?.checked_in > 0) && <article className="stamp">
                <Icon icon="ph:stamp-bold" />
                <h5>More {checkInProgress?.next_reward - checkInProgress?.checked_in} Stamps to Get the Reward</h5>
            </article>}
        </section>
    </>
  );
}
