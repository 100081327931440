import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Success } from "../../../assets/lotties";

import * as Yup from "yup";
import { Steps } from "antd";
import Lottie from "lottie-react";
import { Form, Formik } from "formik";
import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closeTopUpModal,
  openHistoryModal,
  openLoginModal,
  openPromotionDetailsModal,
  openTopUpModal,
  setAnimationModal,
} from "../../../redux/reducer/modalReducer";
import {
  setFullLoading,
  getPromotionInfo,
  getTopUpPaymentList,
} from "../../../redux/reducer/commonReducer";
import { setIsViewingPromo } from "../../../redux/reducer/homeReducer";

import Button from "../../../components/element/button";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";

export default function TopUp({ setLoginType }) {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const { walletBalance } = useSelector((state) => state.home);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const {
    merchantId,
    topUpPaymentList,
    topUpPackageList,
    topUpPackageLoading,
    topUpPaymentLoading,
    fullLoading,
    login,
  } = useSelector((state) => state.common);
  const { isOpenTopUpModal } = useSelector((state) => state.modal);

  const [currentStatus, setCurrentStatus] = useState(1);
  const [openPaymentItem, setOpenPaymentItem] = useState([]);

  const [walletInteger, walletDecimal] = String(walletBalance).split(".");

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");

  const topUpForm = {
    amount: 0,
    free_amount: 0,
    tenant_channel_id: "",
    provider_channel_id: "",
    topup_package_id: "",
  };

  const topUpSchema = Yup.object({
    amount: Yup.number().min(1.0).required("Amount is required"),
  });

  const handleSubmit = async ({ values, setFieldValue }) => {
    if (currentStatus === 2) {
      dispatch(closeTopUpModal());
      dispatch(setFullLoading(true));

      try {
        const response = await apiService.topUpWallet({
          amount: values.amount,
          merchant_id: merchantId,
          tenant_channel_id: values.tenant_channel_id,
          provider_channel_id: values.provider_channel_id,
          topup_package_id: values.topup_package_id,
        });

        if (response) {
          if (response.data.url) {
            handleCloseTopUpModal();
            window.parent.location.replace(response.data.url);
          } else {
            toast.error(
              "Oops! Something went wrong unexpectedly. Please try other payment methods."
            );
          }
        }
      } catch (ex) {
        dispatch(setFullLoading(false));
        dispatch(openTopUpModal());
        setCurrentStatus(2);

        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = [];
          if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                errorMsg = errors[item][0];
                toast.error(errorMsg);
              });
            }
          }
        }
      }
    } else {
      dispatch(
        getTopUpPaymentList({
          merchant_id: merchantId,
          amount: values.amount,
          allow_online_payment: merchantInfo.allow_online_payment,
        })
      )
        .unwrap()
        .then((res) => {
          setCurrentStatus(currentStatus + 1);
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        });
    }
  };

  const handleShowPaymentItem = (type) => {
    if (openPaymentItem.includes(type)) {
      setOpenPaymentItem(openPaymentItem.filter((id) => id !== type));
    } else {
      setOpenPaymentItem([...openPaymentItem, type]);
    }
  };

  const handleCloseTopUpModal = () => {
    setCurrentStatus(1);
    dispatch(closeTopUpModal());
  };

  const handleOpenHistory = () => {
    setCurrentStatus(1);
    dispatch(closeTopUpModal());
    dispatch(openHistoryModal());
  };

  const handleViewReward = (e, id) => {
    e.stopPropagation();

    dispatch(openPromotionDetailsModal());
    dispatch(setAnimationModal(false));
    dispatch(setIsViewingPromo(true));
    dispatch(closeTopUpModal());
    dispatch(
      getPromotionInfo({
        id: id,
      })
    );
  };

  const handleLogin = () => {
    handleCloseTopUpModal();
    setLoginType("full-topup");
    dispatch(openLoginModal());
  };

  useEffect(() => {
    if (status === "success") {
      setCurrentStatus(3);
      setSearchParams("");
    }
  }, [status]);

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenTopUpModal]);

  return (
    <FullModal
      show={isOpenTopUpModal}
      backButton={handleCloseTopUpModal}
      screenSize="fullscreen-xl-down"
      className={currentStatus === 2 ? "" : ""}
      contentClassName="--white"
      content={
        <>
          <ModalHeader
            title="Topup Wallet"
            backTo={handleCloseTopUpModal}
            backToNoAnimation={handleCloseTopUpModal}
            type="model2"
          />
          <Formik
            initialValues={topUpForm}
            validationSchema={topUpSchema}
            onSubmit={(values, { setFieldError, setFieldValue }) => {
              handleSubmit({ values, setFieldError, setFieldValue });
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <MDBModalBody ref={modalRef} className="fixed-body">
                  <article className="topup-modal">
                    <section className="curve-bg"></section>
                    <section className="topup-section">
                      <article className="topup-steps">
                        <Steps
                          responsive={false}
                          items={[
                            {
                              title: "Amount",
                              status:
                                currentStatus === 1 ? "progress" : "finish",
                              icon:
                                currentStatus === 1 ? (
                                  <Icon icon="carbon:circle-filled" />
                                ) : (
                                  <Icon icon="mdi:tick-circle" />
                                ),
                            },
                            {
                              title: "Payment",
                              status:
                                currentStatus === 2
                                  ? "progress"
                                  : currentStatus === 3
                                  ? "finish"
                                  : "wait",
                              icon:
                                currentStatus === 2 ? (
                                  <Icon icon="carbon:circle-filled" />
                                ) : currentStatus === 3 ? (
                                  <Icon icon="mdi:tick-circle" />
                                ) : (
                                  <Icon icon="mynaui:circle" />
                                ),
                            },
                            {
                              title: "Topup",
                              status: currentStatus === 3 ? "finish" : "wait",
                              icon:
                                currentStatus === 3 ? (
                                  <Icon icon="ant-design:smile-outlined" />
                                ) : (
                                  <Icon icon="mynaui:circle" />
                                ),
                            },
                          ]}
                        />
                      </article>
                      <article className="topup-details">
                        <p>Top Up to Merchant</p>
                        <h3>{merchantInfo.store_name}</h3>
                      </article>
                      {currentStatus === 1 || currentStatus === 3 ? (
                        <article
                          className={`topup-details --full flex justify-between items-end mt-3 ${
                            login !== "full" ? "pointer" : ""
                          }`}
                          onClick={() =>
                            login !== "full" ? handleLogin() : undefined
                          }
                        >
                          <article>
                            <p>
                              {login === "full"
                                ? "Balance"
                                : "Login or register to check your balance"}
                            </p>
                            <h3>
                              <span>RM</span>{" "}
                              {login === "full" && walletInteger > 0
                                ? walletInteger
                                : "0"}
                              <span>
                                .
                                {login === "full" && walletDecimal > 0
                                  ? `${walletDecimal.slice(0, 2)}`
                                  : "00"}
                              </span>
                            </h3>
                          </article>
                          {login === "full" && (
                            <article
                              className="icon-container --top-up"
                              onClick={() => handleOpenHistory()}
                            >
                              <Icon
                                icon="ion:wallet-outline"
                                className="default-icon"
                              />
                              <Icon icon="ion:wallet" className="hover-icon" />
                            </article>
                          )}
                        </article>
                      ) : (
                        <article className="topup-details --full mt-3">
                          <p>Top Up Package</p>
                          <article className="flex justify-between items-baseline">
                            <h3>
                              <span>RM</span>{" "}
                              {parseFloat(values.amount).toFixed(2)}
                            </h3>
                            <p>
                              / Free {`${values.free_amount > 0 ? 'RM' : ''}${" "}
                              ${values.free_amount === 'voucher' ? 'Voucher' : parseFloat(values.free_amount).toFixed(2)}`}
                            </p>
                          </article>
                        </article>
                      )}
                      {currentStatus === 1 ? (
                        <article className="body-section">
                          {!topUpPackageLoading &&
                          topUpPackageList.length > 0 ? (
                            <>
                              <h5>PACKAGE</h5>
                              <article className="amount-grid">
                                {topUpPackageList.map(
                                  (topUpPackage, topUpPackageIndex) => (
                                    <article
                                      className={`package ${
                                        values.topup_package_id ===
                                        topUpPackage.id
                                          ? "--selected"
                                          : ""
                                      } pointer`}
                                      onClick={() => {
                                        if (!topUpPaymentLoading) {
                                          setFieldValue(
                                            "amount",
                                            topUpPackage.amount
                                          );
                                          setFieldValue(
                                            "free_amount",
                                            topUpPackage.promotion_id ? 'voucher' : topUpPackage.free_amount
                                          );
                                          setFieldValue(
                                            "topup_package_id",
                                            topUpPackage.id
                                          );
                                        }
                                      }}
                                      key={topUpPackageIndex}
                                    >
                                      <h4>
                                        <span>RM</span>{" "}
                                        {parseFloat(
                                          topUpPackage.amount
                                        ).toFixed(2)}
                                      </h4>
                                      <p
                                        className={
                                          topUpPackage.promotion_title !== null
                                            ? "pointer"
                                            : "default"
                                        }
                                        onClick={(e) =>
                                          topUpPackage.promotion_title !== null
                                            ? handleViewReward(
                                                e,
                                                topUpPackage.promotion_id
                                              )
                                            : undefined
                                        }
                                      >
                                        Free{" "}
                                        {topUpPackage.promotion_title
                                          ? "Voucher"
                                          : `RM${" "}
                                        ${parseFloat(
                                          topUpPackage.free_amount
                                        ).toFixed(2)}`}
                                      </p>
                                    </article>
                                  )
                                )}
                              </article>
                            </>
                          ) : topUpPackageLoading ? (
                            <article className="general-loading">
                              <Icon icon="svg-spinners:12-dots-scale-rotate" />
                            </article>
                          ) : (
                            <article className="empty-container">
                              <p>Merchant didn't setup any top up packages</p>
                            </article>
                          )}
                        </article>
                      ) : currentStatus === 2 ? (
                        <article className="body-section">
                          <h5>PAYMENT METHODS</h5>
                          <article className="payment-method-list">
                            {Object.entries(topUpPaymentList).map(
                              ([paymentType, paymentMethods]) => (
                                <section key={paymentType}>
                                  <article
                                    className={`payment-method ${
                                      openPaymentItem.includes(paymentType)
                                        ? "--withoutBorder"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleShowPaymentItem(paymentType)
                                    }
                                  >
                                    <article className="flex items-center gap-4">
                                      <Icon
                                        icon="bi:credit-card-2-back"
                                        className="icon --primary smaller"
                                      />
                                      <h4>{paymentType}</h4>
                                    </article>
                                    <article>
                                      <Icon
                                        icon={
                                          openPaymentItem.includes(paymentType)
                                            ? "ion:chevron-down"
                                            : "ion:chevron-right"
                                        }
                                        className={`icon ${
                                          openPaymentItem.includes(paymentType)
                                            ? ""
                                            : "smaller"
                                        }`}
                                      />
                                    </article>
                                  </article>
                                  {openPaymentItem.includes(paymentType) && (
                                    <article className="p-4 pt-0 pb-0">
                                      {paymentMethods.map((method) => (
                                        <section
                                          key={method.image_url}
                                          className="payment-method --withoutBorder"
                                          onClick={() => {
                                            setFieldValue(
                                              "tenant_channel_id",
                                              method.tenant_channel_id
                                            );
                                            setFieldValue(
                                              "provider_channel_id",
                                              method.provider_channel_id
                                            );
                                          }}
                                        >
                                          <article className="flex items-center gap-4">
                                            <article className="bank-frame">
                                              <LazyLoadImage
                                                src={method.image_url}
                                                alt={method.name}
                                              />
                                            </article>
                                            <h4>{method.display_name}</h4>
                                          </article>
                                          <article>
                                            <Icon
                                              icon={
                                                values.tenant_channel_id ===
                                                  method.tenant_channel_id &&
                                                values.provider_channel_id ===
                                                  method.provider_channel_id
                                                  ? "pepicons-pop:bank-circle"
                                                  : ""
                                              }
                                              className={
                                                values.tenant_channel_id ===
                                                  method.tenant_channel_id &&
                                                values.provider_channel_id ===
                                                  method.provider_channel_id
                                                  ? "icon --primary"
                                                  : ""
                                              }
                                            />
                                          </article>
                                        </section>
                                      ))}
                                    </article>
                                  )}
                                </section>
                              )
                            )}
                          </article>
                        </article>
                      ) : (
                        <article className="body-section --lottie">
                          <article className="lottie-frame">
                            <Lottie animationData={Success} />
                          </article>
                          <h3>Top Up Successful</h3>
                          <p>
                            Your wallet have been topped up successfully and are
                            ready to use!
                          </p>
                          {/* <p className="amount-paid">Top Up Amount Paid</p>
                              <h4><span>RM</span> 120</h4> */}
                        </article>
                      )}
                    </section>
                  </article>
                </MDBModalBody>
                <section className="model2-summary --fixed">
                  {currentStatus === 2 && (
                    <Button
                      type="button"
                      btnClassName="previous-btn"
                      onClick={() => {
                        setCurrentStatus(1);
                      }}
                    >
                      <Icon icon="ion:arrow-back" />
                    </Button>
                  )}
                  <Button
                    disabled={topUpPaymentLoading || fullLoading}
                    type="submit"
                    className="w-100"
                    onClick={() => {
                      if (login !== "full") {
                        setLoginType("full-topup-proceed");
                        dispatch(openLoginModal());
                        dispatch(closeTopUpModal());

                        return;
                      }

                      if (
                        currentStatus === 1 &&
                        values.amount === 0 &&
                        login === "full"
                      ) {
                        toast.error(
                          "Please select a top up package to proceed the payment"
                        );
                        return;
                      }

                      if (
                        currentStatus === 2 &&
                        values.tenant_channel_id === ""
                      ) {
                        toast.error(
                          "Please select a payment method to proceed the payment"
                        );
                        return;
                      }

                      if (currentStatus === 3) {
                        handleCloseTopUpModal();
                      }
                    }}
                  >
                    {login !== "full" && currentStatus === 1
                      ? "Login To Proceed"
                      : currentStatus === 1
                      ? "Next"
                      : currentStatus === 2
                      ? "Confirm Top Up"
                      : "Great, Thanks!"}
                    {topUpPaymentLoading && (
                      <Icon
                        icon="line-md:loading-twotone-loop"
                        color=""
                        className="loading-icon ms-3 mb-1"
                      />
                    )}
                  </Button>
                </section>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
}
