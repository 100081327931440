import { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Icon } from "@iconify/react";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  setShowModal,
  openTopUpModal,
  closeLoginModal,
  openMarketModal,
  openPaymentModal,
  openProfileModal,
  openPromotionModal,
  openPromotionDetailsModal,
} from "../../../redux/reducer/modalReducer";
import {
  getLogin,
  getToken,
  getCartId,
  getTableId,
  getUsername,
  getMerchantId,
  getTopUpPackageList,
  getCartOwner,
} from "../../../redux/reducer/commonReducer";
import {
  getAllPayments,
  getCartInfo,
  setPaymentStatus,
  setUpdateCartId,
} from "../../../redux/reducer/cartReducer";
import {
  getPointBalance,
  getProfile,
  getPromotionList,
  getUserRank,
  getWalletBalance,
} from "../../../redux/reducer/homeReducer";

import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import CodeInput from "../../../components/element/code.input";

import authService from "../../../services/auth.service";
import { getCheckInList } from "../../../redux/reducer/checkInReducer";

export default function FullLogin({ type }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { merchantId, tableId, method, userId } = useSelector((state) => state.common);

  const [isLogging, setIsLogging] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [otpTimerId, setOtpTimerId] = useState(null);
  const [errorStatus, setErrorStatus] = useState("");
  const [otpCountDown, setOtpCountDown] = useState(null);
  const [firstTimeRequest, setFirstTimeRequest] = useState(true);

  const loginForm = {
    username: "",
    verification_code: "",
  };

  const loginSchema = Yup.object({
    username: Yup.string()
      .required("Phone number is required")
      .matches(
        /^(1)[0-9]{8,9}$/,
        "Enter a valid international phone number (e.g., 123456789)"
      ),
    verification_code: Yup.string()
      .min(6, "OTP must be at least 6 characters")
      .required("OTP is required"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    setIsLogging(true);

    try {
      const response = await authService.login({
        username: `60${values.username}`,
        verification_code: values.verification_code,
        type: "phone verification",
        table_id: tableId ? tableId === 'null' ? null : tableId : null,
        merchant_id: merchantId ?? null,
      });

      if (response) {
        setTimeout(() => {
          localStorage.setItem("username", `+60${values.username}`);
          localStorage.setItem("user_id", response.data.id);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("login", "full");
          dispatch(getToken());
          dispatch(getLogin());
          dispatch(getUsername());

          if (type === "full-cart" || type === "full-credit") {
            dispatch(setUpdateCartId("login-loading"));
            dispatch(
              getCartInfo({
                merchant_id: merchantId,
                order_method: method,
              })
            )
              .unwrap()
              .then((res) => {
                if (res.data.length > 0) {
                  localStorage.setItem("cart_id", res.data[0].cart_id);
                  if(res.data[0].user_id === parseInt(userId)) {
                    localStorage.setItem("cart_owner", true);
                  }
                  dispatch(getCartId());
                  dispatch(getCartOwner());
                }

                if (type === "full-credit" || type === "full-credit-pay") {
                  dispatch(
                    getAllPayments({
                      cart_id: res.data[0].cart_id,
                      amount: res.data[0].total_price,
                      type: "cart",
                      merchant_id: merchantId,
                      allow_online_payment: merchantInfo.allow_online_payment,
                    })
                  )
                    .unwrap()
                    .then((res) => {
                      dispatch(openPaymentModal());
                      dispatch(
                        getWalletBalance({
                          merchant_id: merchantId,
                        })
                      );
                      setIsLogging(false);
                      dispatch(closeLoginModal());
                      dispatch(setShowModal(false));
                      dispatch(setUpdateCartId(null));

                      if(type === "full-credit-pay") {
                        setPaymentStatus("direct-pay")
                      }

                      toast.success(
                        `Login successful. Welcome +60${values.username}.`
                      );
                    })
                    .catch((ex) => {
                      setIsLogging(false);
                      dispatch(closeLoginModal());
                      dispatch(setShowModal(false));
                      dispatch(setUpdateCartId(null));

                      if (ex.response?.status === 422) {
                        const errors = ex.response.data.errors;
                        if (errors && Object.keys(errors).length > 0) {
                          Object.keys(errors).map((item, i) => {
                            if (item === "cart_id") {
                              toast.error('Your cart has been outdated. Please refresh.');

                              setTimeout(() => {
                                window.location.reload()
                              }, 1000)
                            } else {
                              toast.error(errors[item][0])
                            }
                          });
                        }
                      }
                    });

                  return;
                }

                setIsLogging(false);
                dispatch(closeLoginModal());
                dispatch(setShowModal(false));
                dispatch(setUpdateCartId(null));
                toast.success(
                  `Login successful. Welcome +60${values.username}.`
                );
              })
              .catch((ex) => {})

            return;
          }

          if (type === "full-marketplace" || type === "full-point" || type === "full-promo") {
            dispatch(
              getPointBalance({
                merchant_id: merchantId,
              })
            );
            dispatch(
              getUserRank({
                merchant_id: merchantId,
              })
            )
              .unwrap()
              .then((res) => {
                if (type === "full-promo") {
                  dispatch(openPromotionDetailsModal());
                } else {
                  dispatch(openMarketModal(type === "full-point" ? 'point' : ''));
                }
                setIsLogging(false);
                dispatch(setShowModal(false));
                dispatch(closeLoginModal());
                toast.success(
                  `Login successful. Welcome +60${values.username}.`
                );
              })
              .catch((ex) => {})

            return;
          }

          if (type === "full-profile") {
            dispatch(getProfile());
            dispatch(openProfileModal());
          }

          if (type === "full-promo-detail") {
            dispatch(openPromotionDetailsModal('claim-btn'))
          }

          if (type === "full-voucher") {
            dispatch(openPromotionModal());
          }

          if (type === "full-topup" || type === "full-topup-proceed") {
            dispatch(openTopUpModal());
            dispatch(
              getWalletBalance({
                merchant_id: merchantId,
              })
            );
          }

          setIsLogging(false);
          dispatch(setShowModal(false));
          dispatch(closeLoginModal());
          toast.success(`Login successful. Welcome +60${values.username}.`);
        }, 500);
      }
    } catch (ex) {
      setIsLogging(false);
      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) =>
            setFieldError(item, errors[item])
          );
        }
      }
    }
  };

  const handleSendOtp = async ({ values, setFieldError, setFieldValue }) => {
    setIsRequesting(true);

    if (firstTimeRequest) {
      setFirstTimeRequest(false);
    }

    try {
      const response = await authService.sendVerificationCode({
        username: `60${values.username}`,
        type: "phone verification",
      });

      if (response) {
        setIsRequesting(false);
        setFieldValue(values, values);

        if (otpCountDown === null) {
          setOtpCountDown(60);
          const timer = setInterval(() => {
            setOtpCountDown((prevCountdown) => prevCountdown - 1);
          }, 1000);
          setOtpTimerId(timer);
        }
      }
    } catch (ex) {
      setIsRequesting(false);
      setErrorStatus("false");

      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            const errorMesage = errors[item];
            const regex = /\d+/;
            const matches = errorMesage[0].match(regex);
            if (matches) {
              const number = parseInt(matches[0], 10);
              if (otpCountDown === null) {
                setOtpCountDown(number);
                const timer = setInterval(() => {
                  setOtpCountDown((prevCountdown) => prevCountdown - 1);
                }, 1000);
                setOtpTimerId(timer);
                setFieldError(item, errors[item]);
              }
            } else {
            }
          });
        }
      }
    }
  };

  const formatTimer = (time) => {
    if (time === 60) {
      return `( ${time}s )`;
    } else {
      return `( ${time}s )`;
    }
  };

  useEffect(() => {
    dispatch(getTableId());
    dispatch(getMerchantId());
  }, []);

  useEffect(() => {
    if (otpCountDown === 0) {
      clearInterval(otpTimerId);
      setOtpCountDown(null);
    }
  }, [otpCountDown, otpTimerId]);

  useEffect(() => {
    if (otpCountDown > 0 && errorStatus === "false") {
    } else if (otpCountDown === 0) {
      setErrorStatus("");
    }
  }, [otpCountDown]);

  return (
    <Formik
      initialValues={loginForm}
      validationSchema={loginSchema}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={(values, { errors, setFieldError }) => {
        handleSubmit({ values, errors, setFieldError });
      }}
    >
      {({ errors, setFieldValue, isValid, values, setFieldError }) => (
        <Form>
          <section className="input-section">
            <Input
              name="username"
              label={<>Phone Number <span className="message">(All rewards will bind with this number)</span></>}
              placeholder="123456789"
              as="line-field"
              inputClassName="phone_no_otp"
              type="number"
              autoFocus={false}
              handleSendOtp={handleSendOtp}
              firstTimeRequest={firstTimeRequest}
              isRequesting={isRequesting}
              otpCountDown={otpCountDown}
              errorStatus={errorStatus}
              formatTime={formatTimer(otpCountDown)}
            />
            <CodeInput
              name="verification_code"
              label="Enter OTP"
              disabled={false}
            />
            {/* <article
                    className="login-tnc"
                    onClick={() => {
                        setFieldValue(
                            "terms_condition",
                            !values.terms_condition
                        );
                    }}
                >
                    <article>
                        <input
                            type="checkbox"
                            name="terms_condition"
                            className="pointer"
                            checked={values.terms_condition}
                            onChange={(e) =>
                            e.target.checked
                                ? setFieldValue("terms_condition", true)
                                : setFieldValue("terms_condition", false)
                            }
                        />
                    </article>
                    <article>
                        <p>
                            I have read and agree to FoodLink{" "}
                            <span className="pointer">
                            Terms and Conditions
                            </span>{" "}
                            and <span className="pointer">Privacy Policy</span>
                        </p>
                    </article>
                </article> */}
          </section>
          <section>
            <Button
              type="submit"
              className={width > 550 ? "ps-5 pe-5" : ""}
              disabled={
                isLogging ||
                (isValid && values.verification_code?.length === 6
                  ? false
                  : true)
              }
              btnClassName="login-btn w-100"
            >
              LOG IN / SIGN UP
              {isLogging && (
                <Icon
                  icon="line-md:loading-twotone-loop"
                  color="#ffffff"
                  className="login-loading ms-2 mb-1"
                />
              )}
            </Button>
          </section>
        </Form>
      )}
    </Formik>
  );
}
