import { useEffect, useState } from "react";

import { Skeleton } from "primereact/skeleton";
import { MDBModalBody } from "mdb-react-ui-kit";

import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { closePromotionModal, openCouponPopUp, openPromotionDetailsModal } from "../../../redux/reducer/modalReducer";

import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import { getMyPromotion } from "../../../redux/reducer/cartReducer";
import { getPromotionInfo, setUseNowItem } from "../../../redux/reducer/commonReducer";

import Coupon from "../../../components/common/coupon";

export default function MyVoucher() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions()

  const { merchantId } = useSelector((state) => state.common);
  const { isOpenPromotionModal } = useSelector((state) => state.modal);
  const { myPromotion, usedPromotion, myPromotionLoading } = useSelector((state) => state.cart);

  const [currentStatus, setCurrentStatus] = useState('pending');

  const voucherOption = [
    {value: 'pending', title: 'Available'},
    {value: 'completed', title: 'Used'}
  ]

  function formatExpiryDate(date) {
    const expiryDate = new Date(date);

    const day = expiryDate.getDate();
    const month = expiryDate.toLocaleString('default', { month: 'short' });
    const year = expiryDate.getFullYear();

    const formattedDateString = `${day} ${month} ${year}`;

    return formattedDateString;
}

  const handleClosePromotion = () => {
    dispatch(closePromotionModal())
  }

  const handleOpenPromoDetails = (id) => {
    handleClosePromotion()
    dispatch(openPromotionDetailsModal('open-bag'))
    dispatch(getPromotionInfo({
      id: id
    }))
  }

  const handleUseNow = (promo) => {
    dispatch(closePromotionModal())
    dispatch(setUseNowItem({
      promotion_id: promo.user_promotion_bag_id,
      media: promo.media,
      display_name: promo.title,
      expiry_date: formatExpiryDate(promo.expiry_date)
    }))
    dispatch(openCouponPopUp())
  }

  const handleFilterPromotion = (value) => {
    setCurrentStatus(value)
    dispatch(getMyPromotion({
      cart_id: '',
      merchant_id: merchantId,
      status: value
    }))
  }

  useEffect(() => {
    dispatch(getMyPromotion({
        cart_id: '',
        merchant_id: merchantId,
        status: currentStatus
    }))
  }, [])

  return (
    <FullModal
      show={isOpenPromotionModal}
      backButton={handleClosePromotion}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName="--white"
      content={
        <>
            <ModalHeader
                title="My Voucher" 
                backTo={handleClosePromotion} 
                backToNoAnimation={handleClosePromotion} 
                type="model2"
            />
            <MDBModalBody>
                <article className="my-voucher-modal">
                  <section className="curve-bg --short"></section> 
                  <article className="voucher-option pointer">
                    {voucherOption.map((option, optionIndex) => (
                      <h6 
                        className={option.value === currentStatus ? "--active" : ''} 
                        onClick={() => !(myPromotionLoading && option.value === currentStatus) ? handleFilterPromotion(option.value) : ''}
                        key={optionIndex}
                      >{option.title}</h6>
                    ))}
                  </article>
                  <section className="voucher-listing">
                  {!myPromotionLoading && (currentStatus === 'pending' ? myPromotion.promotion_bag : usedPromotion.promotion_bag)?.length > 0 ? 
                    (currentStatus === 'pending' ? myPromotion.promotion_bag : usedPromotion.promotion_bag).map((myPromo, myPromoIndex) => (
                      <article className="relative w-100" key={myPromoIndex}>
                        {currentStatus === 'completed' && <article className="used-badge">
                          <p>Used</p>
                        </article>}
                        <Coupon 
                          tnc={currentStatus === 'pending'}
                          promoInfo={myPromo} 
                          handleUseNow={handleUseNow} 
                          handleOpenPromoDetails={handleOpenPromoDetails} 
                          status={currentStatus} 
                          className={`--brown ${currentStatus === 'completed' ? 'default' : ''}`} 
                        />
                      </article>
                    ))
                  :
                    myPromotionLoading ?
                      Array.from({ length: 2 }, (_, promoSkeletonIndex) => (
                        <Skeleton
                          width="100%"
                          height="85px"
                          border-radius="8px"  
                          key={promoSkeletonIndex}        
                        />
                      ))
                    : 
                      currentStatus === 'pending' ?
                        <article className="empty-container">
                          <p>You didn't claim any promotion yet.</p>
                        </article>
                      : 
                        <article className="empty-container">
                          <p>You didn't used up any promotion yet.</p>
                        </article>
                  }
                  </section>
                </article>
            </MDBModalBody>
        </>
    }
    />
  );
}