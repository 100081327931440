import { configureStore } from "@reduxjs/toolkit";

//reducer
import commonReducer from "./reducer/commonReducer";
import modalReducer from "./reducer/modalReducer";
import checkInReducer from "./reducer/checkInReducer";
import cartReducer from "./reducer/cartReducer";
import homeReducer from "./reducer/homeReducer";
import menuReducer from "./reducer/menuReducer";
import queueReducer from "./reducer/queueReducer";
import spinToWinReducer from "./reducer/spinToWinReducer";
import merchantReducer from "./reducer/merchantReducer";
import addressReducer from "./reducer/addressReducer";
import orderReducer from "./reducer/orderReducer";
import reserveReducer from "./reducer/reserveReducer";

const reducer = {
    common: commonReducer,
    modal : modalReducer,
    cart: cartReducer,
    check_in: checkInReducer,
    home: homeReducer,
    menu: menuReducer,
    queue: queueReducer,
    reserve: reserveReducer,
    spin_to_win: spinToWinReducer,
    merchant: merchantReducer,
    address: addressReducer,
    order: orderReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: process.env.REACT_APP_DEV_TOOL,
})

export default store