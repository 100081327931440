import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";
import { CheckIn, DeliveryHome, DineInHome, Feedback, History, Profile, Queue, Sample, SelfPickHome, Spin, Vip, Voucher, VoucherFeature } from "../../assets/images";
import { handleLogout } from "./commonReducer";
import { useSelector } from "react-redux";

export const getWalletBalance = createAsyncThunk('getWalletBalance', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getWalletBalance(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getPointBalance = createAsyncThunk('getPointBalance', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getPointBalance(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getWalletHistory = createAsyncThunk('getWalletHistory', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getWalletHistory(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getPromotionList = createAsyncThunk('getPromotionList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getPromotionList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getPromotionCount = createAsyncThunk('getPromotionCount', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getPromotionCount(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getMarketplaceList = createAsyncThunk('getMarketplaceList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getPromotionList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getProfile = createAsyncThunk('getProfile', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getProfile(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getUserRank = createAsyncThunk('getUserRank', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getUserRank(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const buyPromotion = createAsyncThunk('buyPromotion', async (payload, thunkAPI) => {
  try {
    const response = await apiService.buyPromotion(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const claimPromotion = createAsyncThunk('claimPromotion', async (payload, thunkAPI) => {
  try {
    const response = await apiService.claimPromotion(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const claimPoint = createAsyncThunk('claimPoint', async (payload, thunkAPI) => {
  try {
    const response = await apiService.claimPoint(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  // methods: [
  //   {value: 'dine in', title: 'Dine In', subtitle: 'Enjoy here', image: DineInHome},
  //   {value: 'queue', title: 'Queue', subtitle: 'Wait your turn', image: Queue},
  //   {value: 'take away', title: 'Self Pick', subtitle: 'Pick it up', image: SelfPickHome},
  //   {value: 'delivery', title: 'Delivery', subtitle: 'Delivered to you', image: DeliveryHome}
  // ],
  genderOptions: [
    {label: 'Male', value: 'male'},
    {label: 'Female', value: 'female'}
  ],
  walletBalance: 0,
  pointBalance: 0,
  needLoading: false,
  walletHistoryLoading: false,
  callWalletHistory: false,
  walletHistory: [],
  profileInfo: {},
  userRank: {},
  promoList: [],
  promoCount: 0,
  promoCountLoading: false,
  marketplaceList: [],
  noLoading: false,
  isBuyingPromo: false,
  isViewingPromo: false,
  promoListLoading: false,
  marketplaceListLoading: false,
  walletLoading: false,
  claimLoading: false,
  pointLoading: false,
  claimPointLoading: false,
  loading: false
};

const dataSlice = createSlice({
  name: "home",
  initialState: initialData,
  reducers: {
    setCallWalletHistory: (state, action) => {
      state.callWalletHistory = action.payload;
    },
    setNeedLoading: (state, action) => {
      state.needLoading = action.payload;
    },
    setNoLoading: (state, action) => {
      state.noLoading = action.payload;
    },
    setIsBuyingPromo: (state, action) => {
      state.isBuyingPromo = action.payload;
    },
    setIsViewingPromo: (state, action) => {
      state.isViewingPromo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getWalletBalance.pending, (state) => {
      state.walletLoading = true;
    })
    .addCase(getWalletBalance.fulfilled, (state, action) => {
      state.walletLoading = false;
      state.walletBalance = action.payload.data;
    })
    .addCase(getWalletBalance.rejected, (state) => {
      state.walletLoading = false;
    })

    .addCase(getPointBalance.pending, (state) => {
      state.pointLoading = true;
    })
    .addCase(getPointBalance.fulfilled, (state, action) => {
      state.pointLoading = false;
      state.pointBalance = action.payload.data;
    })
    .addCase(getPointBalance.rejected, (state) => {
      state.pointLoading = false;
    })

    .addCase(getPromotionList.pending, (state) => {
      state.promoListLoading = true;
    })
    .addCase(getPromotionList.fulfilled, (state, action) => {
      state.promoListLoading = false;
      state.promoList = action.payload.data;
    })
    .addCase(getPromotionList.rejected, (state) => {
      state.promoListLoading = false;
    })

    .addCase(getPromotionCount.pending, (state) => {
      state.promoCountLoading = true;
    })
    .addCase(getPromotionCount.fulfilled, (state, action) => {
      state.promoCountLoading = false;
      state.promoCount = action.payload.data;
    })
    .addCase(getPromotionCount.rejected, (state) => {
      state.promoCountLoading = false;
    })

    .addCase(getMarketplaceList.pending, (state) => {
      state.marketplaceListLoading = true;
    })
    .addCase(getMarketplaceList.fulfilled, (state, action) => {
      state.marketplaceListLoading = false;
      state.marketplaceList = action.payload.data;
    })
    .addCase(getMarketplaceList.rejected, (state) => {
      state.marketplaceListLoading = false;
    })

    .addCase(getWalletHistory.pending, (state) => {
      state.walletHistoryLoading = true;
    })
    .addCase(getWalletHistory.fulfilled, (state, action) => {
      state.walletHistoryLoading = false;
      state.walletHistory = action.payload.data.aaData;
    })
    .addCase(getWalletHistory.rejected, (state) => {
      state.walletHistoryLoading = false;
    })

    .addCase(getProfile.pending, (state) => {
      state.loading = true;
    })
    .addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profileInfo = action.payload.data;
    })
    .addCase(getProfile.rejected, (state) => {
      state.loading = false;
    })

    .addCase(getUserRank.pending, (state) => {
      state.loading = true;
    })
    .addCase(getUserRank.fulfilled, (state, action) => {
      state.loading = false;
      state.userRank = action.payload.data;
    })
    .addCase(getUserRank.rejected, (state) => {
      state.loading = false;
    })

    .addCase(claimPromotion.pending, (state) => {
      state.claimLoading = true;
    })
    .addCase(claimPromotion.fulfilled, (state, action) => {
      state.claimLoading = false;
    })
    .addCase(claimPromotion.rejected, (state) => {
      state.claimLoading = false;
    })

    .addCase(claimPoint.pending, (state) => {
      state.claimPointLoading = true;
    })
    .addCase(claimPoint.fulfilled, (state, action) => {
      state.claimPointLoading = false;
    })
    .addCase(claimPoint.rejected, (state) => {
      state.claimPointLoading = false;
    });
  }
});

export const { 
  setCallWalletHistory,
  setNeedLoading,
  setIsBuyingPromo,
  setIsViewingPromo,
  setNoLoading
} = dataSlice.actions;

export default dataSlice.reducer;
