import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";

export default function Footer() {

  const currentYear = new Date().getFullYear();
  const copyrightText = `Copyright © ${currentYear} FoodLink. All rights reserved.`;

  const { deviceId } = useSelector((state) => state.common);
  const { merchantInfo } = useSelector((state) => state.merchant);

  return (
    <>
      <footer className="footer-container">
        {merchantInfo.company_logo?.length > 0 && <article className="w-100 flex justify-center">
          <article className="logo-frame mb-5">
            <LazyLoadImage src={merchantInfo.company_logo[0].url} />
          </article>
        </article>}
        <article className="flex justify-between flex-wrap">
          <p className="_label --company_name">
            {copyrightText} v{process.env.REACT_APP_VERSION}
          </p>
          <p className="_label --company_name --smaller">
            {deviceId}
          </p>
        </article>
      </footer>
    </>
  );
}
