import { Field, useFormikContext } from "formik";
import { MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import ReactInputVerificationCode from "react-input-verification-code";
import AuthCode from "react-auth-code-input";

//auth

export default function CodeInput({ name, className, label, disabled }) {

const { errors, values, touched, setFieldValue } = useFormikContext();

  const [code, setCode] = useState("");

  const handleChange = (e) => {
    setFieldValue(name, e);
    // setCode(e);
  };

  // const handleCompleted = (e) => {
  // };

  return (
    <>
      <MDBCol className={`element _code-input ${className ? className : ''}`}>
        <article>
          <label>{label}</label>
        </article>
        <article className="code-row">
          <AuthCode
            allowedCharacters="numeric"
            autoFocus={false}
            length={6}
            isPassword={true}
            inputClassName="code-input"
            onChange={(e) => handleChange(e)}
            disabled={disabled}
          />
        </article>
      </MDBCol>
      <MDBRow>
        {errors && touched && errors[name] && touched[name] ? (
          <div className="element _errors text-right no-padding error-message ms-0">
            {errors[name]}
          </div>
        ) : null}
      </MDBRow>
    </>
  );
}
