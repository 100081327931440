import { useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import InfiniteScroll from "react-infinite-scroll-component";

import { useDispatch, useSelector } from "react-redux";
import { getTopUpPackageList } from "../../../redux/reducer/commonReducer";
import { closeHistoryModal, openTopUpModal, setAnimationModal } from "../../../redux/reducer/modalReducer";
import { getWalletBalance, getWalletHistory, setNeedLoading } from "../../../redux/reducer/homeReducer";

import scrollToTop from "../../../helpers/scroll.to.top";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

export default function WalletHistory() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions()

  const { merchantId } = useSelector((state) => state.common);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { isOpenHistoryModal } = useSelector((state) => state.modal);
  const { walletBalance, walletHistoryLoading } = useSelector((state) => state.home);

  const [length, setLength] = useState(14);
  const [start, setStart] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const [walletInteger, walletDecimal] = String(walletBalance).split('.');

  const getDateTime = (dateTime) => {
    const dateObject = new Date(dateTime);

    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateObject);
    const year = dateObject.getFullYear();
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  
    const formattedDateTime = `${day} ${month} ${year} ${hours}:${minutes}`;

    return formattedDateTime;
  }

  let scrollHeight = height - (width > 550 ? (height * 0.4) : (height * 0.45))

  const getMoreData = () => {
    dispatch(setNeedLoading(false))
    dispatch(getWalletHistory({
      merchant_id: merchantId,
      length: length,
      start: start + 14
    }))
    .unwrap()
    .then((res) => {
      setStart(start + 14);

      let newData = [];
      for (var key in res.data.aaData) {
        newData.push({
          ...res.data.aaData[key],
        });
      }
      if (data.length + newData.length >= res.data.iTotalRecords) {
        setHasMore(false);
      }
      setData([...data, ...newData]);
    })
    .catch((ex) => {});
  }
  
  const handleCloseHistory = () => {
    dispatch(closeHistoryModal())
  }

  const handleOpenTopUp = () => {
    dispatch(setAnimationModal(false))
    dispatch(closeHistoryModal())
    dispatch(getTopUpPackageList({
      merchant_id: merchantId
    }))
    dispatch(openTopUpModal())
  }

  useEffect(() => {
    dispatch(getWalletBalance({
      merchant_id: merchantId
    }))
    dispatch(getWalletHistory({
      merchant_id: merchantId,
      length: length,
      start: start,
    }))
    .unwrap()
    .then((res) => {
      setData(res.data.aaData);
      if (data.length < res.data.iTotalRecords) {
        setHasMore(true)
      }
    })
    .catch((ex) => {});
  }, []);
  
  
  useEffect(() => {
    dispatch(setAnimationModal(true))
    if (modalRef.current) {
        scrollToTop(modalRef.current);
    }
  }, [isOpenHistoryModal]);

  return (
    <FullModal
      show={isOpenHistoryModal}
      backButton={handleCloseHistory}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      className="--hidden"
      contentClassName="--white"
      content={
        <>
            <ModalHeader
                title="Wallet History" 
                backTo={handleCloseHistory} 
                backToNoAnimation={handleCloseHistory} 
                type="model2"
            />
            <MDBModalBody ref={modalRef}>
                <article style={{ paddingBottom: '4em' }} className="wallet-history-modal">
                  <section className="curve-bg"></section> 
                  <section className="wallet-section">
                    <article className="wallet-details">
                      <p>Merchant</p>
                      <h3>{merchantInfo.store_name}</h3>
                    </article>                               
                    <article className="wallet-details --full mt-3">
                      <article>
                        <p>Balance</p>
                        <h3><span>RM</span> {walletInteger > 0 ? walletInteger : '0'}<span>.{walletDecimal > 0 ? `${walletDecimal.slice(0, 2)}` : '00'}</span></h3>
                      </article>
                      {merchantInfo.has_topup === 1 && <article className="icon-container" onClick={() => handleOpenTopUp()}>
                        <Icon icon="teenyicons:plus-circle-outline" className="default-icon" />
                        <Icon icon="teenyicons:plus-circle-solid" className="hover-icon" />
                      </article>}
                    </article>
                  </section>
                  <section className="body-section">
                    <h5>Transactions History</h5>
                    <InfiniteScroll
                      className={`--wallet`}
                      dataLength={data.length}
                      height={`${scrollHeight}px`}
                      hasMore={hasMore}
                      next={() => {
                        getMoreData();
                      }}
                      loader={hasMore && <div className="text-center mt-5 infinite-loading-label">Loading..</div>}
                    >
                      {(hasMore || !walletHistoryLoading) && data?.length > 0 ? data.map((history, historyIndex) => (
                          <MDBRow className="mt-4" key={historyIndex}>
                              <div className={`flex justify-between items-center history-row ${historyIndex === data.length - 1 ? '--no-line' : ''}`}>
                                  <div className={`text-start mb-3 ${width < 380 ? 'col-6' : ''}`}>
                                      <div className="_label --wallet-label">{history.description}</div>
                                      <div className="_label --wallet-desc mt-3">{getDateTime(history.created_at)}</div>
                                  </div>
                                  <div className={`_label --wallet-price ${history.amount < 0 ? '--red' : history.amount > 0 ? '--green' : ''} mb-3 ${width < 380 ? 'col-6' : ''}`}>
                                      {history.amount < 0 ? '-' : history.amount > 0 ? '+' : ''} RM {history.amount !== null ? `${Math.abs(parseFloat(history.amount)).toFixed(2)}` : '0.00'}
                                  </div>
                              </div>
                          </MDBRow>             
                        ))
                      :   !hasMore && walletHistoryLoading ?
                            <article className="general-loading">
                              <Icon icon="svg-spinners:12-dots-scale-rotate" />
                            </article>
                          : ''
                      }
                      {!hasMore && !walletHistoryLoading ?
                        data.length > 0 ?
                          <div className="text-center mt-5 infinite-loading-label --nothing">Nothing more..</div>
                        :
                          <article className="empty-container">
                            <p>No wallet history record found</p>
                          </article>
                      : ''}
                    </InfiniteScroll>
                  </section>
                </article>
            </MDBModalBody>
        </>
    }
    />
  );
}