import { useEffect } from "react";

import scrollToTop from "../../helpers/scroll.to.top";

import { useDispatch, useSelector } from "react-redux";
import { getReserveData, getToken, getUserId, getUsername } from "../../redux/reducer/commonReducer";

import ReservationHeader from "./components/reservation.header";
import ReservationBody from "./components/reservation.body";
import { getExperience, getReservationTime, getTableSection, getTimeSession, setReservationTime } from "../../redux/reducer/reserveReducer";

export default function Reservation() {
  const dispatch = useDispatch()

  const { merchantId, reserveId } = useSelector((state) => state.common);

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    dispatch(getToken())
    dispatch(getUserId())
    dispatch(getUsername())
    dispatch(getReserveData())
  }, [])

  useEffect(() => {
    if(merchantId && !reserveId) {
      // dispatch(getExperience({
      //   merchant_id: merchantId,
      // }))
      // .unwrap()
      // .then((res) => {
      //   if(res.data.length > 0) {
      //     dispatch(getTableSection({
      //       merchant_id: merchantId,
      //       experience_id: res.data[0].id
      //     }))
      //   }
      // })

      dispatch(getTimeSession({
        merchant_id: merchantId,
      }))
      .unwrap()
      .then((res) => {
        if(res.data.length > 0) {
          dispatch(setReservationTime(res.data))
        }
      })
    }
  }, [merchantId, reserveId])

  return (
    <>
      <article className="reservation-container">
        <ReservationHeader />
        <ReservationBody />
      </article>
    </>
  );
}
