import { LazyLoadImage } from "react-lazy-load-image-component";
import { DefaultCategory, FullLogo, GreenBg, GreenLogo, MerchantBg, Sample } from "../../../assets/images";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import scrollToTop from "../../../helpers/scroll.to.top";
import { getMerchantList } from "../../../redux/reducer/commonReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setThemeColor } from "../../../redux/reducer/merchantReducer";

export default function MerchantList({ handleScanQR }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { width, height } = useWindowDimensions()
  let scrollHeight = height - (width > 550 ? (height * 0.4) : (height * 0.25))

  const [length, setLength] = useState(10);
  const [start, setStart] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const { merchantListLoading } = useSelector((state) => state.common);
  
  const [searchParams, setSearchParams] = useSearchParams();
  const open_modal = searchParams.get("open");

  const currentYear = new Date().getFullYear();

  const getMoreData = () => {
    dispatch(getMerchantList({
      search: search,
      length: length,
      start: start + 10
    }))
    .unwrap()
    .then((res) => {
      setStart(start + 10);

      let newData = [];
      for (var key in res.data.aaData) {
        newData.push({
          ...res.data.aaData[key],
        });
      }

      if (data.length + newData.length >= res.data.iTotalRecords) {
        setHasMore(false);
      }

      setData([...data, ...newData]);
    })
    .catch((ex) => {});
  }

  const handleSelectMerchant = (supermerchant, store) => {
    if(supermerchant) {
      dispatch(setThemeColor({ main_color: store.color?.main_color ?? "#f6f5f5", bg_color: store.color?.bg_color }))

      if (store.color?.main_color) {
        document.body.style.setProperty(
          "--primary_modal_color",
          store.color?.main_color
        );
      }

      if (store.color?.bg_color) {
        document.body.style.setProperty(
          "--primary_background_color",
          store.color?.bg_color
        );
      }

      navigate(`/${store.display_name}/branch`)
    } else {
      navigate(`/${store.display_name}`)
    }
  }

  useEffect(() => {
    if(!open_modal) {
      const getAllMerchants = () => {
          dispatch(getMerchantList({
            search: search,
            length: length,
            start: start,
          }))
          .unwrap()
          .then((res) => {
            setData(res.data.aaData);
            if (res.data.aaData.length < res.data.iTotalRecords) {
              setHasMore(true);
            } else {
              setHasMore(false)
            }
          })
          .catch((ex) => {});
      };
  
      let timeoutId;

      if (search) {
        timeoutId = setTimeout(() => {
            getAllMerchants();
        }, 500);
      } else {
        getAllMerchants();
      }
  
      return () => clearTimeout(timeoutId);
    }
  }, [search]);

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <section className="merchant-list">
      <header className="list-header">
          <section className="main-header">
              <article className="flex items-start justify-between">
                  <p>version {process.env.REACT_APP_VERSION}</p>
                  <article className="pointer" onClick={() => handleScanQR()}>
                      <Icon icon="ant-design:scan-outlined" />
                  </article>
              </article>
              <article className="mt-4">
                  <article>
                      <h2>Explore your favourite</h2> 
                      <article className="flex">
                          <h2 className="restaurant-on">restaurants on&nbsp;</h2>
                          <article className="logo-frame">
                              <LazyLoadImage src={FullLogo} />
                          </article>
                      </article>
                  </article>
              </article>
          </section>
          <section className="header-bg">
              <LazyLoadImage src={MerchantBg} />
          </section>
          <section className="search-merchant">
              <article>
                  <Icon icon="flowbite:search-outline" color={search ? "#ed1c24" : ""} />
              </article>
              <article className="relative w-100">
                  <input 
                      value={search}
                      className={`w-100 ${search ? 'search-input' : ''}`}
                      onChange={(e) => {
                          setStart(0)
                          setSearch(e.target.value)
                      }}
                  />
                  <span className="placeholder"></span>
              </article>
              {search && <article className="pointer" onClick={() => setSearch('')}> 
                  <Icon icon="carbon:close-filled" />
              </article>}
          </section>
      </header>
      <article className="list-body">
          <InfiniteScroll
              className="--merchant-list"
              dataLength={data.length}
              height={`${scrollHeight}px`}
              hasMore={hasMore}
              next={() => {
                  getMoreData();
              }}
              loader={hasMore && <div className="text-center mt-5 infinite-loading-label">Loading..</div>}
          >
              {(hasMore || !merchantListLoading) && data?.length > 0 ? data.map((merchant, merchantIndex) => (
                  <section className="merchant-info pointer" key={merchantIndex} onClick={() => handleSelectMerchant(merchant.is_supermerchant, merchant)}>
                      <article className="store-frame">
                          <LazyLoadImage src={merchant.logo_attachment} />
                      </article>
                      <article>
                          <h4 className="one-line me-3 w-100">{merchant.store_name}</h4>
                          {!merchant.is_supermerchant && <h6 className={`tag mb-2 ${merchant.remark === 'closed' ? '--closed' : '' }`}>{merchant.remark === 'available' ? 'Open' : 'Closed'}</h6>}
                          <p className="mb-0 two-line">{merchant.description}</p>
                          {merchant.is_supermerchant ?
                            <article className="rating --supermerchant mt-1">
                              <p>See {merchant.outlet_count} outlets</p>
                              <Icon icon="teenyicons:right-circle-solid" />
                            </article>
                          :
                            <article className="rating mt-1">
                              <Icon icon="line-md:map-marker-filled" />
                              <p className="text-capitalize">{merchant.state}</p>
                            </article>
                          }
                      </article>
                  </section>
                ))
              :   ((!hasMore && merchantListLoading) || (search && merchantListLoading)) ?
                      <article className="general-loading">
                          <Icon icon="svg-spinners:12-dots-scale-rotate" color="#6c5fe680" />
                      </article>
                  : ''
              }
              {!hasMore && !merchantListLoading ?
                data.length > 0 ?
                  <div className="text-center mt-5 infinite-loading-label --nothing">Nothing more..</div>
                :
                  <article className="empty-container">
                      <p>No available merchant found</p>
                  </article>
                : ''
              }
          </InfiniteScroll>
      </article>  
    </section>
  );
}
