export default function SymbolLine({ className, children }) {
  return (
    <article className={`common _symbol-line ${className ? className : ''}`}>
        <section className="line relative">
          <span className="symbol --left">&#11045;</span>
          {children && <h1>{children}</h1>}
          <span className="symbol --right">&#11045;</span>
        </section>
    </article>
  );
}
