import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

// const checkCache = (state, cacheKey) => {
//   const cachedData = state.menu.menuCache[cacheKey];
  
//   if (!cachedData) return null;
  
//   const currentTime = new Date().getTime();
//   const cacheTime = cachedData.timestamp;
//   const isExpired = currentTime - cacheTime > 5 * 60 * 1000;

//   return isExpired ? null : cachedData;
// };

export const getMenuList = createAsyncThunk('getMenuList', async (payload, thunkAPI) => {
  // const cacheKey = 'menuList';
  // const cachedData = checkCache(thunkAPI.getState(), cacheKey);

  // if (cachedData) {
  //   return { data: { menu: cachedData }};
  // }

  try {
    const response = await apiService.getMenuList(payload);
    // thunkAPI.dispatch(cacheData({ key: cacheKey, data: response.data.menu }));
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getMenuInfo = createAsyncThunk('getMenuInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getMenuInfo(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getRecommendMenu = createAsyncThunk('getRecommendMenu', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getRecommendMenu(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  menuId: '',
  menuInfo: {},
  menuInfoLoading: false,
  deliveryMenuList: [],
  takeAwayMenuList: [],
  dineInMenuList: [],
  cartOwnerId: '',
  menuListLoading: false,
  quantity: 1,
  isAddingCartId: null,

  showRecommend: true,
  // menuCache: {},
};

const dataSlice = createSlice({
  name: "menu",
  initialState: initialData,
  reducers: {
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setMenuId: (state, action) => {
      state.menuId = action.payload;
    },
    setMenuList: (state, action) => {
      const method = localStorage.getItem('method')
      const menuList = action.payload;

      if (method === 'delivery') {
        state.deliveryMenuList = menuList;
      } else if (method === 'dine in') {
        state.dineInMenuList = menuList;
      } else {
        state.takeAwayMenuList = menuList;
      }
    },
    setShowRecommend: (state, action) => {
      state.showRecommend = action.payload;
    },
    setIsAddingCartId: (state, action) => {
      state.isAddingCartId = action.payload;
    },
    // cacheData: (state, action) => {
    //   const { key, data } = action.payload;
    //   state.menuCache[key] = {
    //     data,
    //     timestamp: new Date().getTime()
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getMenuInfo.pending, (state) => {
      state.menuInfoLoading = true;
    })
    .addCase(getMenuInfo.fulfilled, (state, action) => {
      state.menuInfoLoading = false;
      state.menuInfo = action.payload.data;
    })
    .addCase(getMenuInfo.rejected, (state) => {
      state.menuInfoLoading = false;
    })

    .addCase(getMenuList.pending, (state) => {
      state.menuListLoading = true;
    })
    .addCase(getMenuList.fulfilled, (state, action) => {
      const method = localStorage.getItem('method')
      const menuList = action.payload.data.menu

      if (method === 'delivery') {
        state.deliveryMenuList = menuList;
      } else if (method === 'dine in') {
        state.dineInMenuList = menuList;
      } else {
        state.takeAwayMenuList = menuList;
      }
      
      state.menuListLoading = false;
      state.cartOwnerId = action.payload.data.cart_owner_id;
    })
    .addCase(getMenuList.rejected, (state) => {
      state.menuListLoading = false;
    })

    .addCase(getRecommendMenu.pending, (state) => {
      state.recommendMenuLoading = true;
    })
    .addCase(getRecommendMenu.fulfilled, (state, action) => {
      state.recommendMenuLoading = false;
      state.recommendMenu = action.payload.data;
    })
    .addCase(getRecommendMenu.rejected, (state) => {
      state.recommendMenuLoading = false;
    });
  }
});

export const {
  setMenuId, 
  setQuantity,
  setShowRecommend,
  setMenuList,
  setIsAddingCartId,
  // cacheData
} = dataSlice.actions;

export default dataSlice.reducer;
