import { useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { DefaultCredit, DefaultBetterLuck } from "../../../assets/images";

import { useToast } from "../../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import {
  getSpinHistory,
  getWheelList,
  setData,
  setHasMore,
  setNoLoading,
  setStart,
} from "../../../redux/reducer/spinToWinReducer";
import { openLoginModal } from "../../../redux/reducer/modalReducer";

import apiService from "../../../services/api.service";

import Confetti from "../../../components/common/confetti";

const TheWheel = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { store } = useParams();

  const { merchantId, login, token } = useSelector((state) => state.common);
  const { spinWheelLoading, wheelList, spinToken, data, length, start } = useSelector(
    (state) => state.spin_to_win
  );

  const dropQRef = useRef(null);
  const dropInfoRef = useRef(null);
  const dropIconRef = useRef(null);
  const dropTitleRef = useRef(null);
  const drumRef = useRef(null);

  const [inProgress, setInProgress] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const [currentItemIndex, setCurrentItemIndex] = useState(2);
  const [winningIndex, setWinningIndex] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const desiredOrder = [1, 3, 5, 7, 2, 4, 6, 8];

  const sortWheelList = (wheelList, order) => {
    return [...wheelList].sort(
      (a, b) => order.indexOf(a.index) - order.indexOf(b.index)
    );
  };

  const sortedWheelList = sortWheelList(wheelList, desiredOrder);

  const handleSpinWheel = async () => {
    if (login === "full" && token) {
      if (spinToken?.remaining_token > 0) {
        if (inProgress || !wheelList || wheelList.length === 0 || spinning) return;
        setInProgress(true);
        setSpinning(true);

        // const initialSpinDegrees = 360;
        // setRotationDegrees((prevDegrees) => prevDegrees + initialSpinDegrees);

        let animationFrame;

        const continueSpin = () => {
          const againSpinDegrees = 360;
          setRotationDegrees((prevDegrees) => prevDegrees + againSpinDegrees);

          animationFrame = requestAnimationFrame(continueSpin);
        };
  
        continueSpin();

        try {
          const response = await apiService.spinWheel({
            merchant_id: merchantId,
          });
          
          if (response.data) {
            cancelAnimationFrame(animationFrame);
            setSpinning(false);

            let apiData = response.data.index;
            switch (apiData) {
              case 1:
                apiData += 10; //11
                break;
              case 2:
                apiData += 5; //7
                break;
              case 3:
                apiData += 7; //10
                break;
              case 4:
                apiData += 2; //6
                break;
              case 5:
                apiData += 4; //9
                break;
              case 6:
                apiData -= 1; //5
                break;
              case 7:
                apiData += 1; //8
                break;
              case 8:
                apiData -= 4; //4
                break;
              default:
                break;
            }

            const winningIndex = apiData - 1;
            const degreesPerSegment = 360 / wheelList.length;

            let finalDegrees = (winningIndex * degreesPerSegment) % 360;
            finalDegrees += 360 * 3;
            finalDegrees += 360 - (rotationDegrees % 360);

            setRotationDegrees((prevDegrees) => prevDegrees + finalDegrees);
            setCurrentItemIndex(winningIndex);
            setWinningIndex(response.data.index);
          }
        } catch (ex) {
          cancelAnimationFrame(animationFrame);
          setSpinning(false);

          if (ex && Object.keys(ex).length > 0) {
            let errorMsg = [];
            if (ex.response?.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  errorMsg = errors[item][0];
                });
                toast.error(errorMsg);
              }
            }
          }
        }
      } else {
        toast.error(`You've run out of spin wheel tokens.`);
      }
    } else {
      dispatch(openLoginModal());
    }
  };

  const handleRotationEnd = () => {
    setInProgress(false);
  };

  useEffect(() => {
    const drum = drumRef.current;
    if (drum) drum.addEventListener("transitionend", handleRotationEnd);

    return () => {
      if (drum) drum.removeEventListener("transitionend", handleRotationEnd);
    };
  }, [winningIndex]);

  useEffect(() => {
    if (!inProgress && rotationDegrees !== 0) {
      dispatch(setStart(0))
      dispatch(setData([]))
      dispatch(setHasMore(false))

      setShowConfetti(true);
      dispatch(setNoLoading(true));
      dispatch(getWheelList({ merchant_id: merchantId }));
      dispatch(getSpinHistory({
        merchant_id: merchantId,
        has_reward: '',
        startDate: '',
        endDate: '',
        start: 0,
        search: store,
        length: length
      }))
      .unwrap()
      .then((res) => {
        dispatch(setData(res.data.aaData));
        if (data.length < res.data.iTotalRecords) {
          dispatch(setHasMore(true));
        }
      })
      .catch((ex) => {});
    }
  }, [inProgress, rotationDegrees]);

  const drumStyle = {
    transform: `rotate(${rotationDegrees}deg)`,
    transition: "transform 2s ease-out",
  };

  // useEffect(() => {
  //   if (drumRef.current) {
  //     drumRef.current.style.transform = `rotate(${rotationDegrees}deg)`;
  //   }
  // }, [rotationDegrees]);

  useEffect(() => {
    if (rotationDegrees !== 0 && !inProgress) {
      setShowConfetti(true);

      const confettiTimeout = setTimeout(() => {
        setShowConfetti(false);
      }, 5000);

      return () => clearTimeout(confettiTimeout);
    }
  }, [rotationDegrees, inProgress]);

  return (
    <div className="vk-bn vk-bn--dtf">
      {showConfetti && <Confetti width="100%" />}
      <div className="vk-bn__container">
        <div
          className="vk-bn__spinwheel pointer"
          onClick={() => {
            handleSpinWheel();
          }}
        >
          <div className="vk-bn-spinwheel">
            <div className="vk-bn-spinwheel__rim">
              <svg viewBox="0 0 217.96 218.01">
                <path
                  d="M108.9 9a100 100 0 11-58.7 19.11A100 100 0 01108.9 9zm0-9a108.9 108.9 0 1048.76 11.46A109 109 0 00108.9 0z"
                  fill="#75317c"
                />
                <circle cx="108.98" cy="4.44" r="1.5" />
                <circle cx="108.98" cy="213.59" r="1.5" />
                <circle cx="76.67" cy="9.55" r="1.5" />
                <circle cx="141.3" cy="208.47" r="1.5" />
                <circle cx="47.51" cy="24.41" r="1.5" />
                <circle cx="170.45" cy="193.62" r="1.5" />
                <circle cx="24.38" cy="47.54" r="1.5" />
                <circle cx="193.59" cy="170.48" r="1.5" />
                <circle cx="9.52" cy="76.7" r="1.5" />
                <circle cx="208.44" cy="141.33" r="1.5" />
                <circle cx="4.4" cy="109.01" r="1.5" />
                <circle cx="213.56" cy="109.01" r="1.5" />
                <circle cx="9.52" cy="141.33" r="1.5" />
                <circle cx="208.44" cy="76.7" r="1.5" />
                <circle cx="24.38" cy="170.48" r="1.5" />
                <circle cx="193.59" cy="47.54" r="1.5" />
                <circle cx="47.51" cy="193.62" r="1.5" />
                <circle cx="170.45" cy="24.41" r="1.5" />
                <circle cx="76.67" cy="208.47" r="1.5" />
                <circle cx="141.3" cy="9.55" r="1.5" />
              </svg>
            </div>
            <div className="vk-bn-spinwheel__pin">
              <svg viewBox="0 0 47.67 34.45">
                <path
                  d="M43.68 33.45a3 3 0 01-1-.15L3.05 20.07a3 3 0 01-2-2.84 3 3 0 01.57-1.76 3 3 0 011.48-1.09L42.73 1.16a2.9 2.9 0 011.4-.16 3.11 3.11 0 011.3.54 2.94 2.94 0 01.92 1.06 3.14 3.14 0 01.33 1.4v26.45a3 3 0 01-3 3z"
                  fill="#3d7cc9"
                />
                <path
                  d="M43.68 2a2 2 0 011.41.59A2 2 0 0145.67 4v26.45a1.9 1.9 0 01-.21.91A2 2 0 0144 32.43a2 2 0 01-.94-.08L3.37 19.12a1.94 1.94 0 01-1-.72 2 2 0 011-3.07L43 2.1a2.31 2.31 0 01.64-.1zm0-2a3.93 3.93 0 00-1.27.21L2.73 13.43a4 4 0 00-2 1.46 4 4 0 002 6.13l39.68 13.23a4 4 0 004.83-2 3.88 3.88 0 00.43-1.83V4a4 4 0 00-4-4z"
                  fill="#fff"
                />
              </svg>
            </div>
            <div
              className={`vk-bn-spinwheel__logo ${
                spinWheelLoading ? "--empty" : ""
              } pointer`}
            >
              {spinWheelLoading && rotationDegrees === 0 ? (
                <article className="logo-frame">
                  <Icon icon="line-md:loading-twotone-loop" />
                </article>
              ) : !inProgress && !spinning ? (
                <p className="mb-0">SPIN</p>
              ) : (
                ""
              )}
            </div>
            <div className="vk-bn-spinwheel__drum">
              <div
                ref={drumRef}
                className="vk-bn-spinwheel__drum-inner"
                style={drumStyle}
              >
                {!spinWheelLoading &&
                  wheelList?.length > 0 &&
                  sortedWheelList.map((wheel, wheelIndex) => (
                    <div className="vk-bn-spinwheel__sector" key={wheelIndex}>
                      <div className="vk-bn-spinwheel__emoji">
                        <LazyLoadImage
                          src={
                            wheel.media?.length > 0
                              ? wheel.media[0].url
                              : DefaultCredit
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="vk-bn__drop">
          <div className="vk-bn-drop">
            {(inProgress || rotationDegrees === 0) && (
              <div className="vk-bn-drop__q">?</div>
            )}
            {!inProgress &&
              rotationDegrees !== 0 &&
              wheelList[winningIndex - 1]?.reward_name && (
                <div ref={dropInfoRef} className="vk-bn-drop__info">
                  <img
                    ref={dropIconRef}
                    src={
                      wheelList[winningIndex - 1]?.media?.length > 0
                        ? wheelList[winningIndex - 1]?.media[0].url
                        : wheelList[winningIndex - 1]?.reward_name === "credit"
                        ? DefaultCredit
                        : DefaultBetterLuck
                    }
                    alt=""
                    className="vk-bn-drop__icon"
                  />
                  <div ref={dropTitleRef} className="vk-bn-drop__title">
                    {wheelList[winningIndex - 1]?.reward_name === "credit"
                      ? `You have won ${parseFloat(
                          wheelList[winningIndex - 1]?.reward_amount
                        ).toFixed(2)} credit!`
                      : wheelList[winningIndex - 1]?.reward_name}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheWheel;

// setTimeout(() => {
//   setInProgress(false);
//   dispatch(setNoLoading(true))
//   dispatch(getWheelList({
//     merchant_id: merchantId
//   }))
//   dispatch(getSpinHistory({
//     merchant_id: merchantId,
//     has_reward: '',
//     startDate: '',
//     endDate: '',
//     search: store,
//     length: 9999
//   }))
//   dropQRef.current.classList.add('hide');
//   dropInfoRef.current.classList.add('show');
//   dropIconRef.current.src = wheelList[response.data.index - 1]?.media?.length > 0 ? wheelList[response.data.index - 1].media[0].url : wheelList[response.data.index - 1]?.reward_name === 'credit' ? DefaultCredit : DefaultBetterLuck;
//   dropTitleRef.current.innerHTML = wheelList[response.data.index - 1]?.reward_name === 'credit' ? `You had won ${parseFloat(wheelList[response.data.index - 1]?.reward_amount).toFixed(2)} credit!` : wheelList[response.data.index - 1]?.reward_name;
// }, 2000);

// case 1:
//   apiData += 10;
//   break;
// case 2:
//   apiData += 8;
//   break;
// case 3:
//   apiData += 6;
//   break;
// case 4:
//   apiData += 4;
//   break;
// case 5:
//   apiData += 2;
//   break;
// case 6:
//   apiData += 0;
//   break;
// case 7:
//   apiData -= 2;
//   break;
// case 9:
//   apiData -= 4;
//   break;