import { Swiper, SwiperSlide } from "swiper/react";

import { Skeleton } from "primereact/skeleton";

import { useToast } from "../../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import { claimPromotion, getPromotionCount, getPromotionList } from "../../../redux/reducer/homeReducer";
import { getPromotionInfo } from "../../../redux/reducer/commonReducer";
import { openLoginModal, openPromotionDetailsModal } from "../../../redux/reducer/modalReducer";

import Coupon from "../../../components/common/coupon";

import apiService from "../../../services/api.service";

export default function AvailableVoucher({ setLoginType }) {
  const toast = useToast();
  const dispatch = useDispatch();

  const { merchantId, login } = useSelector((state) => state.common);
  const { promoListLoading, promoList } = useSelector((state) => state.home);
  
  const handleClaimVoucher = async (id) => {
    if(login === 'full') {
      dispatch(claimPromotion({
        id: id
      }))
      .unwrap()
      .then((res) => {
        dispatch(getPromotionList({
          merchant_id: merchantId
        }))
        .unwrap()
        .then((res) => {
          toast.success('Claim voucher successful. Please use it before the expiration.');
        })
        .catch((ex) => {})
        dispatch(getPromotionCount({
          merchant_id: merchantId
        }))
      })
      .catch((ex) => {
        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = [];
          if (ex.response?.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                  Object.keys(errors).map((item, i) => {
                      errorMsg = errors[item][0];
                      toast.error(errorMsg);
                  });
              }
          }
        }
      })
    } else {
        setLoginType('full')
        dispatch(openLoginModal())
    }
  }

  const handleOpenPromoDetails = (id) => {
    dispatch(openPromotionDetailsModal('not-claimed-home'))
    dispatch(getPromotionInfo({
      id: id
    }))
  }

  return (
    <section className="available-voucher"> 
      <Swiper
          slidesPerView='auto'
          grabCursor={true}
      >
      {!promoListLoading && promoList.length > 0 ? promoList.map((promo, promoIndex) => (
          <SwiperSlide className="voucher-swiper" key={promoIndex}>
            <Coupon promoInfo={promo} handleClaimVoucher={handleClaimVoucher} handleOpenPromoDetails={handleOpenPromoDetails} />
          </SwiperSlide>
        )) 
      : 
        promoListLoading ? 
          Array.from({ length: 5 }, (_, promoSkeletonIndex) => (
            <SwiperSlide className="voucher-swiper" key={promoSkeletonIndex}>
              <Skeleton 
                width="350px"
                height="85px"
                borderRadius="8px"
              />
            </SwiperSlide>
          ))
        :
          <article className="mb-4" />
      }
      </Swiper>
    </section>
  );
}
