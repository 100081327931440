import { useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Icon } from "@iconify/react";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  getCartId,
  getCartOwner,
  getGuest,
  getLogin,
  getUsername,
} from "../../../redux/reducer/commonReducer";
import {
  closeLoginModal,
  openFeedbackModal,
  openPaymentModal,
} from "../../../redux/reducer/modalReducer";
import {
  getAllPayments,
  getCartInfo,
  setPaymentStatus,
  setUpdateCartId,
} from "../../../redux/reducer/cartReducer";

import Button from "../../../components/element/button";
import Input from "../../../components/element/input";

import authService from "../../../services/auth.service";

export default function PreRegister({ type, handleDirectCheckout }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [isLogging, setIsLogging] = useState(false);

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { paymentMethod, cartInfo } = useSelector((state) => state.cart);
  const { deviceId, merchantId, tableId, method, cartId, userId } = useSelector(
    (state) => state.common
  );

  const preRegisterForm = {
    username: "",
    verification_code: "",
    terms_condition: "",
  };

  const preRegisterSchema = Yup.object({
    username: Yup.string()
      .required("Phone number is required")
      .matches(
        /^(1)[0-9]{8,9}$/,
        "Enter a valid international phone number (e.g., 123456789)"
      ),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    setIsLogging(true);

    try {
      const response = await authService.preRegister({
        device_id: deviceId,
        merchant_id: merchantId ?? null,
        table_id: tableId ? tableId === 'null' ? null : tableId : null,
        username: `60${values.username}`,
      });
      if (response) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("username", `+60${values.username}`);
        localStorage.setItem("user_id", response.data.id);
        localStorage.setItem("login", "partial");
        dispatch(getLogin());
        dispatch(getUsername());

        if (type === "partial-cart") {
          dispatch(setUpdateCartId("login-loading"));
          dispatch(
            getCartInfo({
              merchant_id: merchantId,
              order_method: method,
            })
          )
            .unwrap()
            .then((res) => {
              if (res.data.length > 0) {
                localStorage.setItem("cart_id", res.data[0].cart_id);
                if(res.data[0].user_id === parseInt(userId)) {
                  localStorage.setItem("cart_owner", true);
                }
                dispatch(getCartId());
                dispatch(getCartOwner());

                if (paymentMethod.name) {
                  dispatch(closeLoginModal());
                  handleDirectCheckout(res.data[0].cart_id);
                } else {
                  dispatch(setPaymentStatus('direct-pay'))
                  dispatch(
                    getAllPayments({
                      cart_id: res.data[0].cart_id,
                      amount: res.data[0].total_price,
                      type: "cart",
                      merchant_id: merchantId,
                      allow_online_payment: merchantInfo.allow_online_payment,
                    })
                  )
                    .unwrap()
                    .then((res) => {
                      setIsLogging(false);
                      dispatch(closeLoginModal());
                      dispatch(setUpdateCartId(null));
                      dispatch(openPaymentModal());
                      toast.success(
                        `Pre-register successful. Welcome +60${values.username}.`
                      );
                    })
                    .catch((ex) => {
                      setIsLogging(false)
                      dispatch(closeLoginModal())
                      dispatch(setUpdateCartId(null))
                      dispatch(setPaymentStatus(null))

                      if (ex.response?.status === 422) {
                        const errors = ex.response.data.errors;
                        if (errors && Object.keys(errors).length > 0) {
                          Object.keys(errors).map((item, i) => {
                            if (item === "cart_id") {
                              toast.error('Your cart has been outdated. Please refresh.');
      
                              setTimeout(() => {
                                window.location.reload()
                              }, 1000)
                            }  else {
                              toast.error(errors[item][0])
                            }
                          });
                        }
                      }
                    });

                  return;
                }
              } else {
                dispatch(closeLoginModal())
                toast.error('Your cart has been outdated. Please refresh.');
  
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            })
            .catch((ex) => {})

          return;
        }

        dispatch(closeLoginModal());
        toast.success(
          `Pre-register successful. Welcome +60${values.username}.`
        );

        if (type === "feedback") {
          dispatch(openFeedbackModal());
        }
      }
    } catch (ex) {
      setIsLogging(false);
      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      }
    }
  };

  const handleContinueGuest = () => {
    localStorage.setItem("guest", true);
    dispatch(getGuest());

    if(cartId && cartInfo?.total_price) {
      if (paymentMethod.name) {
        handleDirectCheckout();
      } else {
        dispatch(setPaymentStatus('direct-pay'))
        dispatch(
          getAllPayments({
            cart_id: cartId,
            amount: cartInfo?.total_price,
            type: "cart",
            merchant_id: merchantId,
            allow_online_payment: merchantInfo.allow_online_payment,
          })
        )
          .unwrap()
          .then((res) => {
            dispatch(closeLoginModal());
            dispatch(setUpdateCartId(null));
            dispatch(openPaymentModal());
          })
          .catch((ex) => {
            dispatch(closeLoginModal())
            dispatch(setPaymentStatus(null))

            if (ex.response?.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  if (item === "cart_id") {
                    toast.error('Your cart has been outdated. Please refresh.');
      
                    setTimeout(() => {
                      window.location.reload()
                    }, 1000)
                  } else {
                    toast.error(errors[item][0])
                  }
                });
              }
            }
          });

        return;
      }
    }

    dispatch(closeLoginModal());
  };

  return (
    <Formik
      initialValues={preRegisterForm}
      validationSchema={preRegisterSchema}
      enableReinitialize={true}
      onSubmit={(values, { errors, setFieldError }) => {
        handleSubmit({ values, errors, setFieldError });
      }}
    >
      {({ isValid }) => (
        <Form>
          <section className="input-section pb-0">
            <Input
              type="number"
              name="username"
              autoFocus={false}
              label={<>Phone Number <span className="message">(All rewards will bind with this number)</span></>}
              placeholder="123456789"
              className="mb-2"
              isRequired={true}
              as="line-field"
              inputClassName="phone_no"
            />
            <Button
              type="submit"
              className={width > 550 ? "pt-4" : "pt-4"}
              disabled={isLogging || !isValid}
              btnClassName="w-100"
            >
              LOG IN / SIGN UP
              {isLogging && (
                <Icon
                  icon="line-md:loading-twotone-loop"
                  color="#ffffff"
                  className="login-loading ms-2 mb-1"
                />
              )}
            </Button>
            {merchantInfo.has_guest === 1 && method === "dine in" && (
              <article className="guest" onClick={() => handleContinueGuest()}>
                <h6>Continue as guest</h6>
              </article>
            )}
          </section>
        </Form>
      )}
    </Formik>
  );
}
