import useWindowDimensions from '../../components/hook/use.window.dimensions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import HomeHeader from './components/home.header';
import { useEffect, useState } from 'react';
import scrollToTop from '../../helpers/scroll.to.top';
import HomeBody from './components/home.body';
import SaveAddress from '../Address/modals/save.address';
import Login from '../Login';
import { getLogin, getSupermerchantList, getToken, getUserId, getUsername, setFullLoading } from '../../redux/reducer/commonReducer';
import { getPointBalance, getPromotionCount, getPromotionList, getWalletBalance } from '../../redux/reducer/homeReducer';
import TopUp from './modals/top.up';
import Feedback from './modals/feedback';
import WalletHistory from './modals/wallet.history';
import MyVoucher from './modals/my.voucher';
import Profile from './modals/profile';
import { getCheckInList } from '../../redux/reducer/checkInReducer';
import ScanQR from '../../components/common/scan.qr';
import PromoDetails from '../../components/common/promo.details';
import StoreInfo from '../Merchant/modals/store.info';
import Pusher from "pusher-js";
import Marketplace from './modals/marketplace';
import Footer from '../../components/footer/footer';
import ReceivePoint from '../../components/common/receive.point';
import Congratulation from '../../components/common/congratulation';
import { openReceivePointModal } from '../../redux/reducer/modalReducer';

export default function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { width } = useWindowDimensions();

    const { store } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();

    const [ loginType, setLoginType ] = useState('')

    const { merchantInfo } = useSelector((state) => state.merchant)
    const { merchantId, login, token, token_checked, userId, username } = useSelector((state) => state.common);
    const { isOpenCongratsModal, isOpenMarketModal, isOpenReceivePointModal, isOpenStoreInfoModal, isOpenPromotionDetailsModal, isOpenScanQRModal, isOpenHistoryModal, isOpenTopUpModal, isOpenFeedbackModal, isOpenProfileModal, isOpenPromotionModal, isOpenLoginModal, isOpenSetAddressModal, isOpenSaveAddressModal } = useSelector((state) => state.modal);

    const amount = searchParams.get("amount");
    const unique_no = searchParams.get("unique_no");
    const merchant_id = searchParams.get("merchant_id");
    const created_at = searchParams.get("created_at");

    const isLoggedIn = localStorage.getItem('is_logged_in')
    const isSetAddress = localStorage.getItem('is_set_address')

    useEffect(() => {
      scrollToTop()
      dispatch(getLogin())
      dispatch(getToken())
      dispatch(getUserId())
      dispatch(getUsername())
    }, [])

    useEffect(() => {
      if (token && token_checked && userId && username) {
        const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
          cluster: "ap1",
          authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              username: username ? username === 'null' ? null : username : null,
              "user-version": process.env.REACT_APP_VERSION,
            },
          },
        });
  
        const channel = pusher.subscribe("presence-user" + userId);
        var socketId = null;
        pusher.connection.bind("connected", () => {
          socketId = pusher.connection.socket_id;
        });
  
        channel.bind("topup-event", (data) => {
          if (data) {
            if(data.data?.payment_status === 'active' && data.data?.topup_id) {
              dispatch(setFullLoading(false))
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("status", "success");
              setSearchParams(newSearchParams);
            }
          }
        });
  
        return () => {
          channel.unbind("topup-event");
          pusher.disconnect();
        };
      }
    }, [token, token_checked, userId, username])

    useEffect(() => {
      if(merchantId && token && token_checked) {
        if(login === 'full') {
          dispatch(getWalletBalance({
            merchant_id: merchantId
          }))
          dispatch(getPointBalance({
            merchant_id: merchantId
          }))
        }

        dispatch(getPromotionList({
          merchant_id: merchantId
        }))

        dispatch(getPromotionCount({
          merchant_id: merchantId
        }))

        if(merchantInfo.has_check_in === 1) {
          dispatch(getCheckInList({
            merchant_id: merchantId
          }))
        }
      }
    }, [merchantId, token, token_checked])

    useEffect(() => {
      if(merchantId && token && token_checked && store) {
        dispatch(getSupermerchantList({
          display_name: store
        }))
      }
    }, [merchantId, token, token_checked, store])

    useEffect(() => {
      if(unique_no && merchant_id && amount && created_at) {
        setSearchParams('')
        dispatch(openReceivePointModal({
          unique_no, merchant_id, amount, created_at
        }))
      }
    }, [unique_no, merchant_id, amount, created_at])

    return (
      <>
        <article className="home-container">
          <HomeHeader />
          <HomeBody status={isLoggedIn} address={isSetAddress} setLoginType={setLoginType} />
        </article>
        {isOpenLoginModal && <Login type={loginType} />}
        {isOpenScanQRModal && <ScanQR />}
        {isOpenSaveAddressModal && <SaveAddress />}
        {isOpenHistoryModal && <WalletHistory />}
        {isOpenReceivePointModal?.unique_no && <ReceivePoint />}
        {isOpenMarketModal && <Marketplace setLoginType={setLoginType} />}
        {isOpenTopUpModal && <TopUp setLoginType={setLoginType} />}
        {isOpenFeedbackModal && <Feedback setLoginType={setLoginType} />}
        {isOpenPromotionModal && <MyVoucher />}
        {isOpenProfileModal && <Profile />}
        {isOpenStoreInfoModal && <StoreInfo />}
        {isOpenCongratsModal && <Congratulation setLoginType={setLoginType} />}
        {isOpenPromotionDetailsModal && <PromoDetails type="show-less" setLoginType={setLoginType} />}
      </>
    );
}