import { DefaultMenu } from "../../../assets/images";

import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { closePlaceOrderModal, openPlaceOrderModal } from "../../../redux/reducer/modalReducer";
import { getMenuInfo, setMenuId } from "../../../redux/reducer/menuReducer";
import { useToast } from "../../../components/common/toast.provider";

import { Swiper, SwiperSlide } from 'swiper/react';

export default function RecommendMenu({ show, handleDirectPlaceOrder }) {
  const dispatch = useDispatch();
  const toast = useToast();

  const { method } = useSelector((state) => state.common);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { isAddingCartId, recommendMenu } = useSelector((state) => state.menu);

  const handlePlaceOrder = (id) => {
    dispatch(
      getMenuInfo({
        id: id,
        method: method,
      })
    )
    .unwrap()
    .then((res) => {
      dispatch(setMenuId(id));
      dispatch(openPlaceOrderModal());
    })
    .catch((ex) => {
      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            toast.error(errors[item][0]);
          });
        }
      }
    });
  };

  const hasNoPromoPrice = recommendMenu.every(
    (recommend) => recommend.promotion_price === null
  );

  return (
    <header className={show ? "recommend-menu" : "recommend-menu-hide"}>
      <h2>Top {recommendMenu?.length} Recommendations</h2>
      <Swiper 
        className="recommend-grid"
        spaceBetween={20}
        slidesPerView={2.7}
        breakpoints={{
          320: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        }}
      >
        {recommendMenu?.length > 0 &&
          recommendMenu.map((recommend, recommendIndex) => (
            <SwiperSlide
              className={`recommend-col ${
                recommend.has_stock === 1 ? "pointer" : "not-allowed"
              }`}
              onClick={() =>
                !isAddingCartId && recommend.has_stock === 1
                  ? handlePlaceOrder(recommend.menu_id)
                  : undefined
              }
              key={recommendIndex}
            >
              <article className="flex justify-center">
                <article
                  className={`recommend-frame ${
                    recommend.has_stock === 0
                      ? "--out-stock"
                      : !recommend.picture &&
                        merchantInfo.company_logo.length > 0
                      ? "--store-logo"
                      : ""
                  }`}
                >
                  {recommend.has_stock === 0 && (
                    <article className="out-stock-overlay">
                      <p>Out Of Stock</p>
                    </article>
                  )}
                  <LazyLoadImage
                    src={
                      recommend.picture ||
                      (merchantInfo.company_logo.length > 0
                        ? merchantInfo.company_logo[0].url
                        : DefaultMenu)
                    }
                  />
                </article>
              </article>

              <article
                className={`menu-detail ${
                  recommend.has_stock === 0 ? "--out-stock" : ""
                }`}
              >
                <h4>{recommend.menu_name}</h4>
              </article>

              <article
                className={`flex items-end justify-between ${
                  hasNoPromoPrice ? "" : "price-container"
                } ${!recommend.description ? "mt-2" : ""}`}
              >
                <h4
                  className={`recommend-price ${
                    recommend.has_stock === 0 ? "--out-stock" : ""
                  }`}
                >
                  RM{" "}
                  {parseFloat(
                    recommend.promotion_price !== null
                      ? recommend.promotion_price
                      : recommend.unit_price
                  ).toFixed(2)}{" "}
                  {recommend.promotion_price !== null && (
                    <span>{parseFloat(recommend.unit_price).toFixed(2)}</span>
                  )}
                </h4>
                {recommend.has_stock === 1 && (
                  <button
                    className={`add-button ${
                      isAddingCartId === recommend.menu_id ? "--loading" : ""
                    }`}
                    onClick={(e) =>
                      !isAddingCartId &&
                      recommend.has_compulsory_food_option === 0
                        ? handleDirectPlaceOrder(e, recommend.menu_id)
                        : undefined
                    }
                  >
                    {isAddingCartId === recommend.menu_id ? (
                      <Icon icon="svg-spinners:3-dots-scale" />
                    ) : (
                      <Icon icon="ic:round-plus" />
                    )}
                  </button>
                )}
              </article>
            </SwiperSlide>
          ))}
      </Swiper>
    </header>
  );
}

// :
//     Array.from({ length: 3 }, (_, menuSkeletonIndex) => (
//         <section className="recommend-col" style={{ minWidth: width < 700 ? '111px' : '240px' }}>
//             <article className="flex justify-center">
//                 <Skeleton
//                     width={width < 700 ? "94px" : "144px"}
//                     height="91px"
//                     borderRadius="35px"
//                 />
//             </article>
//             <Skeleton
//                 width="60%"
//                 height="16px"
//                 borderRadius="8px"
//                 className="mt-3"
//             />
//             <Skeleton
//                 width="100%"
//                 height="12px"
//                 borderRadius="8px"
//                 className="mt-3"
//             />
//             <article className="flex items-start  justify-between mt-3">
//                 <Skeleton
//                     width={width > 991 ? "30%" : "40%"}
//                     height="12px"
//                     borderRadius="8px"
//                 />
//                 <Skeleton
//                     width="20px"
//                     height="20px"
//                     borderRadius="100%"
//                 />
//             </article>
//         </section>
//     ))
