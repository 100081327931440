import { useNavigate, useParams } from "react-router-dom";

import { 
  AfterStamp, 
  BeforeStamp, 
  Claimed, 
  DefaultPromo
} from "../../../assets/images";

import { LazyLoadImage } from "react-lazy-load-image-component";

import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function StampCollection() {
  const navigate = useNavigate();
  const { store } = useParams();
  const { width } = useWindowDimensions();

  const { afterStamp, beforeStamp, themeColor } = useSelector((state) => state.merchant);
  const { stampCollection, checkInProgress } = useSelector((state) => state.check_in);

  const [deviceType, setDeviceType] = useState('');

  const stampsNeeded = checkInProgress?.next_reward - checkInProgress?.checked_in;
  
  const handleNavToCheckIn = () => {
    navigate(`/${store}/check-in`)
  }

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
  };

  const applyColorToImage = (color) => {
    const rgbColor = hexToRgb(color ?? '#6C5FE6');

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
        <filter id="colorize">
          <feColorMatrix type="matrix" values={`0 0 0 0 ${rgbColor.r / 255}
                                                0 0 0 0 ${rgbColor.g / 255}
                                                0 0 0 0 ${rgbColor.b / 255}
                                                0 0 0 1 0`} 
          />
        </filter>
        <image xlinkHref={Claimed} width={width > 991 ? "40" : "30"} height={width > 991 ? "40" : "30"} filter="url(#colorize)" />
      </svg>
    );
  }

  const lastIndex = stampCollection.length > 0 && stampCollection.length - 1

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    }
  }, []);

  return (
    <section className={`stamp-collection ${stampCollection.length === 1 ? 'items-start' : 'items-end'} pointer`} onClick={() => handleNavToCheckIn()}> 
        <article>
            <h4>Stamp Collection {stampsNeeded > 0 ? <span>(collect {stampsNeeded} stamps to get reward)</span> : stampCollection[lastIndex].has_reward === 0 ? <span>(you've collected {checkInProgress.checked_in} stamps)</span> : <span>(waiting for next round)</span>}</h4>
            <section className={`flex justify-center ${width > 550 ? 'gap-4' : 'gap-3'}`}>
                {stampCollection.length > 0 && stampCollection.map((stamp, stampIndex) => (
                  stampIndex !== lastIndex ?
                  stamp.has_reward === 0 ?
                    <article className="frame --stamp" key={stampIndex}>
                        <LazyLoadImage src={stamp.has_check_in === 1 ? afterStamp || AfterStamp : beforeStamp || BeforeStamp} />
                    </article>
                  : 
                    <article className='frame --stamp' key={stampIndex}>
                      {stamp.has_claim === 1 && themeColor?.main_color && 
                        <article className="stamp-claimed">{applyColorToImage(themeColor.main_color)}</article>
                      }
                      <LazyLoadImage src={stamp.promotion_pic?.length > 0 && stamp.promotion_pic[0].url || DefaultPromo} />
                      {stamp.user_check_in_id && stamp.available_status === 'available to claim' ?
                        <p className={`badge --claim ${deviceType === 'iOS' ? '--ios' : ''}`}>Claim</p>
                      : checkInProgress.next_reward === stamp.item_index ?
                        <p className={`badge ${deviceType === 'iOS' ? '--ios' : ''}`}>Next Reward</p>
                      : ''
                      }
                    </article>
                  : ''
                ))}
            </section>
        </article>
        <article>
        {stampCollection[lastIndex].has_reward === 1 && stampCollection.length > 0 &&
          <article className="frame --bigger-stamp">
            {stampCollection[lastIndex].has_claim === 1 && themeColor?.main_color && 
              <article className="stamp-claimed">{applyColorToImage(themeColor.btn_color)}</article>
            }
            <LazyLoadImage src={stampCollection[lastIndex].promotion_pic?.length > 0 && stampCollection[lastIndex].promotion_pic[0].url || DefaultPromo} />
            {stampCollection[lastIndex].user_check_in_id && stampCollection[lastIndex].available_status === 'available to claim' ?
              <p className={`badge ${deviceType === 'iOS' ? '--ios' : ''} --claim`}>Claim</p>
            : checkInProgress.next_reward === stampCollection[lastIndex].item_index?
              <p className={`badge ${deviceType === 'iOS' ? '--ios' : ''}`}>Next Reward</p>
            : ''
            }
          </article>
        }
        </article>
    </section>
  );
}
