import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from "@sentry/react";

//redux
import { Provider } from 'react-redux';
import store from "./redux/store"; 

//css
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { ToastProvider } from './components/common/toast.provider';
import useWindowDimensions from './components/hook/use.window.dimensions';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_ID,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.REACT_APP_SENTRY_URL], 
  // "localhost", 
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback={(<div></div>)}>
      {window.innerWidth < 450 ?
        <ToastProvider>
          <App />
        </ToastProvider>
      : 
        <App />
      }
    </Suspense>
  </Provider>
);

reportWebVitals();
