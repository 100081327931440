import { useEffect, useRef } from "react";

//lib
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";

//lib css
import "react-spring-bottom-sheet/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { setAnimationModal, setShowModal } from "../../redux/reducer/modalReducer";
import useWindowDimensions from "../hook/use.window.dimensions";

export default function HalfModal({
  show,
  header,
  type,
  footer,
  content,
  backDrop,
  className,
  setShow,
  dialogClassName,
  contentClassName,
  centered = true,
  screenSize,
  scrollable = true,
  desktopModal,
  onDismiss,
  onOpen,
  backButton,
}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const bottomSheetRef = useRef(null);
  const { showModal, isAnimationModal } = useSelector((state) => state.modal)

  const handleBottomSheetOpen = () => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.scrollTo({ top: 0 });
    }
  };

  const handleCloseModalWithDelay = () => {
    dispatch(setShowModal(false))
    setTimeout(() => {
      dispatch(setAnimationModal(true))
      backButton();
    }, 500);
  };

  useEffect(() => {
    dispatch(setShowModal(show))

    if (bottomSheetRef.current) {
      bottomSheetRef.current.scrollTo({ top: 0 });
    }
  }, [show])

  //   return (
  //     <RemoveScroll>
  //       <AnimatePresence>
  //         {showModal && 
  //         <MDBModal
  //           className={`full-modal-box-container ${className}`}
  //           tabIndex="-1"
  //           show={true}
  //         >
  //           <div
  //             style={{
  //               height: "100%",
  //               width: "100%",
  //               position: "absolute",
  //             }}
  //             onClick={() => handleCloseModalWithDelay()}
  //           ></div>
  //           <motion.div
  //             initial={ isAnimationModal && { 
  //               scale: 0.2
  //             }}
  //             animate={ isAnimationModal && { 
  //               scale: 1,
  //               opacity: 1,
  //               transition: {
  //                 duration: 0.5
  //               }
  //             }}
  //             exit={ isAnimationModal && {
  //               opacity: [1, 0],
  //               scale: [1, 0.8],
  //               transition: {
  //                 duration: 0.3,
  //               },
  //             }}
  //             >
  //             <MDBModalDialog
  //               centered={centered}
  //               size={`${screenSize}`}
  //               scrollable={scrollable ? true : false}
  //             >
  //               <MDBModalContent>
  //                 <motion.div
  //                   initial={ isAnimationModal && {  
  //                     opacity: 0
  //                   }}
  //                   animate={ isAnimationModal && { 
  //                     opacity: 1,
  //                     transition: {
  //                       delay: 0.3,
  //                       duration: 0.3
  //                     }
  //                   }}
  //                   exit={ isAnimationModal && { 
  //                     opacity: 0,
  //                     transition: {
  //                       duration: 0.3,
  //                     }
  //                   }}
  //                 >
  //                   {content}
  //                 </motion.div>
  //               </MDBModalContent>
  //             </MDBModalDialog>
  //           </motion.div>
  //         </MDBModal>
  //       }
  //       </AnimatePresence>
  //     </RemoveScroll>
  //   );
  // } else {
    return (
      <RemoveScroll>
        {type === 'mobile' ?
          <AnimatePresence>
            {showModal && 
              <MDBModal
                className={`full-modal-box-container ${className ? className : ''}`}
                // tabIndex="-1"
                // show={true}
              >
                {backButton &&
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                  }}
                  onClick={() => handleCloseModalWithDelay()}
                ></div>}
                <motion.div
                  initial={ isAnimationModal && { 
                    scale: 0.2
                  }}
                  animate={ isAnimationModal && { 
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.5
                    }
                  }}
                  exit={ isAnimationModal && {
                    opacity: [1, 0],
                    scale: [1, 0.8],
                    transition: {
                      duration: 0.3,
                    },
                  }}
                >
                  <MDBModalDialog
                    centered={centered}
                    size={`${screenSize}`}
                    scrollable={scrollable ? true : false}
                    className={dialogClassName}
                  >
                    <MDBModalContent className={contentClassName} >
                      <motion.div
                        initial={ isAnimationModal && { 
                          opacity: 0
                        }}
                        animate={ isAnimationModal && { 
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3
                          }
                        }}
                        exit={ isAnimationModal && { 
                          opacity: 0,
                          transition: {
                            duration: 0.3,
                          }
                        }}
                      >
                          {content}
                      </motion.div>
                    </MDBModalContent>
                  </MDBModalDialog>
                </motion.div>
              </MDBModal>
            }
          </AnimatePresence>
        :
          <BottomSheet
            open={type !== 'mobile'}
            onDismiss={backButton}
            // onOpen={handleBottomSheetOpen}
            snapPoints={
              className == "bank-modal"
              ? width < 550 ? ({ maxHeight }) => [maxHeight / 1.2] : ({ maxHeight }) => [maxHeight / 1.2]
              : className == "instant-topup" 
              ? width < 550 ? ({ maxHeight }) => [maxHeight / 1.3] : ({ maxHeight }) => [maxHeight / 1.4]
              : className == "select-time" 
              ? width < 550 ? ({ maxHeight }) => [maxHeight / 1.4] : ({ maxHeight }) => [maxHeight / 1.5]
              : ({ maxHeight }) => [maxHeight / 1.3]
            }
            scrollLocking={false}
            header={header}
            footer={footer}
            className={`half-modal-box-container ${className ? className : ''}`}
            // ref={bottomSheetRef}
          >
            <div ref={bottomSheetRef} className="m-0 p-0">{content}</div>
          </BottomSheet>
        }
      </RemoveScroll>
    );
}
