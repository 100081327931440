import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import Button from "../../../components/element/button";

export default function MenuFooter() {
  const navigate = useNavigate();
  const { store } = useParams();
  const { cartInfo } = useSelector((state) => state.cart);

  const handleToCart = () => {
    navigate(`/${store}/cart`);
  };

  return (
    <footer className="menu-footer">
      {cartInfo?.remainder_amount_get_promotion?.remainder_amount > 0 && (
        <p className="add-more">
          Add RM{" "}
          {parseFloat(
            Math.ceil(
              cartInfo?.remainder_amount_get_promotion?.remainder_amount * 1000
            ) / 1000
          ).toFixed(2)}{" "}
          more to receive{" "}
          {cartInfo?.remainder_amount_get_promotion?.better_deduct_price > 0
            ? `RM
            ${parseFloat(
              cartInfo?.remainder_amount_get_promotion?.better_deduct_price
            ).toFixed(2)} discount`
            : cartInfo?.remainder_amount_get_promotion?.better_promotion_title
            ? `${
                cartInfo?.remainder_amount_get_promotion
                  ?.better_promotion_type === "free menu"
                  ? "free"
                  : ""
              }
            ${
              cartInfo?.remainder_amount_get_promotion
                ?.better_promotion_type === "free menu"
                ? cartInfo?.remainder_amount_get_promotion
                    ?.better_promotion_menu
                : cartInfo?.remainder_amount_get_promotion
                    ?.better_promotion_title
            }
            ${
              cartInfo?.remainder_amount_get_promotion
                ?.better_promotion_type === "free menu"
                ? ""
                : "voucher"
            }`
            : ""}
        </p>
      )}
      <section className="flex justify-between">
        <article className="price-detail">
          <p>Total Price</p>
          <h3>
            RM{" "}
            {cartInfo?.subtotal
              ? parseFloat(cartInfo.subtotal).toFixed(2)
              : "0.00"}
          </h3>
        </article>
        <Button btnClassName="order-now" onClick={() => handleToCart()}>
          Order Now
        </Button>
      </section>
    </footer>
  );
}
