import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../hook/use.window.dimensions";
import { useParams } from "react-router-dom";
import FullModal from "../modal/full.modal.box";
import { MDBModalBody } from "mdb-react-ui-kit";
import Button from "../element/button";
import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FullLogo } from "../../assets/images";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { closeReceiptModal, closeReceivePointModal, openCongratsModal } from "../../redux/reducer/modalReducer";
import { claimPoint } from "../../redux/reducer/homeReducer";
import { useToast } from "./toast.provider";
import CryptoJS from 'crypto-js';

export default function ReceivePoint() {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { store } = useParams();

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { claimPointLoading } = useSelector((state) => state.home);
  const { isOpenReceivePointModal } = useSelector((state) => state.modal);

  const receiveForm = {
    phone_number: '',
  }

  const receiveSchema = Yup.object({
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^(1)[0-9]{8,9}$/,
      "Enter a valid international phone number (e.g., 123456789)"
    )
  });

  const handleCloseReceivePoint = () => {
    dispatch(closeReceivePointModal())
  }

  const handleSubmit = ({ values }) => {
    const secretPhase = `${isOpenReceivePointModal.unique_no}|${isOpenReceivePointModal.merchant_id}|${isOpenReceivePointModal.amount}` 
    const secretKey = process.env.REACT_APP_SECRET_KEY 
    const hashKey = CryptoJS.HmacSHA256(secretPhase, secretKey).toString();

    dispatch(claimPoint({
        username: `+60${values.phone_number}`,
        amount: isOpenReceivePointModal.amount,
        unique_no: isOpenReceivePointModal.unique_no,
        merchant_id: isOpenReceivePointModal.merchant_id,
        created_at: isOpenReceivePointModal.created_at,
        hash: hashKey
    }))
    .unwrap()
    .then((res) => {
        dispatch(openCongratsModal(res.data))
        handleCloseReceivePoint()
        toast.success('Claim membership points successful.')
    })
    .catch((ex) => {
        if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                    toast.error(errors[item][0]);
                });
            }
        }
    });
  }

  return (
    <FullModal
      show={isOpenReceivePointModal?.unique_no}
      screenSize="xl"
      backButton={handleCloseReceivePoint}
      dialogClassName="--margin"
      contentClassName="--short --white"
      content={
        <>
          <MDBModalBody>
            <article className="receive-point-modal">
                <Formik
                    initialValues={receiveForm}
                    validationSchema={receiveSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        handleSubmit({ values });
                    }}
                >
                    {({ values, errors, isValid, setFieldValue }) => (
                        <Form>
                            <section className="receive-notification">
                                <article className="receive-header">
                                    <article className="receive-title">
                                        <section className="receive-frame">
                                            <LazyLoadImage
                                                src={merchantInfo.company_logo.length > 0 ? merchantInfo.company_logo[0].url : FullLogo}
                                            />
                                        </section>
                                        <section>
                                            <h4>Claim Membership Points</h4>
                                            <p>{merchantInfo.store_name}</p>
                                        </section>
                                    </article>
                                    <article onClick={() => handleCloseReceivePoint()}>
                                        <Icon icon="ic:round-close" />
                                    </article>
                                </article>
                                <article className="receive-content">
                                    <article>
                                        <p>You will receive</p>
                                        <article className="flex items-baseline gap-2">
                                            <p className="myr mt-2">RM</p><h2>{parseFloat(isOpenReceivePointModal.amount).toFixed(2)}</h2><p className="pts mt-2">/ worth of points</p>
                                        </article>
                                    </article>
                                </article>
                                <article className="receive-summary">
                                    <article className="flex flex-column gap-3">
                                        <article className="flex items-center gap-3">
                                            <article className="phone-input">
                                                <p>+60</p>
                                                <input
                                                    type="number"
                                                    name="phone_number"
                                                    placeholder="123456789"
                                                    onChange={(e) => setFieldValue('phone_number', e.target.value)}
                                                />
                                            </article>
                                            <Button type="submit" disabled={claimPointLoading || !values.phone_number || !isValid} className="w-25 h-100" btnClassName="receive-btn">Confirm</Button>
                                        </article>
                                        {errors && errors['phone_number'] && 
                                            <p className='element _errors text-right no-padding m-0'>
                                                {errors['phone_number']}
                                            </p>
                                        }
                                        <p className="mt-3">Note: Please ensure that you enter the <span>correct phone number</span>, as it can only be submitted once.</p>
                                    </article>
                                </article>
                            </section>
                        </Form>
                    )}
                </Formik>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
