import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
  isOpenLoginModal: false,
  isOpenPlaceOrderModal: false,
  isOpenSetAddressModal: false,
  isOpenSaveAddressModal: false,
  isOpenStoreInfoModal: false,
  isOpenPaymentModal: false,
  isOpenVoucherModal: false,
  isOpenTncModal: false,
  isOpenPrizeModal: false,
  isOpenReceiptModal: false,
  isOpenConfirmModal: false,
  isOpenHistoryModal: false,
  isOpenTopUpModal: false,
  isOpenInstantTopUpModal: false,
  isOpenTimeModal: false,
  isOpenProfileModal: false,
  isOpenPromotionModal: false,
  isOpenScanQRModal: false,
  isOpenCongratsModal: false,
  isOpenMarketModal: false,
  isOpenPromotionDetailsModal: false,
  isOpenPrizeDetailsModal: false,
  isOpenReceivePointModal: {},

  receivePoint: '',
  showClaimButton: false,
  isClaimed: true,
  isInBag: false,
  isOpenCouponPopUp: false,
  isOpenPopUp: false,
  isAnimationModal: true, 
  showModal: false,
  isInReceipt: false
};

const dataSlice = createSlice({
  name: "modal",
  initialState: initialData,
  reducers: {
    openLoginModal: (state) => {
      state.isOpenLoginModal = true;
    },
    openPlaceOrderModal: (state) => {
      state.isOpenPlaceOrderModal = true;
    },
    openSetAddressModal: (state) => {
      state.isOpenSetAddressModal = true;
    },
    openSaveAddressModal: (state) => {
      state.isOpenSaveAddressModal = true;
    },
    openStoreInfoModal: (state) => {
      state.isOpenStoreInfoModal = true;
    },
    openPaymentModal: (state) => {
      state.isOpenPaymentModal = true;
    },
    openVoucherModal: (state) => {
      state.isOpenVoucherModal = true;
    },
    openTncModal: (state) => {
      state.isOpenTncModal = true;
    },
    openPrizeModal: (state) => {
      state.isOpenPrizeModal = true;
    },
    openReceiptModal: (state) => {
      state.isOpenReceiptModal = true;
    },
    openConfirmModal: (state) => {
      state.isOpenConfirmModal = true;
    },
    openHistoryModal: (state) => {
      state.isOpenHistoryModal = true;
    },
    openTopUpModal: (state) => {
      state.isOpenTopUpModal = true;
    },
    openProfileModal: (state) => {
      state.isOpenProfileModal = true;
    },
    openPromotionModal: (state) => {
      state.isOpenPromotionModal = true;
    },
    openFeedbackModal: (state) => {
      state.isOpenFeedbackModal = true;
    },
    openScanQRModal: (state) => {
      state.isOpenScanQRModal = true;
    },
    openCongratsModal: (state, action) => {
      state.receivePoint = action.payload;
      state.isOpenCongratsModal = true;
    },
    openPromotionDetailsModal: (state, action) => {
      state.showClaimButton = action.payload === 'claim-btn' || action.payload === 'not-claimed-home' ? true : false;
      state.isInReceipt = action.payload === 'open-receipt' ? true : false;
      state.isInBag = action.payload === 'open-bag' ? true : false;
      state.isClaimed = action.payload === 'not-claimed' || action.payload === 'not-claimed-home' ? false : true;
      state.isOpenPromotionDetailsModal = true;
    },
    openPrizeDetailsModal: (state, action) => {
      state.isOpenPrizeDetailsModal = true;
    },
    openInstantTopUpModal: (state) => {
      state.isOpenInstantTopUpModal = true;
    },
    openTimeModal: (state) => {
      state.isOpenTimeModal = true;
    },
    openMarketModal: (state, action) => {
      state.isOpenMarketModal = action.payload === 'point' ? action.payload : true;
    },
    openReceivePointModal: (state, action) => {
      state.isOpenReceivePointModal = action.payload;
    },

    closeLoginModal: (state) => {
      state.isOpenLoginModal = false;
    },
    closePlaceOrderModal: (state) => {
      state.isOpenPlaceOrderModal = false;
    },
    closeSetAddressModal: (state) => {
      state.isOpenSetAddressModal = false;
    },
    closeSaveAddressModal: (state) => {
      state.isOpenSaveAddressModal = false;
    },
    closeStoreInfoModal: (state) => {
      state.isOpenStoreInfoModal = false;
    },
    closePaymentModal: (state) => {
      state.isOpenPaymentModal = false;
    },
    closeVoucherModal: (state) => {
      state.isOpenVoucherModal = false;
    },
    closeTncModal: (state) => {
      state.isOpenTncModal = false;
    },
    closePrizeModal: (state) => {
      state.isOpenPrizeModal = false;
    },
    closeReceiptModal: (state) => {
      state.isOpenReceiptModal = false;
    },
    closeConfirmModal: (state) => {
      state.isOpenConfirmModal = false;
    },
    closeHistoryModal: (state) => {
      state.isOpenHistoryModal = false;
    },
    closeTopUpModal: (state) => {
      state.isOpenTopUpModal = false;
    },
    closeProfileModal: (state) => {
      state.isOpenProfileModal = false;
    },
    closePromotionModal: (state) => {
      state.isOpenPromotionModal = false;
    },
    closeFeedbackModal: (state) => {
      state.isOpenFeedbackModal = false;
    },
    closeScanQRModal: (state) => {
      state.isOpenScanQRModal = false;
    },
    closeCongratsModal: (state) => {
      state.isOpenCongratsModal = false;
    },
    closePromotionDetailsModal: (state) => {
      state.isOpenPromotionDetailsModal = false;
    },
    closeInstantTopUpModal: (state) => {
      state.isOpenInstantTopUpModal = false;
    },
    closeTimeModal: (state) => {
      state.isOpenTimeModal = false;
    },
    closeMarketModal: (state) => {
      state.isOpenMarketModal = false;
    },
    closePrizeDetailsModal: (state, action) => {
      state.isOpenPrizeDetailsModal = false;
    },
    closeReceivePointModal: (state, action) => {
      state.isOpenReceivePointModal = {};
    },

    openCouponPopUp: (state) => {
      state.isOpenCouponPopUp = true;
    },
    closeCouponPopUp: (state) => {
      state.isOpenCouponPopUp = false;
    },
    openPopUp: (state) => {
      state.isOpenPopUp = true;
    },
    closePopUp: (state) => {
      state.isOpenPopUp = false;
    },
    closeAllModal: (state) => {
      state.isOpenLoginModal = false;
      state.isOpenPlaceOrderModal = false;
      state.isOpenSaveAddressModal = false;
      state.isOpenSetAddressModal = false;
      state.isOpenStoreInfoModal = false;
      state.isOpenPaymentModal = false;
      state.isOpenVoucherModal = false;
      state.isOpenTncModal = false;
      state.isOpenPrizeModal = false;
      state.isOpenReceiptModal = false;
      state.isOpenHistoryModal = false;
      state.isOpenTopUpModal = false;
      state.isOpenProfileModal = false;
      state.isOpenPromotionModal = false;
      state.isOpenFeedbackModal = false;
      state.isOpenScanQRModal = false;
      state.isOpenPromotionDetailsModal = false;
      state.isOpenInstantTopUpModal = false;
      state.isOpenTimeModal = false;
      state.isOpenMarketModal = false;
      state.isOpenReceivePointModal = {};
    },
    setAnimationModal: (state, action) => {
      state.isAnimationModal = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
});

export const { 
    openLoginModal, 
    openPlaceOrderModal,
    openSetAddressModal,
    openSaveAddressModal,
    openStoreInfoModal,
    openPaymentModal,
    openVoucherModal,
    openTncModal,
    openPrizeModal,
    openReceiptModal,
    openConfirmModal,
    openHistoryModal,
    openTopUpModal,
    openProfileModal,
    openPromotionModal,
    openFeedbackModal,
    openScanQRModal,
    openCongratsModal,
    openPromotionDetailsModal,
    openInstantTopUpModal,
    openTimeModal,
    openMarketModal,
    openPrizeDetailsModal,
    openReceivePointModal,

    closeLoginModal, 
    closePlaceOrderModal,
    closeSetAddressModal,
    closeSaveAddressModal,
    closeStoreInfoModal,
    closePaymentModal,
    closeVoucherModal,
    closeTncModal,
    closePrizeModal,
    closeReceiptModal,
    closeConfirmModal,
    closeHistoryModal,
    closeTopUpModal,
    closeProfileModal,
    closePromotionModal,
    closeFeedbackModal,
    closeScanQRModal,
    closeCongratsModal,
    closePromotionDetailsModal,
    closeInstantTopUpModal,
    closeTimeModal,
    closeMarketModal,
    closePrizeDetailsModal,
    closeReceivePointModal,

    closeAllModal,
    openPopUp,
    closePopUp,
    openCouponPopUp,
    closeCouponPopUp,
    setAnimationModal,
    setShowModal
} = dataSlice.actions;

export default dataSlice.reducer;
