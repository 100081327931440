import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

import useWindowDimensions from "../hook/use.window.dimensions";
import { useDispatch } from "react-redux";
import { setShowModal } from "../../redux/reducer/modalReducer";
import { useLocation, useParams } from "react-router-dom";

export default function ModalHeader({
  title,
  backTo,
  backToNoAnimation,
  shareButton,
  handleShareReceipt,
  type
}) {
  const dispatch = useDispatch()
  const { store }  = useParams()
  const { pathname }  = useLocation()
  const { width } = useWindowDimensions();

  const handleCloseModalWithDelay = () => {
    dispatch(setShowModal(false))
    setTimeout(() => {
      backTo();
    }, 500);
  };

  return (
      <header
        className={`modal-header-container --${type ? type : ''}`}
        style={{
          position: width >= 991 ? "sticky" : type === 'model2 scan' ? "relative" : "fixed",
        }}
      >
        <section className="row info-row">
          {!type && <article className="info-col col-2 pointer">
            {(backTo || backToNoAnimation) && (
              <Icon
                icon="fluent:person-arrow-back-48-regular"
                onClick={() => { 
                  backToNoAnimation ? backToNoAnimation() : handleCloseModalWithDelay()
                }}
                className="back-icon"
                width={30}
              />
            )}
          </article>}
          <article className="col info-col">
            <label style={{ fontSize: width >= 991 ? "18px" : "" }}>
              {title}
            </label>
          </article>
          {(backTo || backToNoAnimation) && type && 
            <article 
              className={`info-col ${type ? '--end' : ''} col-2 pointer`} 
              onClick={() => backToNoAnimation ? backToNoAnimation() : handleCloseModalWithDelay()}
            >
                <Icon icon="icon-park-twotone:close-one" />
            </article>
          }
        </section>
      </header>
  );
}
