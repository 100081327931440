import { useNavigate, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";

import { useDispatch, useSelector } from "react-redux";
import { openLoginModal } from "../../../redux/reducer/modalReducer";

import PageHeader from "../../../components/header/page.header";

export default function CartHeader({ method, setLoginType }) {
  const { store } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login, guest } = useSelector((state) => state.common);

  const handleBackToMenu = () => {
    navigate(`/${store}/menu`);
  };

  const handleNavToOrder = () => {
    if (login === "partial" || login === "full" || guest === 'true') {
      navigate(`/${store}/order`);
    } else {
      dispatch(openLoginModal());
      setLoginType("partial-cart");
    }
  };

  return (
    <header
      className={`cart-header ${method === "dine in" ? "--dine-in" : ""}`}
    >
      <PageHeader
        backIcon="mdi:backburger"
        backTo={handleBackToMenu}
        navTo={handleNavToOrder}
        className="pb-0"
      />
      <section className="sub-header">
        <h1>Cart</h1>
        <p>Order Summary</p>
        <article className="steps mt-3 relative">
          <hr className="step-line" />
          <section className="grid center">
            <article className="step --checked">
              <Icon icon="material-symbols:check" />
            </article>
            <p className="step-text">Select</p>
          </section>
          <section className="grid center">
            <article className="step --checked">
              <Icon icon="material-symbols:check" />
            </article>
            <p className="step-text">Confirm</p>
          </section>
          <section className="grid center">
            <article className="step" />
            <p className="step-text">Order</p>
          </section>
        </article>
      </section>
    </header>
  );
}
